import { Typography } from 'antd';
import * as React from 'react';
import { useAuth } from '../../stores/use-auth';
import i18n from '../../i18n';
import { t } from 'i18next';

interface ITenantInfoProps { }

const TenantInfo: React.FunctionComponent<ITenantInfoProps> = props => {
  const { tenentName, bussinessGroupName } = useAuth(({ tenentName, bussinessGroupName }) => ({
    tenentName,
    bussinessGroupName
  }));
  return (
    <div className="flex gap-4">
      <Typography.Text className="text-[#898c98]">
        {t('businessGroupId')} - <span className="font-bold text-black">{bussinessGroupName}</span>
      </Typography.Text>
      <Typography.Text className="text-[#898c98]">
        {t('retailerId')} - <span className="font-bold text-black">{tenentName}</span>{' '}
      </Typography.Text>
    </div>
  );
};

export default TenantInfo;

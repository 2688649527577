import { Button, Card, Col, Form, Input, Row, Select, Typography } from 'antd';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import { useNavigate } from 'react-router-dom';
import { images } from '../../utils/images';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useLoader } from '../../stores/use-loader';
import { loggingIn } from '../../services/login.services';
import { useClientInfo } from '../../stores/use-auth';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import _ from '../../helpers/lodash';
import { urlHelpers } from '../../helpers';
import styled from 'styled-components';
import { ENV } from '../../config/envConfig';
import i18n from '../../i18n';
import { localeEjjeList, localeList } from '../../data/intl-data';
import { useLocale } from '../../stores/use-locale';

interface IForgotProps {}

const TransparentSelect = styled(Select)`
  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    color: white;
  }

  .ant-select-arrow {
    color: white;
  }

  .ant-select-item-option-content {
    color: white;
  }

  .ant-select-item-option {
    background-color: transparent !important;
    color: white;
  }

  .ant-select-item-option-selected {
    color: white !important;
    background-color: rgba(255, 255, 255, 0.2) !important; 
`;

const ForgotPassword: React.FunctionComponent<IForgotProps> = () => {
  const { t } = useTranslation();
  const [forgotPasswordForm] = Form.useForm();
  const navigate = useNavigate();

  const { locale, setUserLocale } = useLocale(({ locale, setUserLocale }) => ({ locale, setUserLocale }));

  const { businessGroupId: businessGrpIdFromUrl, tenantId: tenandIdFromUrl } =
    urlHelpers.getTenantIdAndBusinessGroupIdFromUrl();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const { setClientEmail } = useClientInfo(({ setClientEmail }) => ({ setClientEmail }));

  const handleOnGetOTP = async () => {
    setLoading(true);
    const { email } = forgotPasswordForm.getFieldsValue();
    setClientEmail(email);
    let body = {
      email: email
    };
    const { errors } = await loggingIn.getOtpByEmail(body, businessGrpIdFromUrl, tenandIdFromUrl);
    if (_.isEmpty(errors)) {
      navigate('/login/forgot-password/verify-code');
      displaySuccessNotification({ message: t('otpSentToMail') });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const selectLocale = (
    <div className="absolute right-[40px] top-[40px] login-language-dropdown">
      <TransparentSelect
        value={ENV === 'DEVELOPMENT' ? locale : 'IN'}
        onChange={(value: any) => {
          i18n.changeLanguage(value);
          setUserLocale(value);
        }}
        options={ENV === 'DEVELOPMENT' ? localeList : localeEjjeList}
        style={{ width: 'fit-content' }}
        disabled={ENV !== 'DEVELOPMENT'}
        className="text-white"
        dropdownStyle={{
          backgroundColor: 'transparent'
        }}
        defaultValue={'IN'}
      />
    </div>
  );

  return (
    <>
      <div className="main">
        <div className="base_container">
          <div className="cartoon_frame">
            <div>{selectLocale}</div>
            <div className="h-[100%] flex flex-row justify-center items-center">
              <Form
                form={forgotPasswordForm}
                layout="vertical"
                onFinish={() => {
                  handleOnGetOTP();
                }}
              >
                <Card className="xl:w-[400px] lg:w-[400px] md:w-[400px] sm:w-[33%]">
                  <div className="flex flex-col items-center pb-6">
                    <div className="mt-1">
                      <span className="font-extrabold text-[18px] text-[#008080]" translate="no">
                        {t('forgotPassword')}
                      </span>
                    </div>
                    <Typography.Text className="text-[#5e5f66]">{t('forgotPwdMessage')}</Typography.Text>
                  </div>
                  <Form.Item
                    label={<span className="text-[#5A607F]">{t('enterEmail')}</span>}
                    name={'email'}
                    required
                    className="mb-2"
                    rules={[
                      {
                        required: true,
                        message: t('fieldIsRequired')
                      },
                      {
                        validator: (_: any, value: string) => {
                          // Regular expression for a valid email format
                          const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                          // Ensuring domain part does not start/end with a hyphen
                          const domainPattern = /^[a-zA-Z0-9]+([a-zA-Z0-9-]*[a-zA-Z0-9]+)?(\.[a-zA-Z]{2,})+$/;

                          if (!value || (emailPattern.test(value) && domainPattern.test(value.split('@')[1]))) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t('invalidEmailFormat')));
                        }
                      },
                      {
                        max: 50,
                        message: t('max50Characters')
                      }
                    ]}
                  >
                    <Input
                      placeholder={t('enterEmail')}
                      size="large"
                      prefix={<MailOutlined style={{ color: '#1890FF' }} />}
                    />
                  </Form.Item>
                  <Row gutter={12} justify={'space-between'} className="mt-4">
                    <Col xs={24} md={8} lg={8}>
                      <Button size="large" block onClick={() => navigate('/login')}>
                        <BoldButtonLabel labelText={t('goBack')} />
                      </Button>
                    </Col>
                    <Col xs={24} md={10} lg={10}>
                      <Button size="large" block type="primary" htmlType="submit">
                        <BoldButtonLabel labelText={t('getOtp')} />
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

import { Card, Col, Form, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { objectHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { useBasicDetails } from '../../../stores/use-promotion';
import { priceListService } from '../services/pricelist.service';
import { IMasterCatalogListing, IPriceListListing } from '../types/price-list-types.s';
import PriceListDiscountForm from './price-list-discount-form';

const pos = ['S2001', 'S2002', 'S2003', 'S2004', 'S2005'];

interface IPriceListCreateFormProps {}

const PriceListCreate: React.FunctionComponent<IPriceListCreateFormProps> = () => {
  //new declare
  const [priceListCreateForm] = Form.useForm();
  const [priceListBasicDetailsForm] = Form.useForm();
  const navigate = useNavigate();
  //new State
  // const [referenceBlockVisiblity, setReferenceBlockVisiblity] = React.useState(false)
  const [paretListDropDown, setParentListDropDown] = React.useState<any>([]);
  const [masterCatalogOptions, setMasterCatalogOptions] = React.useState<any[]>([]);
  const [basicDetailsResp, setBasicDetailsResp] = React.useState({} as any);

  //other declerations

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  const { productData, setProductData, masterOptions, setMasterOptions, parentOptions, setParentOptions } =
    useBasicDetails(
      ({ productData, setProductData, masterOptions, setMasterOptions, parentOptions, setParentOptions }) => ({
        productData,
        setProductData,
        masterOptions,
        setMasterOptions,
        parentOptions,
        setParentOptions
      })
    );

  React.useEffect(() => {
    loadIntialData();
  }, []);

  const loadIntialData = () => {
    setLoading(true);

    priceListCreateForm.setFieldsValue({
      price_list_form_tab: 'BASIC-DETAILS',
      customer_restrictions_filters: [
        {
          restriction_type: ''
        }
      ]
    });
    priceListCreateForm.setFieldsValue({
      status: 'OPEN',
      suggestion_list: 'list_other',
      isPriceListExclusive: 'priceListExe_no',
      isPriceListResolvable: 'priceListRes_yes',
      is_active: 'active'
      // POS: true,
      // Ecommerce: true
      // specific_pos: pos,
      // specific_ecommerce: pos
    });
    getAllMasterCatalogData(0);
    getAllParentCatalogData(0);
    setLoading(false);
  };

  const onTabChange = (tabName: any) => {};

  const getAllMasterCatalogData = async (offset = 0) => {
    let params = { offset, limit: 100 };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    const { data, errors } = await priceListService.getMasterData(filteredParams);
    if (_.isEmpty(errors)) {
      let masterCatalogList = data?.data?.map((cv: IMasterCatalogListing) => ({ label: cv.name, value: cv.id }));
      setMasterCatalogOptions(masterCatalogList);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getAllParentCatalogData = async (offset = 0) => {
    const params = { offset, limit: 100 };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    const { data, errors } = await priceListService.getPriceList(filteredParams);
    if (_.isEmpty(errors)) {
      let parentCatalogList = data?.price_lists?.map((cv: IPriceListListing) => ({
        label: cv.price_list_name,
        value: cv.id
      }));
      setParentListDropDown(parentCatalogList);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getDateString = (date: any, formatter: string) => {
    let reqDate = dayjs(date).format(formatter);
    return reqDate;
  };

  const getTimeString = (time: any, format: string) => {
    let reqTime = dayjs(time).format(format);
    return reqTime;
  };

  const loadCreateBodyData = () => {
    const specicPOS =
      priceListCreateForm.getFieldsValue()?.specific_pos?.map((cv: any, icv: any) => {
        return { site_id: cv, site_name: cv };
      }) || null;
    const specificOnline =
      priceListCreateForm.getFieldsValue()?.specific_ecommerce?.map((cv: any, icv: any) => {
        return { site_id: cv, site_name: cv };
      }) || null;

    let customerRestrictionData = priceListCreateForm.getFieldsValue()?.customer_restrictions_filters;

    let customer_restriction_result = customerRestrictionData?.map((cv: any) => {
      if (cv.restriction_type === 'SEGMENT') {
        return { restriction_type: cv.restriction_type, restriction_value: cv.restriction_value };
      } else {
        return { restriction_type: cv.restriction_type, restriction_value: [cv.restriction_value] };
      }
    });

    let suggestionLine = priceListCreateForm.getFieldsValue().suggestion_list;
    let CatalogType =
      suggestionLine === 'list_other'
        ? 'IMPORT-CATALOG'
        : suggestionLine === 'list_parent'
        ? 'PARENT-LIST'
        : 'MASTER-CATALOG';

    const bodyData = {
      catalog_type: CatalogType,
      price_list_name: priceListCreateForm.getFieldsValue().name,
      name: priceListCreateForm.getFieldsValue().name,
      description: priceListCreateForm.getFieldsValue().description,
      status: 'OPEN',
      is_active: priceListCreateForm.getFieldsValue().is_active === 'active',
      rank: priceListCreateForm.getFieldsValue().rank,
      start_date: getDateString(priceListCreateForm.getFieldsValue().startDate, 'YYYY-MM-DD'),
      end_date: priceListCreateForm.getFieldsValue().endDate
        ? getDateString(priceListCreateForm.getFieldsValue().endDate, 'YYYY-MM-DD')
        : null,
      start_time: priceListCreateForm.getFieldsValue().startTime
        ? getTimeString(priceListCreateForm.getFieldsValue().startTime, 'HH:mm:ss')
        : '',
      end_time: priceListCreateForm.getFieldsValue().endTime
        ? getTimeString(priceListCreateForm.getFieldsValue().endTime, 'HH:mm:ss')
        : null,
      timezone: priceListCreateForm.getFieldsValue().time_Zone,
      parent_price_list_id: priceListCreateForm.getFieldsValue().parent_price_list
        ? priceListCreateForm.getFieldsValue().parent_price_list
        : null,
      master_catalog_id: priceListCreateForm.getFieldsValue().select_from_master_catalog_dropDown
        ? priceListCreateForm.getFieldsValue().select_from_master_catalog_dropDown
        : null,
      import_catalog_ref_name: priceListCreateForm.getFieldsValue().select_from_master_catalog_TextBox
        ? priceListCreateForm.getFieldsValue().select_from_master_catalog_TextBox
        : null,
      currency_code: priceListCreateForm.getFieldsValue().currency,
      is_pos:
        priceListCreateForm.getFieldsValue().isPriceListResolvable === 'priceListRes_yes'
          ? priceListCreateForm.getFieldsValue().POS
          : false,
      pos: specicPOS,
      is_online:
        priceListCreateForm.getFieldsValue().isPriceListResolvable === 'priceListRes_yes'
          ? priceListCreateForm.getFieldsValue().Ecommerce
          : false,
      online: specificOnline,
      customer_restrictions: priceListCreateForm.getFieldsValue().customer_restrictions_filters
        ? customer_restriction_result
        : null,
      is_resolvable: priceListCreateForm.getFieldsValue().isPriceListResolvable === 'priceListRes_yes',
      is_exclusive: priceListCreateForm.getFieldsValue().isPriceListExclusive === 'priceListExe_yes',
      rounding: {
        rounding_policy: priceListCreateForm.getFieldsValue().rounding_Policy,
        rounding_option:
          priceListCreateForm.getFieldsValue().rounding_Policy === 'NONE'
            ? priceListCreateForm.getFieldsValue().rounding_Option
            : null,
        rounding_amount:
          priceListCreateForm.getFieldsValue().rounding_Policy === 'NONE'
            ? String(priceListCreateForm.getFieldsValue().rounding_amount)
            : null
      }
    };
    return bodyData;
  };

  const handleOnSave = async () => {
    setLoading(true);
    const bodyData = loadCreateBodyData();
    const { errors } = await priceListService.createPriceList(bodyData);
    if (_.isEmpty(errors)) {
      handleGoBack();
      displaySuccessNotification({ message: 'Price List created successfully!' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleGoBack = () => {
    navigate('/promotion-engine/promotions/price-list');
  };

  const getProductName = () => {
    let returnParams = {
      product_Name: '',
      prefered_button: ''
    };
    return returnParams;
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row>
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Create Price List
              </Typography.Title>
            </Col>
          </Row>
          <PriceListDiscountForm
            istabVisible={false}
            isformVisible={true}
            formVisiblityBasedOnStatus={false}
            refUpdatBlock={false}
            referenceBlockVisiblity={false}
            formInstance={priceListCreateForm}
            basicDetailsForm={priceListBasicDetailsForm}
            handleOnSave={handleOnSave}
            handleGoBack={handleGoBack}
            onClickSuggestedLines={() => {}}
            onClickCopyLines={() => {}}
            paretListDropDown={paretListDropDown}
            masterCatalogOptions={masterCatalogOptions}
            getProductName={getProductName}
            handleOnClickDownload={() => {}}
            loadIntialData={onTabChange}
          />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default PriceListCreate;

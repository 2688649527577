import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  TimePicker,
  Typography
} from 'antd';
import React from 'react';
import BoldButtonLabel from '../../../components/BoldButtonLabel';

const currencyList = [
  { label: '🇮🇳 INR', value: 'IN', country: 'India' },
  { label: '🇨🇱 CLP', value: 'CLP', country: 'Chile' },
  { label: '🇺🇸 USD', value: 'US', country: 'USA' }
];

const statusOptions = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'In-Active', value: 'IN-ACTIVE' },
  { label: 'Experied', value: 'EXPIRED' }
];

const uomOptions = [
  { label: 'EA', value: 'EA' },
  { label: 'KG', value: 'KG' },
  { label: 'LB', value: 'LB' }
];

const timeZones = [
  { label: 'Indian Standard Time (UTC+05:30)', value: 'IST', country: 'India' },
  { label: 'America (UTC-08:00)', value: 'AMERICA', country: 'USA' },
  { label: 'Chile Continental (UTC-04:00)', value: 'CHILE_CONTINENTAL', country: 'Chile' },
  { label: 'Chile Easter (UTC-06:00)', value: 'CHILE_EASTER', country: 'Chile' },
  { label: 'Europe (UTC+01:00)', value: 'EUROPE', country: 'Europe' }
];

interface IPriceEnteriesProductForm {
  handleGoBack: () => void;
  formInstance: FormInstance<any>;
  handleOnSave: () => void;
  isformVisible: boolean;
}

const PriceEnteriesProductForm: React.FunctionComponent<IPriceEnteriesProductForm> = ({
  handleGoBack,
  formInstance,
  handleOnSave,
  isformVisible
}) => {
  const [selectedDiscountTypes, setSelectedDiscountTypes] = React.useState<string[]>([]);
  return (
    <Form form={formInstance} layout="vertical" onFinish={handleOnSave}>
      <Row gutter={12}>
        <Col xs={24} md={8}>
          <Form.Item label="Item Name" name="name" rules={[{ required: true, message: "This field can't be empty" }]}>
            <Input size="large" placeholder="Name" disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label="Item Id" name="item_id" rules={[{ required: true, message: "This field can't be empty" }]}>
            <Input size="large" placeholder="Item Id" disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            label="Currency"
            name="currency_code"
            rules={[{ required: true, message: "This field can't be empty" }]}
          >
            <Select placeholder="Select an option" size="large" options={currencyList} disabled={true}></Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col xs={24} md={8}>
          <Form.Item
            label="Cost Price"
            name="costPrice"
            rules={[{ required: false, message: "This field can't be empty" }]}
          >
            <InputNumber className="w-[100%]" size="large" placeholder="" disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label="Status" name="status" rules={[{ required: true, message: "This field can't be empty" }]}>
            <Select
              placeholder="Select an option"
              size="large"
              options={statusOptions}
              disabled={isformVisible}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            label="Time Zone"
            name="time_Zone"
            rules={[{ required: true, message: "This field can't be empty" }]}
          >
            <Select
              placeholder="Select an option"
              size="large"
              className="w-[100%]"
              options={timeZones}
              disabled={true}
            ></Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col xs={24} md={8}>
          <Form.Item
            label="Start Date"
            name="start_date"
            rules={[{ required: true, message: "This field can't be empty" }]}
          >
            <DatePicker size="large" className="w-full" placeholder="Enter Start Date" disabled={isformVisible} />
          </Form.Item>
        </Col>
        <Col xs={12} md={8}>
          <Form.Item
            label="Start Time"
            name="startTime"
            rules={[{ required: true, message: "This field can't be empty" }]}
          >
            <TimePicker size="large" className="w-[100%]" format={'HH:mm:ss'} disabled={isformVisible} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            label="End Date"
            name="end_date"
            rules={[{ required: false, message: "This field can't be empty" }]}
          >
            <DatePicker size="large" className="w-full" placeholder="Enter End Date" disabled={isformVisible} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col xs={12} md={8}>
          <Form.Item
            label="End Time"
            name="endTime"
            rules={[{ required: false, message: "This field can't be empty" }]}
          >
            <TimePicker size="large" className="w-[100%]" format={'HH:mm:ss'} disabled={isformVisible} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            label="Artical Id"
            name="artical_Id"
            rules={[{ required: false, message: "This field can't be empty" }]}
          >
            <Input size="large" placeholder="Artical Id" disabled={true} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            label="Minimum Discount Percent"
            name="minimum_markup_percent"
            rules={[{ required: false, message: "This field can't be empty" }]}
          >
            <Input size="large" placeholder="Minimum Discount Percent" disabled={isformVisible} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col xs={24} md={8}>
          <Form.Item
            label="Maximum Discount Percent"
            name="maximum_markup_percent"
            rules={[{ required: false, message: "This field can't be empty" }]}
          >
            <Input size="large" placeholder="Maximum Discount Percent" disabled={isformVisible} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            label="Product Variant"
            name="is_product_variant"
            rules={[{ required: true, message: "This field can't be empty" }]}
          >
            <Radio.Group disabled={true}>
              <Radio value={'true'}>Yes</Radio>
              <Radio value={'false'}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            label="Threshold Based"
            name="is_threshold_based"
            rules={[{ required: true, message: "This field can't be empty" }]}
          >
            <Radio.Group disabled={isformVisible}>
              <Radio value={'true'}>Yes</Radio>
              <Radio value={'false'}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col xs={24} md={8}>
          <Form.Item
            label="Lock Sales Price"
            name="sales_lock_Price"
            rules={[{ required: true, message: "This field can't be empty" }]}
          >
            <Radio.Group disabled={isformVisible}>
              <Radio value={'true'}>Yes</Radio>
              <Radio value={'false'}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            label="Overide Master Lock Price"
            name="override_master_lock_price"
            rules={[{ required: true, message: "This field can't be empty" }]}
          >
            <Radio.Group disabled={isformVisible}>
              <Radio value={'true'}>Yes</Radio>
              <Radio value={'false'}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Typography.Title level={5} className="text-[#2e2a5b] m-0">
        Sales Price Info :
      </Typography.Title>
      <Form.Item noStyle shouldUpdate>
        {form => {
          return (
            <Form.Item noStyle shouldUpdate>
              {form => {
                const { salesPriceInfo_Data = [] } = form.getFieldsValue();
                const discountOptions = [
                  { label: 'Special Price', value: 'SPECIAL-PRICE' },
                  { label: 'Percentage', value: 'PERCENTAGE' },
                  { label: 'Absolute Discount', value: 'FIXED' }
                ];

                const getAvailableOptions = (index: number) => {
                  const selectedTypes = salesPriceInfo_Data
                    .map((item: { discount_type: string }, idx: number) => (idx !== index ? item.discount_type : null))
                    .filter(Boolean);

                  return discountOptions.filter(option => !selectedTypes.includes(option.value));
                };

                // Check if all discount types are used
                const allDiscountsUsed = salesPriceInfo_Data?.length >= discountOptions.length;

                return (
                  <Form.List name="salesPriceInfo_Data">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => {
                          const discType = salesPriceInfo_Data?.[index]?.discount_type;
                          return (
                            <React.Fragment key={key}>
                              <div
                                style={{
                                  height: 'fit-content',
                                  width: '100%',
                                  background: '#9b9b9b29',
                                  padding: '20px',
                                  borderRadius: '20px',
                                  marginBottom: '15px'
                                }}
                              >
                                {!isformVisible && (
                                  <div className="flex float-right">
                                    <DeleteOutlined
                                      className="text-red-500 text-xl"
                                      title={`Delete`}
                                      onClick={() => remove(name)}
                                    />
                                  </div>
                                )}
                                <Row gutter={12}>
                                  <Col xs={24} md={8}>
                                    <Form.Item
                                      label="Current Base Price"
                                      name={[name, 'original_list_price']}
                                      rules={[{ required: true, message: "This field can't be empty" }]}
                                    >
                                      <InputNumber
                                        className="w-[100%]"
                                        size="large"
                                        placeholder="Enter The Base Price"
                                        disabled={isformVisible}
                                        min={1}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={8}>
                                    <Form.Item
                                      label="Discount Type"
                                      name={[name, 'discount_type']}
                                      rules={[{ required: true, message: "This field can't be empty" }]}
                                    >
                                      <Select
                                        allowClear
                                        options={getAvailableOptions(index)}
                                        size="large"
                                        placeholder="Select Discount Type"
                                        disabled={isformVisible}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={8}>
                                    <Form.Item
                                      label="Minimum Quantity"
                                      name={[name, 'minimum_quantity']}
                                      rules={[{ required: true, message: "This field can't be empty" }]}
                                    >
                                      <InputNumber
                                        className="w-[100%]"
                                        size="large"
                                        placeholder="Add Minimum Quantity"
                                        disabled={isformVisible}
                                        min={1}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={12}>
                                  {discType === 'FIXED' && (
                                    <Col xs={24} md={8}>
                                      <Form.Item
                                        label="Absolute Discount Value"
                                        name={[name, 'discount_value']}
                                        rules={[
                                          { required: true, message: "This field can't be empty" },
                                          {
                                            validator: (_, value) => {
                                              const originalPrice = salesPriceInfo_Data[key]?.original_list_price;
                                              if (discType === 'FIXED' && value > originalPrice) {
                                                return Promise.reject(
                                                  new Error(
                                                    'Absolute Discount Value cannot exceed the Original List Price.'
                                                  )
                                                );
                                              }
                                              return Promise.resolve();
                                            }
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          className="w-[100%]"
                                          size="large"
                                          min={1}
                                          placeholder="Add Absolute Discount Value"
                                          disabled={isformVisible}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                  {discType === 'SPECIAL-PRICE' && (
                                    <>
                                      <Col xs={24} md={8}>
                                        <Form.Item
                                          label="Special Discount Value"
                                          name={[name, 'discount_value']}
                                          rules={[
                                            { required: true, message: "This field can't be empty" },
                                            {
                                              validator: (_, value) => {
                                                const originalPrice = salesPriceInfo_Data[key]?.original_list_price;
                                                if (discType === 'SPECIAL-PRICE' && value > originalPrice) {
                                                  return Promise.reject(
                                                    new Error(
                                                      'Special Discount Value cannot exceed the Original List Price.'
                                                    )
                                                  );
                                                }
                                                return Promise.resolve();
                                              }
                                            }
                                          ]}
                                        >
                                          <InputNumber
                                            className="w-[100%]"
                                            size="large"
                                            placeholder="Enter Special Discount Value"
                                            disabled={isformVisible}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </>
                                  )}
                                  {discType === 'PERCENTAGE' && (
                                    <Col xs={24} md={8}>
                                      <Form.Item
                                        label="Percentage Discount Value"
                                        name={[name, 'discount_value']}
                                        rules={[
                                          { required: true, message: "This field can't be empty" },
                                          {
                                            validator: (_, value) => {
                                              if (discType === 'PERCENTAGE' && value > 100) {
                                                return Promise.reject(
                                                  new Error('Percentage Discount Value cannot exceed 100.')
                                                );
                                              }
                                              return Promise.resolve();
                                            }
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          className="w-[100%]"
                                          size="large"
                                          min={1}
                                          max={100}
                                          placeholder="Percentage Discount Value"
                                          disabled={isformVisible}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}

                                  <Col xs={24} md={8}>
                                    <Form.Item
                                      label="Unit Of Measure"
                                      name={[name, 'unit_of_measure']}
                                      rules={[{ required: true, message: "This field can't be empty" }]}
                                    >
                                      <Select
                                        placeholder="Select an option"
                                        size="large"
                                        options={uomOptions}
                                        disabled={isformVisible}
                                      ></Select>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </React.Fragment>
                          );
                        })}
                        {!isformVisible && (
                          <Form.Item>
                            <Row justify={'start'}>
                              <Col xs={24} md={4}>
                                <Button
                                  onClick={() => add({ discount_type: undefined })} // Set a default value
                                  icon={<PlusOutlined />}
                                  disabled={allDiscountsUsed} // Disable when all discounts are used
                                >
                                  <BoldButtonLabel labelText="Add Sales Price Info" />
                                </Button>
                              </Col>
                            </Row>
                          </Form.Item>
                        )}
                      </>
                    )}
                  </Form.List>
                );
              }}
            </Form.Item>
          );
        }}
      </Form.Item>

      <Row gutter={[12, 12]}>
        {!isformVisible && (
          <Col xs={24} md={6}>
            <Button size="large" block className="mb-4" htmlType="submit" type="primary">
              <BoldButtonLabel labelText="Save" />
            </Button>
          </Col>
        )}
        <Col xs={24} md={6}>
          <Button size="large" block className="mb-4" onClick={handleGoBack}>
            <BoldButtonLabel labelText="Go Back" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default PriceEnteriesProductForm;

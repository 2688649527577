import { Card } from 'antd';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useState } from 'react';
import forge from 'node-forge';
import { PUBLICKEY } from '../../../constants/common-constants';
import { useLoader } from '../../../stores/use-loader';
import { promotionService } from '../services/promotion.service';
import _ from '../../../helpers/lodash';

interface ITestEncryption { }
const TestEncryption: React.FunctionComponent<ITestEncryption> = () => {
  const [inputText, setInputText] = useState('');
  const [encryptedText, setEncryptedText] = useState('');
  const [decryptedText, setDecryptedText] = useState('');
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const handleDecrypt = async () => {
    setLoading(true);
    try {
      // Send the encrypted text to the decryption API
      const { data, errors } = await promotionService.promoEncryption(
        { operation: 'decrypt' },
        `${encryptedText}`
      );

      if (_.isEmpty(errors)) {
        setDecryptedText(data); // Assuming the response contains decryptedText
      } else {
        console.error('Decryption API errors:', errors);
        setDecryptedText('Decryption API call failed.');
      }
    } catch (error) {
      console.error('Decryption failed:', error);
      setDecryptedText('Decryption failed.');
    }
    setLoading(false);
  };

  const encrypt = async () => {
    setLoading(true);
    try {
      // Convert PEM to a forge public key object
      //----------------------------------Generated PK-----------------------
      //   const keypair = forge.pki.rsa.generateKeyPair({ bits: 2048, e: 0x10001 });
      //   const GeneratedKey = keypair.publicKey;
      //   console.log('GeneratedKey', GeneratedKey);
      //----------------------------------Generated PK-----------------------
      //---------------------------CONST PK -----------------------------
      const publicKey = forge.pki.publicKeyFromPem(PUBLICKEY);
      //---------------------------CONST PK -----------------------------
      // Convert the input text to a byte buffer
      const buffer = forge.util.createBuffer(inputText, 'utf8');
      // Encrypt the buffer using RSA-OAEP
      const encrypted = publicKey.encrypt(buffer.getBytes(), 'RSA-OAEP', {
        md: forge.md.sha256.create(),
        mgf1: forge.mgf.mgf1.create(forge.md.sha256.create())
      });
      // Convert the encrypted data to base64 for display
      const base64 = forge.util.encode64(encrypted);
      setEncryptedText(base64);
      setDecryptedText(''); // Clear decrypted text on new encryption
    } catch (error) {
      console.error('Encryption failed:', error);
      setEncryptedText('Encryption failed.');
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <div>
            <h2>RSA-OAEP Encryption</h2>
            <input
              type="text"
              value={inputText}
              onChange={e => setInputText(e.target.value)}
              placeholder="Enter text to encrypt"
            />
            <button onClick={encrypt}>Encrypt</button>

            <button onClick={handleDecrypt}>Decrypt</button>

            {encryptedText && (
              <div>
                <h3>Encrypted Text:</h3>
                <textarea readOnly value={encryptedText} style={{ width: '100%' }} />
              </div>
            )}
            {decryptedText && (
              <div>
                <h3>Decrypted Text:</h3>
                <textarea readOnly value={decryptedText} style={{ width: '100%' }} />
              </div>
            )}

          </div>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default TestEncryption;

import { Card, Col, Form, RadioChangeEvent, Row, Steps, Typography } from 'antd';
import * as React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useLoader } from '../../../../stores/use-loader';
import { promotionServiceCreate } from '../../services/promotionCreate.service';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../layouts/primary-layout';
import TenantInfo from '../../../../components/TenantIdInfo';
import _ from 'lodash';
import PromoForm from './promo-form';
import { domHelpers } from '../../../../helpers/dom.helpers';
import RewardForm from './reward-form';
import { MAX_LIMIT_PROMO } from '../../../../constants/common-constants';
import { Criteria } from '../../types/create-promo';
import { getDayJsObjectWithFormattedString } from '../../../../helpers/date.helpers';

interface IEditPromotionPageProps {}

const EditPromo: React.FunctionComponent<IEditPromotionPageProps> = props => {
  const { id } = useParams();
  const [promotionForm] = Form.useForm();
  const [searchParams] = useSearchParams();
  const promoName = searchParams.get('promotion');
  const promo_header_type = searchParams.get('promo_header_type');
  const [promotionResponse, setPromotionResponse] = React.useState({} as any);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const [currentStep, setCurrentStep] = React.useState(0);
  const [bdyData, setBdyData] = React.useState({} as any);
  const [validTimeFrame, setValidTimeFrame] = React.useState(1);
  const [radioValue, setRadioValue] = React.useState(1);
  const [discountData, setDiscountData] = React.useState<any>(null);

  React.useEffect(() => {
    loadPromotionDetails();
  }, []);

  const extractCountryValue = (criteria: { criteria_list: Criteria[] }) => {
    const countryCriterion = criteria.criteria_list.find(
      criterion => criterion.type === 'COUNTRY' && criterion.values?.length > 0
    );

    return countryCriterion?.values[0] || '';
  };

  const getExcludedProductsFromApiCriterea = (criteria: { criteria_list: Criteria[] }): string[] => {
    const productExlcusionCriterea = criteria.criteria_list.find(
      criterion =>
        criterion.type === 'PRODUCTS' && criterion.operation === 'EXCLUDE' && criterion.value_type === 'SKU_ID'
    );

    return productExlcusionCriterea?.values || [];
  };

  const extractValuesFromCriteras = (criteria: any, key: any) => {
    criteria.criteria_list = criteria.criteria_list.filter((item: any) => item !== null);
    const criterea = criteria?.criteria_list?.find(
      (criterion: any) => criterion.type === key && criterion.values?.length > 0
    );
    return criterea?.values || [];
  };

  const RFMextractValuesFromCriteras = (criteria: any, key: any) => {
    let nedC = criteria.criteria_list.filter((criteria: any) => criteria !== null);
    let data = nedC?.filter((item: any) => item.type === 'RFM_CATEGORY');
    return data.length > 0 ? data[0].values : [];
  };

  const loadInitialApiValuesIntoForm = (data: any) => {
    const {
      title,
      status,
      application_type,
      trigger,
      promotion_type,
      criterias,
      timezone,
      discount,
      visible_on_popup,
      priority,
      buyer_selection,
      product_selection,
      products,
      is_pos,
      is_online,
      pos_list,
      online_list,
      max_usage,
      limit_per_user,
      new_buyer,
      seller_selection,
      sellers,
      application,
      reward_type,
      reward_name,
      reward_value,
      visibility,
      tier_restriction,
      buyers,
      code_type,
      code,
      coupon_quantity_to_generate,
      start_date,
      end_date,
      start_time,
      end_time,
      transaction_apply_count,
      valid_on_given_timeframe,
      schedule,
      applies_to_product_in_sale,
      ignore_price_lock,
      combine_with_other_promo,
      combine_promo_ids,
      currency,
      next_order_applicable,
      no_of_next_orders
    } = data as any;
    // ----------------------------------------------------------------------
    const { daily, daily_info, monthly, monthly_info, seasonal, seasonal_info, weekly, weekly_info } =
      schedule || ({} as any);
    const { from_date, to_date, from_time, to_time } = daily_info || ({} as any);
    // ----------------------------------------------------------------------
    const countryValue: string = extractCountryValue(criterias);
    const excluded_products = getExcludedProductsFromApiCriterea(criterias);
    // ----------------------------------------------------------------------
    let use_max_limit = false;
    if (max_usage >= MAX_LIMIT_PROMO) {
      use_max_limit = true;
    }
    let customerRestrictionSelection = buyer_selection === 'ALL' ? 'ALL' : 'SPECIFIC';
    // ----------------------------------------------------------------------
    const customerSegment = extractValuesFromCriteras(criterias, 'CUSTOMER_SEGMENT');
    const customerTire = extractValuesFromCriteras(criterias, 'CUSTOMER_TIER');
    const rfmCategories = RFMextractValuesFromCriteras(criterias, 'RFM_CATEGORIES');
    // ----------------------------------------------------------------------
    const filters = [];
    if (tier_restriction) filters.push({ filter_type: 'TIER', customerTireRestriction: tier_restriction });
    if (!_.isEmpty(customerSegment)) filters.push({ filter_type: 'SEGMENT', segment: customerSegment });
    if (!_.isEmpty(customerTire)) filters.push({ filter_type: 'TIER', customerTireRestriction: customerTire });
    if (!_.isEmpty(rfmCategories)) filters.push({ filter_type: 'RFM_CATEGORY', RFM_CATEGORY: rfmCategories });
    // ----------------------------------------------------------------------
    const validTimeFrame = valid_on_given_timeframe ? 1 : 2;
    setValidTimeFrame(validTimeFrame);
    // ----------------------------------------------------------------------
    setRadioValue(visible_on_popup ? 1 : 2);
    // ----------------------------------------------------------------------
    setDiscountData(discount); //for <ProductReward compo
    // ----------------------------------------------------------------------
    const { discount_type, apply_discount_on, discount_value, max_discount } = discount[0];
    let formValues: any = {
      title,
      status,
      trigger,
      promotionType: promotion_type,
      country: countryValue,
      timeZone: timezone,
      currency,
      next_order_applicable: next_order_applicable ? true : false,
      no_of_next_orders,
      appliesTo: application_type, //change all appliesTo -> application_type after Full Complete
      rewardType: reward_type, //change all rewardType -> reward_type after Full Complete
      isAvailablePos: is_pos,
      isAvailableOnline: is_online,
      chooseEcomType: online_list.length === 0 ? 'All' : 'Specific',
      chooseStoreType: pos_list.length === 0 ? 'All' : 'Specific',
      posList: online_list || [],
      onlineList: pos_list || [],
      excluded_products,
      onlyForNewUser: new_buyer,
      limit_per_user,
      max_usage,
      use_max_limit,
      dateRangeInSchedule: [
        getDayJsObjectWithFormattedString(start_date, 'YYYY-MM-DD'),
        getDayJsObjectWithFormattedString(end_date, 'YYYY-MM-DD')
      ],
      timeRangeInSchedule_startTime: getDayJsObjectWithFormattedString(start_time, 'HH:mm:ss'),
      timeRangeInSchedule_endTime: getDayJsObjectWithFormattedString(end_time, 'HH:mm:ss'),
      daily_val: daily,
      week_val: weekly,
      month_val: monthly,
      seasonal_val: seasonal,
      dateRangeInScheduleFilter: [
        getDayJsObjectWithFormattedString(from_date, 'YYYY-MM-DD'),
        getDayJsObjectWithFormattedString(to_date, 'YYYY-MM-DD')
      ],
      timeRangeInScheduleFilter: [
        getDayJsObjectWithFormattedString(from_time, 'HH:mm:ss'),
        getDayJsObjectWithFormattedString(to_time, 'HH:mm:ss')
      ],
      weekValues: weekly_info,
      monthValues: monthly_info,
      seasonalValues: seasonal_info,
      applies_to_product_in_sale,
      combine_with_other_promo,
      ignore_price_lock,
      for_generate: visibility, //change all for_generate -> visibility after Full Complete
      customerRestriction: customerRestrictionSelection,
      filters,
      priority,
      discount_type: discount_type,
      apply_discount_on: apply_discount_on,
      discount_value: discount_value,
      max_discount: max_discount
    };

    promotionForm.setFieldsValue(formValues);
  };

  const loadPromotionDetails = async () => {
    setLoading(true);
    const { data, errors } = await promotionServiceCreate.getPromotionByPromotionId(id as string);

    if (_.isEmpty(errors)) {
      setPromotionResponse(data);
      loadInitialApiValuesIntoForm(data);
    } else {
      displayErrorNotifications(errors);
      setLoading(false);
    }
    // setLoading(false);
  };

  const handleClickNext = async (currentFormValues: any, bodyData: any) => {
    domHelpers.scrollMainContentToTop();
    setBdyData(bodyData);
    setCurrentStep(prev => prev + 1);
  };

  const handleOnClickStep = (step: any) => {
    setCurrentStep(step);
  };

  const handleOnGoBack = (step: any, combinedBodyData: any) => {
    setPromotionResponse(promotionResponse);
    setBdyData(combinedBodyData);
    setCurrentStep(step);
  };

  const handleOnGoBackReward = (body: any) => {
    setBdyData(body);
  };

  const handleOnRadioValue = (data: any) => {
    setRadioValue(data);
  };

  const handleValidTimeFrame = (e: RadioChangeEvent) => {
    setValidTimeFrame(e.target.value);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Promotion - {promotionResponse?.title}
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <div className="w-1/2 mt-6">
            <Steps
              responsive
              current={currentStep}
              onChange={handleOnClickStep}
              items={[
                {
                  title: 'Promotion Creation'
                },
                {
                  title: 'Rewards'
                }
              ]}
            />
          </div>
          <div className="pt-6">
            {currentStep === 0 && (
              <PromoForm
                promotionFormInitialValues={promotionResponse}
                viewOnly={false}
                handleClickNext={handleClickNext}
                promotionForm={promotionForm}
                validTimeFrame={validTimeFrame}
                radioValue={radioValue}
                discountData={discountData}
                handleOnRadioVale={handleOnRadioValue}
                handleValidTimeFrame={handleValidTimeFrame}
                bdyData={bdyData}
              />
            )}
            {currentStep === 1 && (
              <RewardForm
                handleOnGoBack={handleOnGoBack}
                promotionResponse={promotionResponse}
                promotionForm={promotionForm.getFieldsValue()}
                bdyData={bdyData}
                handleOnGoBackReward={handleOnGoBackReward}
              />
            )}
          </div>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default EditPromo;

import { Button, Card, Col, Form, Popconfirm, Row, Select, Spin, Table, TableColumnsType, Typography } from 'antd';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../components/ActionButton';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TenantInfo from '../../../components/TenantIdInfo';
import { convertQueryStringToObj, objectHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { useReward } from '../../promotion-engine/hooks/drop-down-hooks/use-rewards';
import { useRewardNames } from '../hooks/use-reward-names';
import { loyaltyService } from '../services/loyalty.service';
import { IPointConversionListingItem, IPointConversionListingType } from '../types/point-conversion';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { t } from 'i18next';

interface IPointConversionRulesListingProps { }



const PointConversionRulesListing: React.FunctionComponent<IPointConversionRulesListingProps> = props => {
  const [listingResponse, setListingResponse] = React.useState({} as IPointConversionListingType);
  const { t } = useTranslation();

  const [expandedRowData, setExpandedRowData] = React.useState({} as Record<string, IPointConversionListingItem[]>);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([] as string[]);

  const { debouncedFetchRewarsDropdownOptions, rewardDropDownFetch, rewardDropDownOptions } = useReward();

  const statusList = [
    { value: 'OPEN', label: t('open') },
    { value: 'ACTIVE', label: t('active') },
    { value: 'EXPIRED', label: t('expired') },
    { value: 'DEFFERED', label: t('deferred') },
    { value: 'ON_HOLD', label: t('onHold') }
  ];

  const columns: TableColumnsType<IPointConversionListingItem> = [
    {
      title: t('ruleName'),
      render(value, record, index) {
        return <>{record.name}</>;
      },
      align: 'center'
    },
    {
      title: t('status'),
      render(value, record, index) {
        return <>{record.status === 'ON_HOLD' ? t('onHold') : t((record.status || "")?.toLowerCase())}</>;
      },
      align: 'center'
    },
    {
      title: t('rewardName'),
      render(value, record, index) {
        return <>{record.reward_name}</>;
      },
      align: 'center'
    },
    {
      title: t('version'),
      render(value, record, index) {
        return <>{record.version}</>;
      },
      align: 'center'
    },
    {
      title: t('action'),
      render(value, record, index) {
        return (
          <section className="flex justify-center gap-4">
            <ActionButton
              action="VIEW"
              title={t('viewPointConversionRule')}
              onClick={() => navigate(`/loyalty/config/point-conversion/${record.id}`)}
            ></ActionButton>

            <ActionButton
              action="CREATE_NEW_VERSION"
              title={t('createNewVersionOfThisRule')}
              onClick={() => navigate(`/loyalty/config/point-conversion/${record.id}/new?id=${record.id}`)}
            ></ActionButton>

            <ActionButton
              action="EXPAND_VERSIONS"
              title={t('viewVersions')}
              onClick={() => handleTableExpand(record)}
            ></ActionButton>
          </section>
        );
      },
      align: 'center'
    },

    {
      title: t('statusUpdate'),
      render(value, record, index) {
        let actions: { label: string; action: string }[] = [];

        if (record.status === 'OPEN') {
          actions.push({ label: t('activate'), action: 'ACTIVE' });
        }
        if (record.status === 'ACTIVE') {
          actions.push(
            ...[
              {
                label: t('onHold'),
                action: 'ON_HOLD'
              },

              {
                label: t('defer'),
                action: 'DEFFERED'
              }
            ]
          );
        }
        if (record.status === 'ON_HOLD') {
          actions.push(
            ...[
              { label: t('activate'), action: 'ACTIVE' },
              {
                label: t('defer'),
                action: 'DEFFERED'
              }
            ]
          );
        }

        return (
          <section className="flex justify-center">
            {actions.map(({ label, action }) => {
              return (
                <>
                  <Popconfirm
                    title={
                      label === 'Defer'
                        ? t('areYouSureYouWantToDefer')
                        : t('areYouSureYouWantToAction', { label })
                    }
                    okText={t('ok')}
                    cancelText={t('cancel')}
                    onConfirm={() =>
                      handleStatusChange(action, record.id, {
                        reward_id: record.reward_id,
                        version: record.version
                      })
                    }
                  >
                    <Button key={action} type="link">
                      {label}
                    </Button>
                  </Popconfirm>
                </>
              );
            })}
          </section>
        );
      },
      align: 'center'
    }
  ];

  const handleStatusChange = async (
    status: string,
    id: string,
    rewardDetails: { reward_id: string; version: number }
  ) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.changePointConversionRuleStatus(
      id,
      status,
      rewardDetails.reward_id,
      rewardDetails.version
    );
    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      displaySuccessNotification({ message: t("ruleUpdatedSuccessfully") });
      const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
      await Promise.allSettled([
        pageControl?.currentPage == 1
          ? handleFilterSearch(pageControl?.currentPage - 1)
          : setPageControl(prev => ({ ...prev, currentPage: 1 })),
        fetchRuleVersions(id)
      ]);
    }
    setLoading(false);
  };

  const expandedRowRender = () => {
    const columns: TableColumnsType<IPointConversionListingItem> = [
      {
        title: t('ruleName'),
        render(value, record, index) {
          return <>{record.name}</>;
        },
        align: 'center'
      },
      {
        title: t('status'),
        render(value, record, index) {
          return <>{record.status === 'ON_HOLD' ? t('onHold') : t((record.status || "")?.toLowerCase())}</>;
        },
        align: 'center'
      },

      {
        title: t('version'),
        render(value, record, index) {
          return <>{record.version}</>;
        },
        align: 'center'
      },
      {
        title: t('action'),
        render(value, { id }, index) {
          return (
            <Button type="link" onClick={() => navigate(`/loyalty/config/point-conversion/${id}`)}>
              View
            </Button>
          );
        },
        align: 'center'
      },
      {
        title: t('action'),
        /* 
  
  open -> active -> differed
  
  open -> active -> expired
  
  open -> active -> on_hold -> active
  
  open -> active -> on_hold -> differed*/
        render(value, record, index) {
          let actions: { label: string; action: string }[] = [];

          if (record.status === 'OPEN') {
            actions.push({ label: t('activate'), action: 'ACTIVE' });
          }
          if (record.status === 'ACTIVE') {
            actions.push(
              ...[
                {
                  label: t('onHold'),
                  action: 'ON_HOLD'
                },

                {
                  label: t('defer'),
                  action: 'DEFFERED'
                }
              ]
            );
          }

          if (record.status === 'ON_HOLD') {
            actions.push(
              ...[
                { label: t('activate'), action: 'ACTIVE' },
                {
                  label: t('defer'),
                  action: 'DEFFERED'
                }
              ]
            );
          }
          return (
            <section className="flex justify-center">
              {actions.map(({ label, action }) => (
                <Button
                  key={action}
                  type="link"
                  onClick={() =>
                    handleStatusChange(action, record.id, {
                      reward_id: record.reward_id,
                      version: record.version
                    })
                  }
                >
                  {label}
                </Button>
              ))}
            </section>
          );
        },
        align: 'center'
      }
    ];
    return <Table bordered dataSource={expandedRowData[expandedRowKeys[0]]} pagination={false} columns={columns} />;
  };

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const [searchForm] = Form.useForm();
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');

    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });
  const loadInitialData = async () => {
    if (!_.isEmpty(queryStringObj)) {
      searchForm.setFieldsValue(queryStringObj);
    }
    await handleFilterSearch(pageControl?.currentPage - 1);
  };

  React.useEffect(() => {
    if (pageControl?.currentPage && pageControl?.pageSize) loadInitialData();
  }, [pageControl]);

  const { rewardNameSelectOptions } = useRewardNames();

  const handleFilterSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    const params = { ...formValues, offset, limit: pageControl.pageSize };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);

    const { data, errors } = await loyaltyService.getPointConversion({
      offset,
      limit: pageControl?.pageSize,
      rewardId: formValues.reward_id,
      status: formValues.status
    });

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      const transformedItems = data?.data?.map((item: any) => ({ ...item, key: item.id }));
      const transformedData = { ...data, data: transformedItems };
      setListingResponse(transformedData);
    }
    setLoading(false);
  };

  const handleClickCreate = () => {
    navigate('/loyalty/config/point-conversion/create');
  };

  const handlePageChange = (currentPage: number) => {
    const offset = (currentPage - 1) * 10;
    handleFilterSearch(offset);
  };

  const handleTableExpand = async (record: IPointConversionListingItem) => {
    if (expandedRowKeys.includes(record.id)) {
      return setExpandedRowKeys([]);
    }

    if (_.isEmpty(expandedRowData[record.id])) {
      await fetchRuleVersions(record.id);
    }

    setExpandedRowKeys([record.id]);
  };

  const fetchRuleVersions = async (id: string) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getPointConversionVersions(id);
    if (_.isEmpty(errors)) {
      setExpandedRowData(rowData => ({ ...rowData, [id]: data }));
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnClear = () => {
    searchForm.setFieldsValue({
      reward_id: null,
      status: null
    });
    handleFilterSearch();
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('pointConversion')}
              </Typography.Title>
            </Col>
            <Col>
              <Button type="primary" size="large" onClick={handleClickCreate}>
                <BoldButtonLabel labelText={t('Create')}></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
          <TenantInfo />
          <div className="my-4">
            <Form
              onFinish={() =>
                pageControl?.currentPage == 1
                  ? handleFilterSearch(0)
                  : setPageControl(prev => ({ ...prev, currentPage: 1 }))
              }
              form={searchForm}
              layout="vertical"
            >
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item name={'reward_id'} label={t('rewardName')}>
                    <Select
                      // mode="multiple"
                      showSearch
                      allowClear
                      size="large"
                      filterOption={false}
                      placeholder={t('rewardName')}
                      notFoundContent={rewardDropDownFetch ? <Spin size="small" /> : null}
                      onSearch={async searchTerm => {
                        if (searchTerm) await debouncedFetchRewarsDropdownOptions(searchTerm);
                      }}
                      options={rewardDropDownOptions}
                      onClear={() => {
                        searchForm.setFieldsValue({
                          reward_id: null
                        });
                        handleFilterSearch();
                      }}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name={'status'} label={t('status')}>
                    <Select
                      placeholder={t('status')}
                      size="large"
                      options={statusList}
                      allowClear
                      onClear={() => {
                        searchForm.setFieldsValue({
                          status: null
                        });
                        handleFilterSearch();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={3}>
                  <Button type="primary" htmlType="submit" size="large" block>
                    <BoldButtonLabel labelText={t('search')}></BoldButtonLabel>
                  </Button>
                </Col>
                <Col xs={12} md={3}>
                  <Button size="large" onClick={handleOnClear} block>
                    <BoldButtonLabel labelText={t('clear')} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
          <section className="mt-4">
            <Table
              loading={false}
              expandable={{ expandedRowRender, expandedRowKeys, showExpandColumn: false }}
              pagination={{
                current: pageControl?.currentPage,
                total: listingResponse?.page_info?.total_pages * pageControl?.pageSize || 0,
                pageSize: pageControl?.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],
                onChange: (currentPage, pageSize) => setPageControl({ currentPage, pageSize }),
                locale: {
                  items_per_page: `${t('page')}`,

                },
              }}
              locale={{
                emptyText: t('noData'),
              }}
              bordered
              dataSource={listingResponse.data}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default PointConversionRulesListing;

import { Card, Col, Row, Typography } from 'antd';
import * as React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import PromotionForm from '../components/promotion-form';
import { promotionServiceCreate } from '../services/promotionCreate.service';
import TenantInfo from '../../../components/TenantIdInfo';

interface IEditPromotionPageProps {}

const EditPromotionPage: React.FunctionComponent<IEditPromotionPageProps> = props => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const promoName = searchParams.get('promotion');
  const promo_header_type = searchParams.get('promo_header_type');
  const [promotionResponse, setPromotionResponse] = React.useState({} as any);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const [closedStatusPreference, setClosedStatusPreference] = React.useState(false);
  const [statusPreference, setStatusPreference] = React.useState(false);

  React.useEffect(() => {
    loadPromotionDetails();
  }, []);

  const loadPromotionDetails = async () => {
    setLoading(true);
    const { data, errors } = await promotionServiceCreate.getPromotionByPromotionId(id as string);

    if (_.isEmpty(errors)) {
      setPromotionResponse(data);
      if (data.status === 'CLOSED') {
        setClosedStatusPreference(true);
      }
      if (data.status === 'ON_HOLD' || data.status === 'ACTIVE') {
        setStatusPreference(true);
      }
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Promotion - {promotionResponse?.title}
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <div className="flex gap-4 mt-4 mb-2">
            <Typography.Text className="text-[#898c98]">
              Promotion Type - <span className="font-bold text-black">{promoName}</span>
            </Typography.Text>
            <Typography.Text className="text-[#898c98]">
              Trigger - <span className="font-bold text-black">{promo_header_type}</span>{' '}
            </Typography.Text>
          </div>
          <PromotionForm
            promotionFormInitialValues={promotionResponse}
            viewOnly={closedStatusPreference}
            statusPreference={statusPreference}
          />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default EditPromotionPage;

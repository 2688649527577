import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../../stores/use-loader';
import { useClientInfo } from '../../stores/use-auth';
import { urlHelpers } from '../../helpers';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import { loggingIn } from '../../services/login.services';
import _ from '../../helpers/lodash';
import styled from 'styled-components';
import { useLocale } from '../../stores/use-locale';
import { ENV } from '../../config/envConfig';
import i18n from '../../i18n';
import { localeEjjeList, localeList } from '../../data/intl-data';
import { t } from 'i18next';

interface IUpdatePwd {}

const TransparentSelect = styled(Select)`
  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    color: white;
  }

  .ant-select-arrow {
    color: white;
  }

  .ant-select-item-option-content {
    color: white;
  }

  .ant-select-item-option {
    background-color: transparent !important;
    color: white;
  }

  .ant-select-item-option-selected {
    color: white !important;
    background-color: rgba(255, 255, 255, 0.2) !important; 
`;

const UpdatePwd: React.FunctionComponent<IUpdatePwd> = () => {
  const [updatePwdForm] = Form.useForm();
  const navigate = useNavigate();

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const { clientEmail, resetToken } = useClientInfo(({ clientEmail, resetToken }) => ({
    clientEmail,
    resetToken
  }));
  const { locale, setUserLocale } = useLocale(({ locale, setUserLocale }) => ({ locale, setUserLocale }));

  const { businessGroupId: businessGrpIdFromUrl, tenantId: tenandIdFromUrl } =
    urlHelpers.getTenantIdAndBusinessGroupIdFromUrl();

  const handleOnSubmit = async () => {
    setLoading(true);
    const { password, confirm_password } = updatePwdForm.getFieldsValue();
    const bodyData = {
      new_password: password,
      confirm_password: confirm_password,
      email: clientEmail,
      reset_token: resetToken
    };
    const { data, errors } = await loggingIn.resetForgetPassword(bodyData, businessGrpIdFromUrl, tenandIdFromUrl);
    if (_.isEmpty(errors)) {
      navigate('/login');
      displaySuccessNotification(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(true);
  };

  const selectLocale = (
    <div className="absolute right-[40px] top-[40px] login-language-dropdown">
      <TransparentSelect
        value={ENV === 'DEVELOPMENT' ? locale : 'IN'}
        onChange={(value: any) => {
          i18n.changeLanguage(value);
          setUserLocale(value);
        }}
        options={ENV === 'DEVELOPMENT' ? localeList : localeEjjeList}
        style={{ width: 'fit-content' }}
        disabled={ENV !== 'DEVELOPMENT'}
        className="text-white"
        dropdownStyle={{
          backgroundColor: 'transparent'
        }}
        defaultValue={'IN'}
      />
    </div>
  );

  return (
    <>
      <div className="main">
        <div className="base_container">
          <div className="cartoon_frame">
            <div>{selectLocale}</div>
            <div className="h-[100%] flex flex-row justify-center items-center">
              <Form
                form={updatePwdForm}
                layout="vertical"
                onFinish={() => {
                  handleOnSubmit();
                }}
              >
                <Card className="xl:w-[450px] lg:w-[450px] md:w-[450px] sm:w-[33%]">
                  <div className="mt-1 flex justify-center">
                    <span className="font-extrabold text-[18px] text-[#008080]" translate="no">
                      {t('resetPassword')}
                    </span>
                  </div>
                  <Row gutter={24} className="mt-4">
                    <Col xs={24} md={24}>
                      <Form.Item
                        label={<span className="text-[#5A607F]">{t('enterNewPassword')}</span>}
                        name={'password'}
                        required
                        className="mb-2"
                        rules={[
                          { required: true, message: t('thisFieldCantBeEmpty') },
                          {
                            pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])(?=.{8,})(?!.*\s)/,
                            message: t('passwordValidationReset')
                          }
                        ]}
                      >
                        <Input.Password placeholder={t('enterNewPassword')} size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} className="mt-4">
                    <Col xs={24} md={24}>
                      <Form.Item
                        label={<span className="text-[#5A607F]">{t('confirmNewPassword')}</span>}
                        name={'confirm_password'}
                        dependencies={['password']}
                        required
                        className="mb-2"
                        rules={[
                          { required: true, message: t('thisFieldCantBeEmpty') },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error(t('passwordMatchValidator')));
                            }
                          })
                        ]}
                      >
                        <Input.Password placeholder={t('confirmNewPassword')} size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12} className="mt-4">
                    <Col xs={24} md={15} lg={15}>
                      <Button size="large" block type="primary" htmlType="submit">
                        <BoldButtonLabel labelText={t('resetPassword')} />
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePwd;

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
  TableColumnsType,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../../components/ActionButton';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import TenantInfo from '../../../../components/TenantIdInfo';
import CustomPagination from '../../../../components/custom-pagination';
import { convertQueryStringToObj, objectHelpers } from '../../../../helpers';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useLoader } from '../../../../stores/use-loader';
import { loyaltyService } from '../../services/loyalty.service';
import ChannelModal from './channel-modal';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import { t } from 'i18next';


interface IChannelListingProps { }




const ChannelListing: React.FunctionComponent<IChannelListingProps> = () => {
  //constants
  const [channelListingForm] = Form.useForm();
  const [formData] = Form.useForm();
  const { t } = useTranslation();

  //State
  const [channelModal, setChannelModal] = React.useState(false);
  const [modalHead, setModalHead] = React.useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [channelListResp, setChannelListResp] = React.useState({} as any);
  const [currentRec, setCurrentRec] = React.useState({} as any);
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');

    return {
      currentPage: offset > 0 ? offset : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  //Zusthand
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const channelOptions = [
    { label: t('ecomOnline'), value: 'ECOM' },
    { label: t('store'), value: 'STORE' },
    { label: t('marketPlace'), value: 'MARKETPLACE' }
  ];

  const columns: TableColumnsType<any> = [
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('channelName')}
          <div>
            <CaretUpOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'channel_name', 'asc');
              }}
            />
            <CaretDownOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'channel_name', 'desc');
              }}
            />
          </div>
        </div>
      ),
      render(value, record, index) {
        return <>{record.channel_name}</>;
      }
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('channelType')}
          <div>
            <CaretUpOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'channel_type', 'asc');
              }}
            />
            <CaretDownOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'channel_type', 'desc');
              }}
            />
          </div>
        </div>
      ),
      render(value, record, index) {
        let channelType = '';

        if (record.channel_type === 'ECOM') {
          channelType = 'ecom';
        } else if (record.channel_type === 'STORE') {
          channelType = 'store';
        } else if (record.channel_type === 'MARKETPLACE') {
          channelType = 'marketPlace';
        }
        // Add more conditions as needed

        return <>{t(channelType)}</>;
      },
    },
    {
      title: t('channelDescription'),
      align: 'center',
      render(value, record, index) {
        return <div className="whitespace-nowrap overflow-hidden text-ellipsis w-[150px]">{record.channel_desc}</div>;
      }
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('createdDate')}
          <div>
            <CaretUpOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'created_date_time', 'asc');
              }}
            />
            <CaretDownOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'created_date_time', 'desc');
              }}
            />
          </div>
        </div>
      ),
      align: 'center',
      render(value, record, index) {
        let date = record?.created_date_time ? dayjs(record?.created_date_time).format('DD/MM/YYYY') : '';
        let time = record?.created_date_time ? dayjs(record?.created_date_time).format('HH:mm:ss') : '';
        return (
          <>
            {date} {time}
          </>
        );
      }
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('createdBy')}
          <div>
            <CaretUpOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'created_by', 'asc');
              }}
            />
            <CaretDownOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'created_by', 'desc');
              }}
            />
          </div>
        </div>
      ),
      align: 'center',
      render(value, record, index) {
        return <>{record.created_by}</>;
      }
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('updatedDate')}
          <div>
            <CaretUpOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'last_updated_date_time', 'asc');
              }}
            />
            <CaretDownOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'last_updated_date_time', 'desc');
              }}
            />
          </div>
        </div>
      ),
      align: 'center',
      render(value, record, index) {
        let date = record?.last_updated_date_time ? dayjs(record?.last_updated_date_time).format('DD/MM/YYYY') : '';
        let time = record?.last_updated_date_time ? dayjs(record?.last_updated_date_time).format('HH:mm:ss') : '';
        return (
          <>
            {date} {time}
          </>
        );
      }
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('updatedBy')}
          <div>
            <CaretUpOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'updated_by', 'asc');
              }}
            />
            <CaretDownOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'updated_by', 'desc');
              }}
            />
          </div>
        </div>
      ),
      align: 'center',
      render(value, record, index) {
        return <>{record.updated_by}</>;
      }
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {t('isActive')}
          <div>
            <CaretUpOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'is_active', 'asc');
              }}
            />
            <CaretDownOutlined
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => {
                handleFilterSearch(0, 'is_active', 'desc');
              }}
            />
          </div>
        </div>
      ),
      align: 'center',
      render(value, record, index) {
        return (
          <>
            <Popconfirm
              title={t('areYouSure')}
              onConfirm={() => {
                handleOnStatusChange(record?.channel_id, record?.is_active);
              }}
              okText={t('ok')}
              cancelText={t('cancel')}
            >
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} checked={record?.is_active} />
            </Popconfirm>
          </>
        );
      }
    },
    {
      title: t('actions'),
      align: 'center',
      render(value, record, index) {
        return (
          <section className="flex justify-center">
            <ActionButton
              action="CREATE_NEW_VERSION"
              onClick={() => {
                setCurrentRec(record);
                setModalHead('Update');
                formData.setFieldsValue({
                  channel_name: record.channel_name,
                  channel_type: record.channel_type,
                  channel_desc: record.channel_desc
                });
                setChannelModal(true);
              }}
              title={t('edit')}
            />
            <Popconfirm
              title={t('areYouSure')}
              okText={t('ok')}
              cancelText={t('cancel')}
              onConfirm={() => {
                handleOnConfirmDelete(record?.channel_id);
              }}
            >
              <ActionButton action="DELETE" title={t('delete')} />
            </Popconfirm>
          </section>
        );
      }
    }
  ];


  React.useEffect(() => {
    if (pageControl?.currentPage && pageControl?.pageSize) loadInitialData();
  }, [pageControl]);

  React.useEffect(() => {
    const queryString = searchParams.toString();
    const queryStringObj = convertQueryStringToObj(queryString);
    if (!_.isEmpty(queryStringObj)) {
      channelListingForm.setFieldsValue(queryStringObj);
    }
  }, []);

  const handleOnConfirmDelete = async (id: any) => {
    setLoading(true);
    const { errors } = await loyaltyService.removeChannel(id);
    if (!_.isEmpty(errors)) {
      setLoading(false);
      displayErrorNotifications(errors);
    } else {
      pageControl?.currentPage == 1 ? handleFilterSearch(0) : setPageControl(prev => ({ ...prev, currentPage: 1 }));
    }
    setLoading(false);
  };

  const handleOnStatusChange = async (id: any, currentStatus: boolean) => {
    setLoading(true);
    const paramData = {
      active: !currentStatus
    };
    const { errors } = await loyaltyService.channelStatusUpdate(id, paramData);
    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      const queryString = searchParams.toString();
      const queryStringObj = convertQueryStringToObj(queryString);
      handleFilterSearch(pageControl?.currentPage - 1);
    }
    setLoading(false);
  };

  const loadInitialData = async () => {
    await handleFilterSearch(pageControl?.currentPage - 1);
  };

  const handleFilterSearch = async (offset = 0, sortKey = '', sortWay = '') => {
    setLoading(true);
    if (!sortWay) {
      const ascParam = searchParams.get('asc');
      const descParam = searchParams.get('desc');
      if (ascParam) {
        sortWay = 'asc';
        sortKey = ascParam;
      }
      else if (descParam) {
        sortWay = 'desc';
        sortKey = descParam;
      }
    }

    const formValues = {
      [sortWay || 'asc']: sortKey || 'channel_name',
      'channel-name': channelListingForm.getFieldsValue()?.channel_name,
      'channel-type': channelListingForm.getFieldsValue()?.channel_type
    };
    const params = { ...formValues, offset, limit: pageControl?.pageSize };

    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);

    const { data, errors } = await loyaltyService.getChannelList(filteredParams);

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      setChannelListResp(data);
    }
    setLoading(false);
  };
  const handlePageChange = (currentPage: number) => {
    // setCurrentPage(currentPage);
    const offsetValue = currentPage - 1;
    handleFilterSearch(offsetValue);
  };

  const handleOnModalClose = () => {
    setChannelModal(false);
    formData.resetFields();
  };

  const handleOnSave = async () => {
    setLoading(true);
    const bodyData = formData.getFieldsValue();
    if (modalHead === 'Create') {
      const { errors } = await loyaltyService.createChannel(bodyData);
      if (!_.isEmpty(errors)) {
        displayErrorNotifications(errors);
      } else {
        handleFilterSearch();
        handleOnModalClose();
      }
    } else {
      const { errors } = await loyaltyService.updateChannel(bodyData, currentRec?.channel_id);
      if (!_.isEmpty(errors)) {
        displayErrorNotifications(errors);
      } else {
        handleFilterSearch();
        handleOnModalClose();
      }
    }
    setLoading(false);
  };

  const handleOnClear = () => {
    channelListingForm.resetFields();
    handleFilterSearch();
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'}>
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('channel')}
              </Typography.Title>
            </Col>
            <Col>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  setModalHead('Create');
                  setChannelModal(true);
                }}
              >
                <BoldButtonLabel labelText={t('create')} />
              </Button>
            </Col>
          </Row>
          <TenantInfo />
          <div className="my-4">
            <Form
              form={channelListingForm}
              layout="vertical"
              onFinish={() => {
                pageControl?.currentPage == 1
                  ? handleFilterSearch(0)
                  : setPageControl(prev => ({ ...prev, currentPage: 1 }));
              }}
            >
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item name={'channel_name'} label={t('channelName')}>
                    <Input className="w-full" size="large" allowClear placeholder={t('enterChannelName')} />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name={'channel_type'} label={t('channelType')}>
                    <Select
                      placeholder={t('selectChannelType')}
                      size="large"
                      allowClear
                      options={channelOptions}
                      onClear={() => {
                        channelListingForm.setFieldsValue({ channel_type: null });
                        handleFilterSearch();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={3}>
                  <Button type="primary" htmlType="submit" size="large" block>
                    <BoldButtonLabel labelText={t('search')}></BoldButtonLabel>
                  </Button>
                </Col>
                <Col xs={12} md={3}>
                  <Button size="large" onClick={handleOnClear} block>
                    <BoldButtonLabel labelText={t('clear')} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
          <section className="mt-4">
            <Table
              loading={false}
              pagination={{
                current: pageControl?.currentPage,
                total: channelListResp?.page_info?.total_pages * pageControl?.pageSize || 0,
                pageSize: pageControl?.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],
                onChange: (currentPage, pageSize) => setPageControl({ currentPage, pageSize }),
                locale: {
                  items_per_page: `${t('page')}`
                }
              }}
              locale={{
                emptyText: t('noData'),
              }}
              bordered
              dataSource={channelListResp.data || []}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>
        <Modal
          centered
          width={900}
          open={channelModal}
          onCancel={handleOnModalClose}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>{t(modalHead)}  {t('channel')}</Typography.Title>
            </div>
          }
        >
          <ChannelModal
            formInstance={formData}
            modalHead={modalHead}
            handleOnModalClose={handleOnModalClose}
            handleOnSave={handleOnSave}
          />
        </Modal>
      </div>
    </PrimaryLayout>
  );
};

export default ChannelListing;

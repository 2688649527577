const ENVIRONMENTS = {
  DEVELOPMENT: 'DEVELOPMENT',
  BETA: 'BETA',
  PRODUCTION: 'PRODUCTION',
  UAT: 'UAT'
};

const toggles = {
  common: {
    ENABLE_SIDE_MENU_AUTHORIZATION: false,
    ENABLE_PRICELIST: true,
    ENABLE_LOGISTICS: false,
    TEST_ENCRYPTION: true,
    NEW_SUB_TOTAL_DISCOUNT: true,
    COMMERCIAL_BALANCE_REPORT: true
  },
  [ENVIRONMENTS.DEVELOPMENT]: {
    ENABLE_SIDE_MENU_AUTHORIZATION: true,
    ENABLE_PRICELIST: false,
    ENABLE_LOGISTICS: true,
    TEST_ENCRYPTION: true,
    NEW_SUB_TOTAL_DISCOUNT: true,
    COMMERCIAL_BALANCE_REPORT: true
  },
  [ENVIRONMENTS.BETA]: {
    ENABLE_SIDE_MENU_AUTHORIZATION: true,
    ENABLE_PRICELIST: false,
    ENABLE_LOGISTICS: false,
    TEST_ENCRYPTION: false,
    NEW_SUB_TOTAL_DISCOUNT: false,
    COMMERCIAL_BALANCE_REPORT: false
  },
  [ENVIRONMENTS.BETA]: {
    ENABLE_SIDE_MENU_AUTHORIZATION: true,
    ENABLE_PRICELIST: false,
    ENABLE_LOGISTICS: false,
    TEST_ENCRYPTION: false
  },
  [ENVIRONMENTS.UAT]: {
    ENABLE_SIDE_MENU_AUTHORIZATION: true,
    ENABLE_PRICELIST: false,
    ENABLE_LOGISTICS: false,
    TEST_ENCRYPTION: false
  },
  [ENVIRONMENTS.PRODUCTION]: { ENABLE_LOGISTICS: false }
};

const environment = process.env.REACT_APP_WEB_ENV as string;

const getEnvSpecificToggles = () => {
  return {
    ...toggles.common,
    ...toggles[environment]
  };
};

const FEATURE_FLAGS = { ...getEnvSpecificToggles() };

export { FEATURE_FLAGS };

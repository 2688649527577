import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import { Button, Card, Col, Form, Input, Row, Select, Typography } from 'antd';
import DmnModeler from 'dmn-js';
import TenantInfo from '../../../components/TenantIdInfo';
import { useRewardNames } from '../hooks/use-reward-names';
import '../styles/custom-create-point-conversion.scoped.css';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { useNavigate, useParams } from 'react-router-dom';
import { loyaltyService } from '../services/loyalty.service';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import { useLoader } from '../../../stores/use-loader';
import '../../../i18n';
import { useTranslation } from 'react-i18next';

interface IViewPointConversionRuleProps { }

const ViewPointConversionRule: React.FunctionComponent<IViewPointConversionRuleProps> = props => {
  const { rewardNameSelectOptions } = useRewardNames();

  let modeller: any = null;

  const modellerRef = React.useRef<any>(null);

  const { id } = useParams();
  const { t } = useTranslation();

  const initialXML =
    '<?xml version="1.0" encoding="UTF-8"?>\r\n<definitions xmlns="https://www.omg.org/spec/DMN/20191111/MODEL/" id="definitions_07uqaar" name="definitions" namespace="http://camunda.org/schema/1.0/dmn" exporter="dmn-js (https://demo.bpmn.io/dmn)" exporterVersion="14.1.1">\r\n  <decision id="decision_11mjt9j" name="Point Conversion Rule">\r\n    <decisionTable id="decisionTable_0k1wdl7">\r\n      <input id="input1" label="Point">\r\n        <inputExpression id="inputExpression1" typeRef="number">\r\n          <text>point</text>\r\n        </inputExpression>\r\n      </input>\r\n      <output id="output1" label="Factor" name="factor" typeRef="number" />\r\n    </decisionTable>\r\n  </decision>\r\n</definitions>';

  const [ruleDetail, setRuleDetail] = React.useState({} as { xml: string; reward_id: string; name: string });
  React.useEffect(() => {
    if (_.isEmpty(ruleDetail)) fetchRuleDetail();

    if (modeller === null && ruleDetail.xml) initializeDiagram();

    return () => {
      if (modeller !== null) {
        modeller.detach();
        modeller = null;
      }
    };
  }, [ruleDetail]);

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const fetchRuleDetail = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getPointConversionDetail(id as string);

    if (_.isEmpty(errors)) {
      setRuleDetail(data);
      viewForm.setFieldsValue(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const initializeDiagram = async () => {
    modeller = new DmnModeler({
      container: '#pointConversionViewCanvas'
    });
    modellerRef.current = modeller;

    try {
      const { warnings } = await modeller.importXML(ruleDetail.xml);

      if (warnings.length) {
        console.log('import with warnings', warnings);
      } else {
        console.log('import successful');
      }
    } catch (err) {
      console.log('something went wrong:', err);
    }
  };

  const navigate = useNavigate();
  const [viewForm] = Form.useForm();

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('pointConversionRule')}
              </Typography.Title>
            </Col>
          </Row>
          <div className="flex gap-4">
            <TenantInfo />
            <Typography.Text className="text-[#898c98]">
              {t('rewardType')} - <span className="font-bold text-black">{t('points')}</span>{' '}
            </Typography.Text>
          </div>

          <section className="mt-4">
            <Form layout="vertical" disabled form={viewForm}>
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item name="reward_id" label={t("rewardName")}>
                    <Select placeholder={t("selectRewardType")} size="large" options={rewardNameSelectOptions} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="name" label={t("conversionRuleName")}>
                    <Input size="large" placeholder={t("enterRuleName")} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </section>
          <div id="pointConversionViewCanvas" className="h-[400px]"></div>

          <Row gutter={12}>
            <Col xs={24} md={8} lg={6}>
              <Button block size="large" onClick={() => navigate(`/loyalty/config/point-conversion`)}>
                <BoldButtonLabel labelText={t("goBack")} />
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default ViewPointConversionRule;

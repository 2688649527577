import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import { Card, Col, Form, Row, Typography } from 'antd';
import TenantInfo from '../../../components/TenantIdInfo';
import RewardForm from '../components/RewardForm';
import { useLoader } from '../../../stores/use-loader';
import { loyaltyService } from '../services/loyalty.service';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

interface ICreateRewardPageProps {}

const CreateRewardPage: React.FunctionComponent<ICreateRewardPageProps> = props => {
  const [createRewardForm] = Form.useForm();
  const {t} = useTranslation();

  const { loading, setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const onFormSave = async () => {
    setLoading(true);
    const { name, reward_type, description } = createRewardForm.getFieldsValue();

    const { errors } = await loyaltyService.createReward({ description, name, reward_type });
    setLoading(false);
    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      displaySuccessNotification({ message: t("rewardCreatedSuccessfully") });
      navigate(-1);
    }
  };

  const navigate = useNavigate();

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
               {t("createApplicableReward")}
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <RewardForm formInstance={createRewardForm} onFinish={onFormSave} />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CreateRewardPage;

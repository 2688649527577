import * as React from 'react';
import { IProfileDetailsResponse } from '../../../types/profile.types';
import { Tabs, TabsProps, Typography } from 'antd';
import _ from '../../../helpers/lodash';
import NebulaConfiguration from './app-configurations/nebula-configuration';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { useTranslation } from 'react-i18next';
import '../../../i18n';

interface ITenantConfigurationProps {
  profileDetails: IProfileDetailsResponse;
}

const TenantConfiguration: React.FunctionComponent<ITenantConfigurationProps> = ({ profileDetails }) => {

  const subscribedApps = profileDetails?.tenant_details?.subscriptions?.subscription_info || [];
  const showTabs = !_.isEmpty(subscribedApps);
  const {t} = useTranslation();

  const getTabItems = () => {
    const nebulaTab: { id: string; tab: any } = {
      id: 'nebula',
      tab: {
        key: 'nebula',
        children: <NebulaConfiguration profileDetails={profileDetails} />,
        tabKey: 'nebula',
        label: <BoldButtonLabel labelText={t("nebula")} />
      }
    };
    
    const tabs: TabsProps['items'] = [];
    subscribedApps.forEach(({ is_enabled, product_id }) => {
      if (!is_enabled) return;
      if (product_id === 'NEBULA' || product_id === "nebula") tabs.push(nebulaTab.tab);
    });

    return tabs;
  };

  const tabData = getTabItems();
  const tabItems: TabsProps['items'] = tabData

  return tabData.length !== 0 ? showTabs ? (
    <Tabs defaultActiveKey="1" items={tabItems} />
  ) : (
    <Typography.Text>{t("youHaveNotSubscribedToAnyOfOurApps")}</Typography.Text>
  ) : <Typography.Text>{t("noConfiguration")}</Typography.Text>
};

export default TenantConfiguration;

import { Button, Card, Col, Form, Popconfirm, Row, Select, Switch, Table, TableColumnsType, Typography } from 'antd';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../components/ActionButton';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TenantInfo from '../../../components/TenantIdInfo';
import { convertQueryStringToObj, urlHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { loyaltyService } from '../services/loyalty.service';
import { IRewardListItem, IRewardListResponse } from '../types/rewards';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';


interface IRewardListPageProps { }

const RewardListPage: React.FunctionComponent<IRewardListPageProps> = props => {
  const [rewardListResponse, setRewardListResponse] = React.useState({} as IRewardListResponse);
  const { t } = useTranslation();

  const columns: TableColumnsType<IRewardListItem> = [
    {
      title: t('rewardName'),
      render(value, record, index) {  
        return <>{record.name}</>;
      },
      align: 'center'
    },
    {
      title: t('rewardType'),
      render(value, record, index) {
        return <>{t((record.reward_type || '')?.toLowerCase())}</>;
      },
      align: 'center'
    },
    {
      title: t('rewardStatus'),
      render(value, record, index) {
        return (
          <>
            {/* {record.active ? 'Active' : 'Inactive'} */}
            <Popconfirm
              title={t(`areYouSureYouWantTo${record.active ? 'Deactivate' : 'Activate'}This`)}
              // onConfirm={() => handleOnChaneStatus(record?.is_active ? false : true, record?.item_id)}
              okText={t('ok')}
              cancelText={t('cancel')}
              onConfirm={() => handleStatusChange(!record.active, record)}
            >
              <Switch
                size="default"
                checked={record?.active ? true : false}
                checkedChildren={t('active')}
                unCheckedChildren={t('inactive')}
              />
            </Popconfirm>
          </>
        );
      },
      align: 'center'
    },
    {
      title: t('action'),
      render(value, record, index) {
        return (
          <section className="flex justify-center gap-4">
            <ActionButton
              action="CREATE_NEW_VERSION"
              onClick={() => {
                const backUrl = urlHelpers.getBackUrl(searchParams);
                navigate(`/loyalty/config/rewards/${record.id}?backUrl=${backUrl}`);
              }}
              title={t('edit')}
            />
            {/* <Popconfirm
              title={`Are you sure you want to ${record.active ? 'Deactivate' : 'Activate'} this?`}
              onConfirm={() => handleStatusChange(!record.active, record)}
            >
              <ActionButton
                action={record.active ? 'DEACTIVATE' : 'ACTIVATE'}
                // onClick={() => handleStatusChange(!record.active, record)}
                title={record.active ? t("deactivate") : t("activate")}
              />
            </Popconfirm> */}
            <Popconfirm title={t('areYouSureYouWantToDeleteThis')} onConfirm={() => handleRewardDelete(record)}
              okText={t('ok')}
              cancelText={t('cancel')}
            >
              <ActionButton tooltipPlacement="bottom" action="DELETE" title={t("delete")} />
            </Popconfirm>
          </section>
        );
      },
      align: 'center'
    }
  ];
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchForm] = Form.useForm();

  const handleStatusChange = async (isActive: boolean, record: IRewardListItem) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.changeRewardStatus(record.id, isActive);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('rewardUpdatedSuccessfully') });
      await loadInitialData();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };
  const handleRewardDelete = async (record: IRewardListItem) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.deleteReward(record.id);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t("rewardDeletedSuccessfully") });
      await loadInitialData();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const loadInitialData = async () => {
    const page_number = parseInt(searchParams.get('page_number') || '1');
    const page_size = parseInt(searchParams.get('page_size') || '10');
    await handleFilterSearch(page_number - 1, page_size);
  };

  React.useEffect(() => {
    const queryString = searchParams.toString();
    const queryStringObj = convertQueryStringToObj(queryString);
    if (!_.isEmpty(queryStringObj)) {
      searchForm.setFieldsValue(queryStringObj);
    }
  }, []);

  React.useEffect(() => {
    loadInitialData();
  }, [searchParams]);

  const handleFilterSearch = async (offset = 0, limit = 10) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    const params = { ...formValues, page_number: offset + 1, page_size: limit };

    // const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    // setSearchParams(filteredParams);

    let active = formValues.status;

    if (active) {
      active = active === 'ACTIVE';
    }

    const { data, errors } = await loyaltyService.getRewardsList({
      limit,
      offset,
      rewardType: formValues.reward_type,
      active
    });

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      setRewardListResponse(data);
    }
    setLoading(false);
  };
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const handleClickCreate = () => {
    const backUrl = urlHelpers.getBackUrl(searchParams);
    navigate(`/loyalty/config/rewards/create?backUrl=${backUrl}`);
  };

  const handlePageChange = (currentPage: number) => {
    const offset = currentPage - 1;
    handleFilterSearch(offset);
  };

  const handleOnClear = () => {
    searchForm.resetFields();
    handleFilterSearch();
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('rewards')}
              </Typography.Title>
            </Col>
            <Col>
              <Button type="primary" size="large" onClick={handleClickCreate}>
                <BoldButtonLabel labelText={t('create')}></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
          <TenantInfo />
          <div className="my-4">
            <Form
              onFinish={() => {
                if (parseInt(searchParams.get('page_number') || '') === 1) {
                  const page_size = parseInt(searchParams.get('page_size') || '10');
                  handleFilterSearch(0, page_size);
                } else {
                  setSearchParams(prev => {
                    prev.set('page_number', '1');
                    return prev;
                  });
                }
              }}
              form={searchForm}
              layout="vertical"
            >
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item name={'reward_type'} label={t('rewardType')}>
                    <Select
                      allowClear
                      placeholder={t("rewardType")}
                      size="large"
                      options={[
                        { label: t('points'), value: 'POINTS' },
                        { label: t('cash'), value: 'CASH' }
                      ]}
                      onClear={() => {
                        searchForm.setFieldsValue({
                          reward_type: null
                        });
                        handleFilterSearch();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name={'status'} label={t('status')}>
                    <Select
                      allowClear
                      placeholder={t("status")}
                      size="large"
                      options={[
                        { label: t('active'), value: 'ACTIVE' },
                        { label: t('inactive'), value: 'INACTIVE' }
                      ]}
                      onClear={() => {
                        searchForm.setFieldsValue({
                          status: null
                        });
                        handleFilterSearch();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={3}>
                  <Button type="primary" htmlType="submit" size="large" block>
                    <BoldButtonLabel labelText={t('search')}></BoldButtonLabel>
                  </Button>
                </Col>
                <Col xs={12} md={3}>
                  <Button size="large" onClick={handleOnClear} block>
                    <BoldButtonLabel labelText={t("clear")} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
          <section className="mt-4">
            <Table
              loading={false}
              pagination={{
                current: parseInt(searchParams.get('page_number') as string) || 1,
                pageSize: parseInt(searchParams.get('page_size') as string) || 10,
                total: rewardListResponse?.page_info?.total_pages
                  ? rewardListResponse.page_info?.total_pages * (parseInt(searchParams.get('page_size') as string) || 10)
                  : 0,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],
                onShowSizeChange: (current, size) => {
                  setSearchParams(prev => {
                    prev.set('page_size', size + '');
                    prev.set('page_number', '1');
                    return prev;
                  });
                },
                locale: {
                  items_per_page: `${t('page')}`
                },
                onChange: page => {
                  setSearchParams(prev => {
                    prev.set('page_number', page + '');
                    return prev;
                  });
                }
              }}
              locale={{
                emptyText: t('noData'),
              }}

              bordered
              dataSource={rewardListResponse.data}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default RewardListPage;

import { Button, Card, Col, Form, FormInstance, Input, Row, Select, Switch, Typography } from 'antd';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { profileService } from '../../../services';
import * as React from 'react';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { useLoader } from '../../../stores/use-loader';
import _ from '../../../helpers/lodash';
import ManageUserForm from './manage-user-form';
import TenantInfo from '../../../components/TenantIdInfo';
import { useTranslation } from 'react-i18next';



const EditUser: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const [createUserForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams({ user_name: '' });
  const [t] = useTranslation();
  const productList: { label: string; value: string }[] = [
    { label: t('opus'), value: 'opus' },
    { label: t('elysian'), value: 'elysian' },
    { label: t('nebula'), value: 'nebula' },
    { label: t('identity'), value: 'identity' }
  ];

  const roleList: { label: string; value: string }[] = [
    { label: t('root'), value: 'ROOT' },
    { label: t('readOnly'), value: 'Read Only' }
  ];


  React.useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const userName = searchParams.get('user_name') as string;
        if (!userName) return;
        const response = await profileService.getUser(userName);
        if (response.status === 200) {
          const first_name = response.data.first_name;
          const data = {
            ...response.data,
            first_name: first_name.split(' ')[0],
            middle_name: first_name.split(' ')[1] || ''
          };
          createUserForm.setFieldsValue(data);
        } else {
          displayErrorNotifications(response?.errors);
        }
      } catch (error: any) {
        displayErrorNotifications([error?.message || t('unexpectedErrorOccurred')]);
      } finally {
        setLoading(false);
      }
    };

    if (searchParams.get('user_name')) {
      fetchUser();
    }
  }, [searchParams]);

  const handleOnSave = async () => {
    setLoading(true);
    try {
      const values = createUserForm.getFieldsValue();
      const userName = searchParams.get('user_name') as string;

      const response = await profileService.updateUser(userName, values);

      if (response.status === 200) {
        displaySuccessNotification({ message: t('userUpdatedSuccessfully') });
      } else {
        displayErrorNotifications(response?.errors);
      }
    } catch (error: any) {
      displayErrorNotifications([error?.message || t('unexpectedErrorOccurred')]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Row gutter={12} justify={'space-between'}>
            <Typography.Title level={3} className="text-[#2e2a5b] pb-4">
              <div className='pb-2 pt-2'>{t('editUsers')}</div>
              <TenantInfo />
            </Typography.Title>
          </Row>

          <ManageUserForm formType={'edit'} formInstance={createUserForm} handleOnSave={handleOnSave} />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default EditUser;

import { Button, Card, Col, Form, FormInstance, Input, Row, Select, Switch, Typography } from 'antd';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useNavigate, useParams } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import * as React from 'react';
import { profileService } from '../../../services';
import { cbtProductService } from '../../../services/cbt-product.service';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import { useLoader } from '../../../stores/use-loader';
import { useAuth } from '../../../stores/use-auth';
import { useTranslation } from 'react-i18next';

interface IManageUserForm {
  formInstance: FormInstance;
  formType: 'create' | 'edit';
  handleOnSave: () => void;
}

const ManageUserForm: React.FunctionComponent<IManageUserForm> = ({ formInstance, formType, handleOnSave }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const [productOptions, setProductOptions] = React.useState<{ label: string; value: string }[]>([]);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const { tenentName, bussinessGroupName } = useAuth(({ tenentName, bussinessGroupName }) => ({
    tenentName,
    bussinessGroupName
  }));

  let productList: { label: string; value: string }[] = [];
  if (tenentName === 'sparta') {
    productList = [
      // { label: 'Opus', value: 'opus' },
      { label: 'Elysian', value: 'elysian' }
      // { label: 'Nebula', value: 'nebula' },
      // { label: 'Identity', value: 'identity' }
    ];
  } else if (tenentName === 'unicomer') {
    productList = [{ label: 'Opus', value: 'opus' }];
  } else {
    productList = [
      { label: 'Opus', value: 'opus' },
      { label: 'Elysian', value: 'elysian' }
      // { label: 'Nebula', value: 'nebula' },
      // { label: 'Identity', value: 'identity' }
    ];
  }

  const roleList: { label: string; value: string }[] = [
    // { label: t('root'), value: 'ROOT' },
    { label: t('readOnly'), value: 'Read Only' },
    { label: t('admin'), value: 'Admin' }
    // { label: t('opsAdmin'), value: 'Ops Admin' },
    // { label: t('opsUser'), value: 'Ops User' },
  ];

  React.useEffect(() => {
    if (formType === 'create') {
      formInstance.setFieldsValue({
        user_enabled: true,
        email_verified: true
      });
    }
    // getProductOptions();
  }, [formType]);

  const getProductOptions = async () => {
    try {
      setLoading(true);
      const { data } = await cbtProductService.getSubscriptions({});

      if (!Array.isArray(data?.data)) {
        throw new Error(t('invalidResponseDataFormat'));
      }

      const options = data?.data
        .map((item: any) => item.domain)
        .filter((domain: string) => domain !== 'eopus')
        .map((domain: string) => ({ label: domain, value: domain }));

      setProductOptions(options);
    } catch (error: any) {
      displayErrorNotifications(error);
      setProductOptions([]);
    }
    setLoading(false);
  };

  return (
    <Form
      form={formInstance}
      layout={'vertical'}
      onFinish={handleOnSave}
      initialValues={{
        subscriptions: formType === 'edit' ? formInstance.getFieldValue('subscriptions') : []
      }}
    >
      <Row gutter={12} className="mt-4">
        <Col xs={12} md={6}>
          <Form.Item
            name={'first_name'}
            label={t('firstName')}
            rules={[
              { required: true, message: t('fieldIsRequired') },
              {
                pattern: /^[A-Za-z.]+$/,
                message: t('fieldIsNotValid')
              }
            ]}
          >
            <Input className="w-full" allowClear placeholder={t('firstName')} />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name={'middle_name'}
            label={t('middleName')}
            rules={[
              {
                pattern: /^[A-Za-z]+$/,
                message: t('fieldIsNotValid')
              }
            ]}
          >
            <Input className="w-full" allowClear placeholder={t('middleName')} />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name={'last_name'}
            label={t('lastName')}
            rules={[
              { required: true, message: t('fieldIsRequired') },
              {
                pattern: /^[A-Za-z]+$/,
                message: t('fieldIsNotValid')
              }
            ]}
          >
            <Input className="w-full" allowClear placeholder={t('lastName')} />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name={'email'}
            label={t('email')}
            rules={[
              { required: true, message: t('fieldIsRequired') },
              {
                type: 'email',
                message: t('emailIsNotValid')
              }
            ]}
          >
            <Input className="w-full" allowClear placeholder={t('email')} disabled={formType === 'edit'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item
            name={'user_name'}
            label={t('userName')}
            rules={[{ required: true, message: t('fieldIsRequired') }]}
          >
            <Input className="w-full" allowClear placeholder={t('userName')} disabled={formType === 'edit'} />
          </Form.Item>
        </Col>
        {formType === 'create' && (
          <>
            <Col xs={12} md={6}>
              <Form.Item
                name={'password'}
                label={t('password')}
                rules={[
                  {
                    required: true,
                    message: t('fieldIsRequired')
                  },
                  {
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[A-Za-z\d\W]{8,}$/,
                    message: t('passwordValidation')
                  }
                ]}
              >
                <Input.Password className="w-full" allowClear placeholder={t('password')} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name={'confirm_password'}
                label={t('confirmPassword')}
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: t('fieldIsRequired')
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('confirmPasswordDoesNotMatch')));
                    }
                  })
                ]}
              >
                <Input.Password className="w-full" allowClear placeholder={t('confirmPassword')} />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      {formType === 'create' && (
        <>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item name="user_enabled" valuePropName="checked" label={t('enableUser')}>
                <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')}></Switch>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Form.List
        name="subscriptions"
        rules={[
          {
            validator: async (_, subscriptions) => {
              if (!subscriptions || subscriptions.length < 1) {
                return Promise.reject(new Error(t('atLeastOneSubscriptionMustBeAdded')));
              }
            }
          }
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          const optionCount = fields.length;
          const maxSubscriptions = tenentName === 'sparta' || tenentName === 'unicomer' ? 1 : 2;
          return (
            <React.Fragment>
              {fields.map(({ key, name, ...restField }, index) => {
                return (
                  <Row gutter={12} key={key}>
                    <Col xs={12} md={6}>
                      <Form.Item
                        {...restField}
                        name={[name, 'product']}
                        rules={[{ required: true, message: t('fieldIsRequired') }]}
                        label={t('product')}
                      >
                        <Select placeholder={t('selectProduct')} options={productList}></Select>
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Item
                        {...restField}
                        name={[name, 'role']}
                        rules={[{ required: true, message: t('fieldIsRequired') }]}
                        label={t('role')}
                      >
                        <Select placeholder={t('selectRole')} options={roleList}></Select>
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={2}>
                      <div className="mx-4 h-full flex justify-center items-center">
                        <DeleteOutlined
                          className="text-red-500 text-xl cursor-pointer"
                          role="button"
                          title={t('removeSubscription')}
                          onClick={() => remove(name)}
                        ></DeleteOutlined>
                      </div>
                    </Col>
                  </Row>
                );
              })}
              <Form.Item>
                <Row justify={'start'}>
                  <Col xs={24} md={4}>
                    <Button
                      onClick={() => add({ product: '', role: '' })}
                      icon={<PlusOutlined />}
                      disabled={optionCount >= maxSubscriptions}
                    >
                      <BoldButtonLabel labelText={t('addSubscription')} />
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Col>
                </Row>
              </Form.Item>
            </React.Fragment>
          );
        }}
      </Form.List>
      <Row gutter={12}>
        <Col xs={12} md={6} className="mt-7">
          <Button type="primary" htmlType="submit" block>
            <BoldButtonLabel labelText={t('save')}></BoldButtonLabel>
          </Button>
        </Col>
        <Col xs={12} md={6} className="mt-7">
          <Button type="primary" onClick={() => navigate(-1)} block>
            <BoldButtonLabel labelText={t('back')}></BoldButtonLabel>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ManageUserForm;

import * as React from 'react';

import PrimaryLayout from '../../layouts/primary-layout';
import { Card, Col, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import OperationCreationForm from '../../components/OperatorCreationForm';
import { useLoader } from '../../stores/use-loader';
import { operatorService } from '../../services';
import _ from '../../helpers/lodash';
import { displayErrorNotifications } from '../../helpers/toast.helpers';
import { getDayJsObjectWithFormattedString, getDayJsRangeFromTimeRangeString } from '../../helpers/date.helpers';
import { getCountryNameByCountry, getStateNameByCountryAndStateCode } from '../../data';
interface IOperatorDetailsProps {}

const OperatorDetails: React.FunctionComponent<IOperatorDetailsProps> = props => {
  const { operatorId } = useParams();

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const [operatorDetails, setOperatorDetails] = React.useState({} as any);

  const loadOperatorData = async () => {
    setLoading(true);
    const { data, errors } = await operatorService.getOperatorByOperatorId(operatorId as string);

    if (_.isEmpty(errors)) {
      const { operator_name, operator_type, timezone, roles, is_package_provided } = data;

      const businessEmailObject = (data.emails || []).filter((email: any) => email.type === 'BUSINESS')[0];
      const business_email = businessEmailObject ? businessEmailObject.email_id : '';
      const phoneNumberObject = data?.phone_numbers?.filter((phone: any) => phone.type === 'MOBILE')[0];
      const { number, country_code, availability } = phoneNumberObject
        ? phoneNumberObject
        : { country_code: '+91', number: '', availability: '9:00 AM - 5:00 PM' };
      const { endTime, startTime } = getDayJsRangeFromTimeRangeString(availability);
      const phone_availability = [startTime, endTime];
      const contacts = data?.contacts?.map(
        (contact: {
          type: string;
          organisation: string;
          business_unit: string;
          name: string;
          email_id: string;
          phone_number: { country_code: string; number: string };
          availability: string;
        }) => {
          const {
            type,
            organisation,
            business_unit,
            name,
            email_id,
            availability,
            phone_number: { number, country_code }
          } = contact;

          const { endTime, startTime } = getDayJsRangeFromTimeRangeString(availability);
          return {
            type,
            organisation,
            business_unit,
            name,
            email_id,
            phone_number: { number, country_code },
            phone_availability: [startTime, endTime]
          };
        }
      );

      const phone_number = { number, country_code };

      const work_week = data.work_contract?.work_week.map(
        (workWeek: {
          day_of_the_week: string;
          shift_start_time: string;
          shift_end_time: string;
          cut_off_time: string;
          capacities: any;
        }) => {
          const { capacities, cut_off_time, day_of_the_week, shift_end_time, shift_start_time } = workWeek;

          const mappedCapacities = (capacities || []).map((capacity: any) => {
            const { capacity_type, capacity_uom, slots = [] } = capacity || {};
            const mappedSlots = slots.map((slot: any) => {
              const { capacity, default_required_capacity_for_uom_line, slot_start_time, slot_end_time } = slot || {};
              return {
                capacity,
                default_required_capacity_for_uom_line,
                slot_time: [
                  getDayJsObjectWithFormattedString(slot_start_time, 'hh:mm:ss'),
                  getDayJsObjectWithFormattedString(slot_end_time, 'hh:mm:ss')
                ]
              };
            });

            return {
              capacity_type,
              capacity_uom,
              slots: mappedSlots
            };
          });

          return {
            day_of_the_week,
            shift_time: [
              getDayJsObjectWithFormattedString(shift_start_time, 'hh:mm:ss'),
              getDayJsObjectWithFormattedString(shift_end_time, 'hh:mm:ss')
            ],
            cut_off_time: getDayJsObjectWithFormattedString(cut_off_time, 'hh:mm:ss'),

            capacities: mappedCapacities
          };
        }
      );

      const holidays = (data.work_contract.holidays || []).map(
        (holiday: { reason: string; start_date: string; end_date: string; start_time: string; end_time: string }) => {
          const { end_date, end_time, reason, start_date, start_time } = holiday;
          return {
            reason,
            date: [
              getDayJsObjectWithFormattedString(start_date, 'YYYY-MM-DD'),
              getDayJsObjectWithFormattedString(end_date, 'YYYY-MM-DD')
            ],
            time: [
              getDayJsObjectWithFormattedString(start_time, 'hh:mm:ss'),
              getDayJsObjectWithFormattedString(end_time, 'hh:mm:ss')
            ]
          };
        }
      );

      const primaryAddress = data.address.primary;

      const primary = {
        type: primaryAddress.type,
        address_line1: primaryAddress.address_line1,
        address_line2: primaryAddress.address_line2,
        address_line3: primaryAddress.address_line3,
        landmark: primaryAddress.landmark,
        city: primaryAddress.city,
        postal_code: primaryAddress.postal_code,
        coordinates: { latitude: primaryAddress.coordinates.latitude, longitude: primaryAddress.coordinates.longitude },
        //!@TODO - How to get country and state from the map
        country: getCountryNameByCountry(primaryAddress?.country_code),
        state: getStateNameByCountryAndStateCode(
          getCountryNameByCountry(primaryAddress?.country_code) as string,
          primaryAddress?.state_code
        )
      };

      const other_address = (data.address?.others || []).map(
        ({
          address_line1,
          address_line2,
          address_line3,
          city,
          coordinates,
          country_code,
          landmark,
          postal_code,
          state_code,
          type
        }: {
          type: string;
          address_line2: string;
          address_line3: string;
          address_line1: string;
          landmark: string;

          city: string;
          postal_code: string;
          state_code: string;
          country_code: string;
          coordinates: any;
        }) => {
          return {
            address_line1,
            address_line2,
            address_line3,
            city,
            coordinates,
            country_code,
            country: getCountryNameByCountry(country_code),
            landmark,
            postal_code,
            state: getStateNameByCountryAndStateCode(getCountryNameByCountry(country_code) as string, state_code),
            state_code,
            type
          };
        }
      );

      const currency = (data?.packages || []).length ? data.packages[0].cost?.currency_code : undefined;

      const packages = (data?.packages || []).map((p: any) => {
        const { type, name, description, is_dimension_variable, dimension, weight_in_gm, cost } = p;

        return {
          type,
          name,
          description,
          is_dimension_variable,
          ...dimension,
          weight_in_gm,
          // currency: cost?.currency_code,
          cost: ((cost.amount || 0) / (cost.fraction || 1)).toFixed(2)
        };
      });

      const operatorFormValues = {
        operator_name,
        operator_type,
        phone_number,
        phone_availability,
        business_email,
        contacts,
        work_week,
        holidays,
        primary,
        other_address,
        timezone,
        roles,
        is_package_provided,
        packages,
        currency
      };

      setOperatorDetails(operatorFormValues);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadOperatorData();
  }, []);
  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card className="pt-">
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={2} className="text-[#2e2a5b]">
                Operator Details
              </Typography.Title>
            </Col>
          </Row>

          <OperationCreationForm fetchedOperatorInfo={operatorDetails} />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default OperatorDetails;

import * as React from 'react';
import PrimaryLayout from '../../layouts/primary-layout';
import { Col, Row, Typography, Breadcrumb } from 'antd';
import './home.css';
import { images } from '../../utils/images';
import { CarryOutFilled, AccountBookFilled, SafetyCertificateFilled, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import '../../i18n';

interface IOrderDashboardProps { }

const OrderDashboard: React.FunctionComponent<IOrderDashboardProps> = props => {
  const [greeting, setGreeting] = React.useState('');
  const { t } = useTranslation();

  React.useEffect(() => {
    const getGreeting = () => {
      const hour = new Date().getHours();
      if (hour < 12) {
        return 'goodMorning'
      } else if (hour < 18) {
        return 'goodAfternoon'
      } else {
        return 'goodEvening'
      }
    };
    setGreeting(getGreeting());
  }, []);

  return (
    <PrimaryLayout>
      <main className="mb-6 bg-white p-6 pt-2">
        <h1 className="font-inter text-xl font-semibold pb-4">{t('workplace')}</h1>
        <div className="flex items-center">
          <img src={images.nature} alt="Icon" className="w-24 h-24 rounded-full" />
          <div className="ml-4">
            <h2 className="font-inter text-[20px] font-bold pb-3">{t(greeting)}</h2>
            <p className="text-gray-500">{t('cloverBayLabs')}</p>
          </div>
        </div>
      </main>
      <div className="p-6">
        <section className="mb-4">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-[calc(50%-0.5rem)] mb-4">
              <div className="bg-white shadow-lg rounded-lg h-64 flex flex-col justify-between">
                <div className="p-6 pt-4">
                  <p className="text-gray-600 mb-1 text-[12px] font-bold">{t('welcomeBackToExcellence')}</p>
                  <h2 className="text-[18px] font-bold mb-4">{t('solutionsWithCloverBayLabs')}</h2>
                  <p className="text-gray-600 mb-4 text-[14px] mt-2">
                    {t('discoverTailoredSolutionsWithCloverBayLabs')}
                  </p>
                </div>
                <div className="custom-gradient-dashboard-3 h-14 flex items-center text-xs rounded-b-md">
                  {/* <a className="text-black pl-6 cursor-pointer flex items-center gap-1">
                <span className="">{t('readMore')}</span>
                <ArrowRightOutlined style={{ color: '#000' }} className="" />
              </a> */}
                </div>
              </div>
            </div>
            <div className="w-full md:w-[calc(50%-0.5rem)] mb-4">
              <div className="bg-white shadow-lg rounded-lg p-4 h-64 flex flex-col justify-between">
                <div
                  style={{
                    backgroundImage: `linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 100%), url(${images.dashboard1})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                  className="h-full rounded-lg flex flex-col justify-between"
                >
                  <div className="p-4">
                    <h2 className="text-[18px] font-bold text-white">{t('empoweringGlobalBusinessWithInnovation')}</h2>
                    <p className="text-gray-600 mb-4 text-white text-[14px] mt-2 lg:max-w-[80%] md:w-[100%] ">
                      {t('cloverBayLabsFuelGrowthAndTransformation')}
                    </p>
                  </div>
                  {/* <a className="text-white pl-6 pb-4 cursor-pointer text-xs">
                <span className="pb-2">{t('readMore')}</span>
                <ArrowRightOutlined style={{ color: '#fff' }} className="pt-2" />
              </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section>
        <h2 className="text-[18px] font-bold pl-6">{t('ourOfferings')}</h2>
        <div className="flex flex-wrap bg-[#E3F5F7] p-6 justify-between">
          <div className="w-full md:w-[32%] mb-4">
            <div className="bg-white shadow-lg rounded-lg h-full flex flex-col justify-between">
              <div className="p-6">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-[18px] font-bold mb-4">{t('nebulaOms')}</h3>
                  <div className="w-[45px] h-[45px] bg-[#5F38C4] flex justify-center items-center rounded-xl">
                    <AccountBookFilled style={{ color: '#fff' }} />
                  </div>
                </div>
                <p className="text-gray-600 mb-4 text-[14px]">
                  {t('empowerYourOperations')}
                </p>
              </div>
              <div className="custom-gradient-dashboard-1 h-14 flex items-center text-xs rounded-b-md">
                {/* <a className="text-white pl-6 cursor-pointer flex items-center gap-1">
              <span className="">{t('readMore')}</span>
              <ArrowRightOutlined style={{ color: '#fff' }} className="" />
            </a> */}
              </div>
            </div>
          </div>
          <div className="w-full md:w-[32%] mb-4">
            <div className="bg-white shadow-lg rounded-lg h-full flex flex-col justify-between">
              <div className="p-6">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-[18px] font-bold mb-4">{t('opusPromotion')}</h3>
                  <div className="w-[45px] h-[45px] bg-[#2D3459] flex justify-center items-center rounded-xl">
                    <CarryOutFilled style={{ color: '#fff' }} />
                  </div>
                </div>
                <p className="text-gray-600 mb-4 text-[14px]">
                  {t('revolutionizePromotions')}
                </p>
              </div>
              <div className="custom-gradient-dashboard-2 h-14 flex items-center text-xs rounded-b-md">
                {/* <a className="text-white pl-6 cursor-pointer flex items-center gap-1">
              <span className="">{t('readMore')}</span>
              <ArrowRightOutlined style={{ color: '#fff' }} className="" />
            </a> */}
              </div>
            </div>
          </div>
          <div className="w-full md:w-[32%] mb-4">
            <div className="bg-white shadow-lg rounded-lg h-full flex flex-col justify-between">
              <div className="p-6">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-[18px] font-bold mb-4">{t('elysianLoyalty')}</h3>
                  <div className="w-[45px] h-[45px] bg-[#25BDA3] flex justify-center items-center rounded-xl">
                    <SafetyCertificateFilled style={{ color: '#fff' }} />
                  </div>
                </div>
                <p className="text-gray-600 mb-4 text-[14px]">
                  {t('elevateCustomerLoyalty')}
                </p>
              </div>
              <div className="custom-gradient-dashboard-3 h-14 flex items-center text-xs rounded-b-md">
                {/* <a className="text-white pl-6 cursor-pointer flex items-center gap-1">
              <span className="">{t('readMore')}</span>
              <ArrowRightOutlined style={{ color: '#fff' }} className="" />
            </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </PrimaryLayout>
  );
};

export default OrderDashboard;

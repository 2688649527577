import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useAuth, useTenant } from './stores/use-auth';
import { loggingIn } from './services/login.services';
import _ from './helpers/lodash';
import { profileService } from './services';
import { urlHelpers } from './helpers';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import 'antd/dist/reset.css';
import 'dmn-js/dist/assets/diagram-js.css';
import 'dmn-js/dist/assets/dmn-js-drd.css';
import 'dmn-js/dist/assets/dmn-js-decision-table-controls.css';
import 'dmn-js/dist/assets/dmn-js-decision-table.css';
import 'dmn-js/dist/assets/dmn-js-literal-expression.css';
import 'dmn-js/dist/assets/dmn-js-shared.css';
import 'dmn-js/dist/assets/dmn-font/css/dmn.css';
import { useLoader } from './stores/use-loader';
import { useLocale } from './stores/use-locale';
import enUS from 'antd/es/locale/en_US';
import esES from 'antd/es/locale/es_ES';
import 'dayjs/locale/en'
import 'dayjs/locale/es'


dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const {
    setTenent,
    setIsLoggedIn,
    accessTokenData,
    setAccessToken,
    setBussinessGroupName,
    setProfileName,
    setUserMail,
    setUserName,
    tenantSecret,
    setTenantSecret,
    resetAuthStates
  } = useAuth(
    ({
      tenentName,
      setTenent,
      setIsLoggedIn,
      accessTokenData,
      setAccessToken,
      setBussinessGroupName,
      setProfileName,
      setUserMail,
      setUserName,
      tenantSecret,
      setTenantSecret,
      resetAuthStates
    }) => ({
      tenentName,
      setTenent,
      setIsLoggedIn,
      accessTokenData,
      setAccessToken,
      setBussinessGroupName,
      setProfileName,
      setUserMail,
      setUserName,
      tenantSecret,
      setTenantSecret,
      resetAuthStates
    })
  );
  const { setUserRole, setMemberOf, setResourceAccess } = useTenant(
    ({ setUserRole, setMemberOf, setResourceAccess }) => ({ setUserRole, setMemberOf, setResourceAccess })
  );
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const { businessGroupId: businessGrpIdFromUrl, tenantId: tenandIdFromUrl } =
    urlHelpers.getTenantIdAndBusinessGroupIdFromUrl();
  const { locale, setUserLocale } = useLocale(({ locale, setUserLocale }) => ({ locale, setUserLocale }));


  const verifyToken = async () => {
    setLoading(true);
    const { data: tenantSecretData } = await profileService.getTenantSecret({
      businessGroupId: businessGrpIdFromUrl,
      tenantId: tenandIdFromUrl
    });
    const tenantSecret = tenantSecretData?.secret;
    setTenantSecret(tenantSecret);

    if (accessTokenData) {
      const { data, errors } = await loggingIn.getintrospect({ tenantSecret });
      if (_.isEmpty(errors)) {
        setProfileName(data?.name);
        setUserMail(data?.email);
        setTenent(data?.tenant_profile_name);
        setUserRole(data?.realm_access?.roles);
        setUserName(data?.username);
        setBussinessGroupName(data?.tenant_business_grp_name);
        setMemberOf(data?.member_of);
        setResourceAccess(data?.resource_access);
        if (!data.active) {
          resetAuthStates();
        }
      } else {
        resetAuthStates();
      }
    } else {
      resetAuthStates();
    }
    setLoading(false);
  };

  useEffect(() => {
    verifyToken();
  }, []);

  const localeCodeForAntdTranlsation: any = {
    "CL": esES,
    "IN": enUS
  }

  const localeForMomentTranslation: any = {
    "CL": 'es',
    "IN": 'en'
  }
  dayjs.locale(localeForMomentTranslation[locale] || 'en');
  return (
    <ConfigProvider
      locale={localeCodeForAntdTranlsation[locale] || enUS}
      theme={{
        token: { fontFamily: `Inter`, colorPrimary: '#008080' },
        components: {
          Button: {
            colorPrimary: '#008080',
            colorPrimaryBgHover: '#008888',
            colorPrimaryHover: '#016161',
            colorPrimaryActive: '#004d4d'
          },
          Steps: {
            colorPrimary: '#5885AF',
            colorPrimaryBgHover: '#7399bc',
            colorPrimaryHover: '#7399bc',
            colorPrimaryActive: '#5885AF'
          },
          Input: {
            fontFamily: 'Inter, sans-serif'
          },
          InputNumber: {
            fontFamily: 'Inter, sans-serif',
            borderRadius: 0
          },
          Menu: {}
        }
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;

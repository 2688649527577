import { countryCodeMap, countryStateMap } from '../data';
import { formatSingleDate, formatTimeRange, formatTimeSingle } from './date.helpers';

const generateOperatorsRequestBody = (formValues: any) => {
  const {
    operator_name,
    operator_type,
    business_email,
    timezone,
    roles,
    primary,
    is_package_provided,
    packages,
    currency
  } = formValues;

  const {
    type,
    address_line1,
    address_line2,
    address_line3,
    landmark,
    city,
    postal_code,
    country,
    state,
    coordinates,
    custom_type
  } = primary || {};

  const others = (formValues.other_address || []).map(
    ({
      type,
      address_line1,
      address_line2,
      address_line3,
      landmark,
      city,
      postal_code,
      country,
      state,
      coordinates,
      custom_type
    }: {
      type: string;
      address_line1: string;
      address_line2: string;
      address_line3: string;
      landmark: string;
      city: string;
      postal_code: string;
      country: string;
      state: string;
      custom_type: string;
      coordinates: any;
    }) => {
      return {
        type: type,
        type_description: custom_type,
        address_line1,
        address_line2,
        address_line3,
        landmark,
        city,
        state_code: countryStateMap[country][state].state_code,
        country_code: countryCodeMap[country],
        postal_code,
        coordinates
      };
    }
  );

  const address = {
    primary: {
      type: type,
      type_description: custom_type,
      address_line1,
      address_line2,
      address_line3,
      landmark,
      city,
      state_code: countryStateMap[country][state].state_code,
      country_code: countryCodeMap[country],
      postal_code,
      coordinates
    },
    others
  };

  const work_week = (formValues?.work_week || []).map(
    ({
      cut_off_time,
      day_of_the_week,
      shift_time,
      capacities
    }: {
      day_of_the_week: string;
      shift_time: any;
      cut_off_time: any;
      shipping_capacity: number;
      picking_capacity: number;
      capacities: any;
    }) => {
      const mappedCapacities = (capacities || []).map((capacity: any) => {
        const slots = capacity?.slots || [];
        const mappedSlots = slots.map((slot: any) => {
          const { capacity, default_required_capacity_for_uom_line, slot_time } = slot || {};

          const slot_start_time = formatTimeSingle(slot_time[0]);
          const slot_end_time = formatTimeSingle(slot_time[1]);

          return {
            capacity,
            default_required_capacity_for_uom_line,
            slot_start_time,
            slot_end_time
          };
        });

        return { capacity_type: capacity?.capacity_type, capacity_uom: capacity?.capacity_uom, slots: mappedSlots };
      });

      return {
        day_of_the_week,
        shift_start_time: formatTimeSingle(shift_time[0]),
        shift_end_time: formatTimeSingle(shift_time[1]),
        cut_off_time: formatTimeSingle(cut_off_time),
        capacities: mappedCapacities
      };
    }
  );

  const holidays = (formValues?.holidays || []).map(
    ({ date, reason, time }: { date: any[]; time: any[]; reason: string }) => {
      return {
        start_date: formatSingleDate(date[0]),
        end_date: formatSingleDate(date[1]),
        start_time: formatTimeSingle(time[0]),
        end_time: formatTimeSingle(time[1]),
        reason
      };
    }
  );

  const work_contract = {
    work_week,
    holidays
  };
  //!TODO - Implement document
  const documents: any = [];

  const emails = [{ type: 'BUSINESS', email_id: business_email }];

  const phone_numbers = [
    {
      type: 'MOBILE',
      country_code: formValues.phone_number?.country_code,
      number: formValues.phone_number?.number,
      availability: formatTimeRange(formValues.phone_availability)
    }
  ];

  const contacts = (formValues?.contacts || []).map(
    ({
      type,
      organisation,
      business_unit,
      name,
      email_id,
      phone_availability,
      phone_number
    }: {
      type: string;
      organisation: string;
      business_unit: string;
      name: string;
      phone_availability: any;
      email_id: string;
      phone_number: any;
    }) => {
      return {
        type,
        organisation,
        business_unit,
        name,
        email_id,
        availability: formatTimeRange(phone_availability),
        phone_number
      };
    }
  );

  const transformedPackages = is_package_provided
    ? (packages || []).map(({ cost, length_in_mm, width_in_mm, height_in_mm, is_dimension_variable, ...rest }: any) => {
        return {
          ...rest,
          is_dimension_variable: is_dimension_variable ? true : false,
          dimension: {
            length_in_mm,
            width_in_mm,
            height_in_mm
          },
          cost: {
            currency_code: currency,
            amount: convertAmountTobaseAmount(cost, 100),
            fraction: 100
          }
        };
      })
    : [];

  const request = {
    operator_name,
    operator_type,
    address,
    work_contract,
    documents,
    emails,
    phone_numbers,
    contacts,
    timezone,
    roles,
    is_package_provided,
    packages: transformedPackages
  };

  return request;
};

const convertAmountTobaseAmount = (amount: number, fraction = 100) => {
  const baseAmount = parseInt((amount * fraction).toFixed());
  return baseAmount;
};

export const operatorHelpers = {
  generateOperatorsRequestBody
};

import { IconPencilPlus } from '@tabler/icons';
import { Avatar, Button, Col, Divider, Form, Input, List, Row, Select, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { IProfileDetailsResponse } from '../../../types/profile.types';
import _ from '../../../helpers/lodash';
import { countryCodeMap, countryStateMap, getCountryNameByCountry, stateList } from '../../../data';
import { countryList } from '../../../data/intl-data';
import { profile } from 'console';
import { useLoader } from '../../../stores/use-loader';
import { profileService } from '../../../services';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { useTranslation } from 'react-i18next';
import '../../../i18n';

interface IOrganizationInfo {
  profileDetails: IProfileDetailsResponse;
  fetchProfileInfo: () => void;
}

const OrganizationInfo: React.FunctionComponent<IOrganizationInfo> = ({ profileDetails, fetchProfileInfo }) => {
  const [addressDetailsDisablity, setAddressDetailsDisablity] = React.useState(true);

  const [OrganizationForm] = Form.useForm();
  const {t} = useTranslation();

  useEffect(() => {
    if (!_.isEmpty(profileDetails)) {
      fillFormWithProfileDetails();
    }
  }, [profileDetails]);

  const fillFormWithProfileDetails = () => {
    const addressDetail = profileDetails?.contact_details?.address_detail || {};
    const domain_url = profileDetails?.tenant_details?.domain_url;
    const business_group_name = profileDetails?.tenant_details?.business_group_name;
    const business_type = profileDetails?.tenant_details?.business_type;
    const { address_line1, address_line2, country, city, state, pincode, location } = addressDetail;
    OrganizationForm.setFieldsValue({
      addressLine1: address_line1,
      addressLine2: address_line2,
      country,
      city,
      state,
      domain_url,
      business_group_name,
      business_type,
      pincode,
      location
    });
  };

  // "country_details": [
  //   {
  //     "country_code": "cl",
  //     "base_currency": "CLP",
  //     "other_supported_currencies": [
  //       {
  //         "supported_currency": "INR",
  //         "conversion_exists": true
  //       }
  //     ]
  //   }
  // ],

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const handleAddressSave = async () => {
    setLoading(true);
    const previousAddressDetails = profileDetails.contact_details.address_detail;
    const { addressLine1, addressLine2, country, city, state, pincode, location } = OrganizationForm.getFieldsValue();
    const countryDetailsExist = countryCodeMap[country] && countryStateMap[country];
    const stateDetailsExist = countryStateMap[country] && countryStateMap[country][state];
    const addressEditRequest = {
      tenant_name: profileDetails.tenant_details.tenant_name,
      business_group_name: profileDetails.tenant_details.business_group_name,

      contact_type: 'PRIMARY',
      address_detail: {
        address_type: previousAddressDetails?.address_type,
        address_line1: addressLine1,
        address_line2: addressLine2,
        country,
        state_code: stateDetailsExist ? countryStateMap[country][state].state_code : state,
        country_code: countryDetailsExist ? countryCodeMap[country] : country,
        location,
        coordinates: {
          latitude: '',
          longitude: ''
        },
        city,
        state,

        pincode,
        timezone: previousAddressDetails?.timezone
      }
    };

    const { errors } = await profileService.editUserAddress(addressEditRequest);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('addressSuccessfullyEdited') });
      fetchProfileInfo();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <div className="min-h-screen ">
      <Form form={OrganizationForm} layout="vertical" className="space-y-6">
        <div className="bg-white rounded-lg space-y-6">
          {/* Organization Details Section */}
          <div>
            <h2 className="text-base text-gray-800 p-4 pl-6">{t('organizationDetails')}</h2>
            <Divider className='bg-[#ffffff]' />
            <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0 p-6">
              <Form.Item
                label={t('businessGroupName')}
                name="business_group_name"
                rules={[{ required: false, message: t("thisFieldCantBeEmpty") }]}
                className="flex-1"
              >
                <Input
                  size="middle"
                  placeholder={t('businessGroupName')}
                  disabled={true}
                  className="w-full border-gray-300 rounded-lg"
                />
              </Form.Item>
              <Form.Item
                label={t('domainUrl')}
                name="domain_url"
                rules={[{ required: false, message: t("thisFieldCantBeEmpty") }]}
                className="flex-1"
              >
                <Input
                  size="middle"
                  placeholder={t('domainUrl')}
                  disabled={true}
                  className="w-full border-gray-300 rounded-lg"
                />
              </Form.Item>
              <Form.Item
                label={t('organizationType')}
                name="business_type"
                rules={[{ required: false, message: t("thisFieldCantBeEmpty") }]}
                className="flex-1"
              >
                <Input
                  size="middle"
                  placeholder={t('organizationType')}
                  disabled={true}
                  className="w-full border-gray-300 rounded-lg"
                />
              </Form.Item>
            </div>
          </div>
        </div>
    
        {/* Address Details Section */}
        <div className="bg-white rounded-lg space-y-6">
          <div className="flex justify-between items-center p-4 pl-6">
            <h2 className="text-base text-gray-800 ">{t('addressDetails')}</h2>
            <Button icon={<EditOutlined />} onClick={() => setAddressDetailsDisablity((prev) => !prev)} className='flex flex-row-reverse justify-center items-center gap-1 border-none'>{t('edit')}</Button>
          </div>
          <Divider className='bg-[#ffffff]' />
          <div className="space-y-4 pb-6 pl-6 pr-6 ">
            <Form.Item
              label={t('addressLine1')}
              name="addressLine1"
              rules={[{ required: false, message: t("thisFieldCantBeEmpty") }]}
            >
              <Input
                size="middle"
                placeholder={t('addressLine1')}
                disabled={addressDetailsDisablity}
                className="w-full border-gray-300 rounded-lg"
              />
            </Form.Item>
            <Form.Item
              label={t('addressLine2')}
              name="addressLine2"
              rules={[{ required: false, message: t("thisFieldCantBeEmpty") }]}
            >
              <Input
                size="middle"
                placeholder={t('addressLine2')}
                disabled={addressDetailsDisablity}
                className="w-full border-gray-300 rounded-lg"
              />
            </Form.Item>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Form.Item
                label={t('country')}
                name="country"
                rules={[{ required: false, message: t("thisFieldCantBeEmpty") }]}
              >
                <Select
                  onChange={() => {
                    OrganizationForm.setFieldsValue({ state: null });
                  }}
                  options={countryList}
                  size="middle"
                  placeholder={t('country')}
                  disabled={addressDetailsDisablity}
                  className="w-full border-gray-300 rounded-lg"
                />
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {(form) => {
                  const { country } = form.getFieldsValue();
                  const options = stateList[country] || [];
                  return (
                    <Form.Item
                      label={t('state')}
                      name="state"
                      rules={[{ required: false, message: t("thisFieldCantBeEmpty") }]}
                    >
                      <Select
                        options={options}
                        placeholder={t('state')}
                        disabled={addressDetailsDisablity}
                        className="w-full border-gray-300 rounded-lg"
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                label={t('city')}
                name="city"
                rules={[{ required: false, message: t("thisFieldCantBeEmpty") }]}
              >
                <Input
                  placeholder={t('city')}
                  disabled={addressDetailsDisablity}
                  className="w-full border-gray-300 rounded-lg"
                />
              </Form.Item>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Form.Item
                label={t('pincode')}
                name="pincode"
                rules={[{ required: false, message: t("thisFieldCantBeEmpty") }]}
              >
                <Input
                  placeholder={t('pincode')}
                  disabled={addressDetailsDisablity}
                  className="w-full border-gray-300 rounded-lg"
                />
              </Form.Item>
              <Form.Item
                label={t('location')}
                name="location"
                rules={[{ required: false, message: t("thisFieldCantBeEmpty") }]}
              >
                <Input
                  placeholder={t('location')}
                  disabled={addressDetailsDisablity}
                  className="w-full border-gray-300 rounded-lg"
                />
              </Form.Item>
            </div>
            {!addressDetailsDisablity && (
              <div className="flex justify-end">
                <Button
                  onClick={handleAddressSave}
                  disabled={addressDetailsDisablity}
                  type="primary"
                  className="bg-blue-600 text-white px-6 py-2 rounded-lg"
                >
                  {t('saveDetails')}
                </Button>
              </div>
            )}
          </div>
        </div>
    
        {/* Country Details Section */}
        <div className="bg-white rounded-lg">
          <h2 className="text-base text-gray-800 p-4 pl-6 country-details">{t('countryDetails')}</h2>
          <Divider className='bg-[#ffffff]' />
    
          <div className="space-y-4 p-6">
            <List
              className="demo-loadmore-list"
              loading={false}
              bordered
              itemLayout="horizontal"
              dataSource={profileDetails?.tenant_details?.country_details || []}
              renderItem={(item, index) => (
                <List.Item key={index} className='border-none'>
                  <List.Item.Meta
                    title={
                      <h3 className="text-lg font-semibold  border-none">
                        {getCountryNameByCountry(item.country_code) || getCountryNameByCountry('CHL')}
                      </h3>
                    }
                    description={
                      <>
                        <p>{t('baseCurrency')} - {item.base_currency}</p>
                        {!_.isEmpty(item?.other_supported_currencies) && (
                          <p>
                            {t('otherSupportedCurrencies')} -{' '}
                            {item?.other_supported_currencies
                              ?.map(({ supported_currency }) => supported_currency)
                              .join(', ')}
                          </p>
                        )}
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </Form>
    </div>
    

  );
};
export default OrganizationInfo;

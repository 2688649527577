import { CUSTOMER_PRODUCT_URI, LOYALTY_URI, REWARD_URL } from '../../../config/envConfig';
import { objectHelpers } from '../../../helpers';
import httpClient from '../../../http/http.client';
import { useAuth } from '../../../stores/use-auth';

const loyaltyUrls = {
  getRewardsUri: () => `${LOYALTY_URI}/loyalty/v1/rewards`,
  getRewardDetailUri: (id: string) => `${LOYALTY_URI}/loyalty/v1/rewards/${id}`,
  getDeleteRewardDetailUri: (id: string) => `${LOYALTY_URI}/loyalty/v1/rewards/${id}`,
  getCreateRewardUri: () => `${LOYALTY_URI}/loyalty/v1/rewards`,
  getRewardsByType: (type: string) => `${LOYALTY_URI}/loyalty/v1/rewards/type/${type}`,
  getCreatePointRuleUri: () => `${LOYALTY_URI}/loyalty/v1/rules/point-conversions`,
  getPointConversionsUri: () => `${LOYALTY_URI}/loyalty/v1/rules/point-conversions`,
  getPointConversionVersionsUri: (id: string) => `${LOYALTY_URI}/loyalty/v1/rules/point-conversions/${id}/versions`,
  getPointConversionRuleByIdUri: (id: string) => `${LOYALTY_URI}/loyalty/v1/rules/point-conversions/${id}`,
  getPointConversionCreateNewVersionUri: (ruleId: string) =>
    `${LOYALTY_URI}/loyalty/v1/rules/point-conversions/${ruleId}`,
  getPointConversionStatusChangeUri: (id: string) =>
    `${LOYALTY_URI}/loyalty/v1/rules/point-conversions/${id}/versions/status`
};

const getRewardsList = ({
  limit,
  offset,
  active,
  rewardType
}: {
  limit: number;
  offset: number;
  rewardType?: string;
  active?: boolean;
}) => {
  const params = {
    offset,
    limit,
    active,
    rewardType
  };

  const url = loyaltyUrls.getRewardsUri();

  return httpClient.get({ url, params });
};

const getRewardDetail = (id: string) => {
  const url = loyaltyUrls.getRewardDetailUri(id);
  return httpClient.get({ url });
};

const deleteReward = (id: string) => {
  const url = loyaltyUrls.getDeleteRewardDetailUri(id);
  return httpClient.delete({ url });
};

const changeRewardStatus = (id: string, isActive: boolean) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rewards/${id}/status`;
  const params = {
    active: isActive
  };
  return httpClient.patch({ url, params });
};

const putReward = (id: string, request: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rewards/${id}`;
  return httpClient.put({ url, body: request });
};

const createReward = (request: { name: string; reward_type: string; description: string }) => {
  const url = loyaltyUrls.getCreateRewardUri();
  const body = request;
  return httpClient.post({ url, body });
};

const getRewardsByType = (type: string) => {
  const url = loyaltyUrls.getRewardsByType(type);

  return httpClient.get({ url });
};

const createPointRule = (request: any) => {
  const url = loyaltyUrls.getCreatePointRuleUri();
  const body = request;
  return httpClient.post({ url, body });
};

const getPointConversion = (params: any) => {
  const url = loyaltyUrls.getPointConversionsUri();
  return httpClient.get({ url, params });
};

const getPointConversionVersions = (id: string) => {
  const url = loyaltyUrls.getPointConversionVersionsUri(id);
  return httpClient.get({ url });
};

const getPointConversionDetail = async (id: string) => {
  const url = loyaltyUrls.getPointConversionRuleByIdUri(id);

  return httpClient.get({ url });
};

const createPointRuleNewVersion = (request: any, ruleId: string) => {
  const url = loyaltyUrls.getPointConversionCreateNewVersionUri(ruleId);
  const body = request;
  return httpClient.put({ url, body });
};

const changePointConversionRuleStatus = (id: string, status: string, reward_id: string, version: number) => {
  const url = loyaltyUrls.getPointConversionStatusChangeUri(id);
  const params = {
    version,
    status
  };

  return httpClient.patch({ url, params });
};

const getRuleEarnList = (params: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/earnings`;
  const updatedParams = {
    triggerType: params.trigger_type,
    status: params.status,
    applicabilityType: params.applicablity_type,
    limit: params.limit,
    offset: params.offset
  };
  return httpClient.get({ url, params: updatedParams });
};

const getRuleBurnList = async (params: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/burn`;
  const updatedParams = {
    'template-type': params.burn_template_type,
    status: params.status,
    limit: params.limit,
    offset: params.offset
  };
  const { data, errors } = await httpClient.get({ url, params: updatedParams });
  return { data, errors };
};

const createEarnRule = (body: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/earnings`;

  return httpClient.post({ url, body });
};

const createBurnRule = (body: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/burn`;
  return httpClient.post({ url, body });
};

const createNewBurnRule = (body: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/burn`;
  return httpClient.put({ url, body });
};

const createEarnRuleNewVersion = (body: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/earnings`;

  return httpClient.put({ url, body });
};

const changeBurnRuleStatus = (id: string, status: string) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/burn/${id}/status`;
  return httpClient.patch({ url, params: { status } });
};

const changeEarnRuleStatus = (id: string, status: string) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/earnings/${id}/status`;

  return httpClient.patch({ url, params: { status } });
};

const testEarnRule = (id: string, body: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/earnings/${id}/verifications`;

  return httpClient.post({ url, body });
};

const getEarnRuleDetail = async (id: string) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/earnings/${id}`;
  return httpClient.get({ url });
};

const getBurnRuleDetail = async (id: string) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/burn/${id}`;
  return httpClient.get({ url });
};

const getEarnRuleVersions = (id: string) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/earnings/${id}/versions`;
  return httpClient.get({ url });
};

const getBurnRuleVersions = (id: string) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/burn/${id}/versions`;
  return httpClient.get({ url });
};

const getRuleDescriptions = async (params: { triggerType: string; applicabilityType: string }) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rules/help`;

  return await httpClient.get({ url, params });
};

const getCustomerLevel = async () => {
  const url = `${LOYALTY_URI}/loyalty/v1/customers/levels`;

  return httpClient.get({ url });
};
const createCustomerLevel = async (request: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/customers/levels`;
  return httpClient.post({ url, body: request });
};

const getRewardHistory = (params: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rewards/metrics`;
  return httpClient.get({ url, params: objectHelpers.convertObjectKeysSnakeToCamel(params) });
};

const getRewardHistoryDetails = (customerId: string, params: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rewards/transactions/history`;
  const headers = {
    'x-customer-id': customerId
  };

  return httpClient.get({ url, params: objectHelpers.convertObjectKeysSnakeToCamel(params), headers });
};

const getRewardIdDetails = (id: string) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rewards/transactions/history/${id}`;
  return httpClient.get({ url });
};

const createCustomer = (request: any, headers: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/customers/v1`;
  return httpClient.post({ url, body: request, headers });
};

const putCustomer = (request: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/customers/v1`;

  return httpClient.put({ url, body: request });
};

const getCustomerDetails = (id: string) => {
  let headers = {
    'x-customer-id': id
  };
  const url = `${CUSTOMER_PRODUCT_URI}/customers/v1/id`;

  return httpClient.get({ url, headers });
};

const searchCustomer = (params: any) => {
  const snakeCaseParams = objectHelpers.convertObjectKeysSnakeToCamel(params);
  const url = `${CUSTOMER_PRODUCT_URI}/customers/v1`;
  return httpClient.get({ url, params: snakeCaseParams });
};

const uploadCustomers = (formData: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/customers/v1/upload`;
  return httpClient.post({ url, body: formData });
};

const deleteCustomer = (customerId: string) => {
  const url = `${CUSTOMER_PRODUCT_URI}/customers/v1/${customerId}`;

  return httpClient.delete({ url });
};

const downloadSampleCustomerUploadFile = () => {
  const url = `${CUSTOMER_PRODUCT_URI}/customers/v1/upload/template`;

  const headers = {
    'Content-Disposition': 'attachment; filename=template.xlsx',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  };

  return httpClient.get({ url, headers, responseType: 'arraybuffer' });
};

const createProduct = (request: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/products/v1`;
  const headers = {
    Tenant_id: useAuth?.getState()?.tenentName,
    'x-tenant-id': useAuth?.getState()?.tenentName,
    'x-business-group-id': useAuth?.getState()?.bussinessGroupName,
    Business_grp_id: useAuth?.getState()?.bussinessGroupName,
    'x-client-id': 'webui'
  };
  return httpClient.post({ url, headers, body: request });
};

const searchProduct = (params: any) => {
  const snakeCaseParams = objectHelpers.convertObjectKeysSnakeToCamel(params);
  const url = `${CUSTOMER_PRODUCT_URI}/products/v1`;
  const headers = {
    Tenant_id: useAuth?.getState()?.tenentName,
    'x-tenant-id': useAuth?.getState()?.tenentName,
    'x-business-group-id': useAuth?.getState()?.bussinessGroupName,
    Business_grp_id: useAuth?.getState()?.bussinessGroupName,
    'x-client-id': 'webui'
  };
  return httpClient.get({ url, params: snakeCaseParams, headers });
};

const updateProductStatus = (id: any, value: boolean) => {
  const url = `${CUSTOMER_PRODUCT_URI}/products/v1/${id}/status`;
  const params = {
    active: value
  };
  const headers = {
    Tenant_id: useAuth?.getState()?.tenentName,
    'x-tenant-id': useAuth?.getState()?.tenentName,
    'x-business-group-id': useAuth?.getState()?.bussinessGroupName,
    Business_grp_id: useAuth?.getState()?.bussinessGroupName,
    'x-client-id': 'webui'
  };
  return httpClient.patch({ url, params, headers, body: {} });
};

const getProductDetails = (id: string) => {
  const url = `${CUSTOMER_PRODUCT_URI}/products/v1/${id}`;
  const headers = {
    Tenant_id: useAuth?.getState()?.tenentName,
    'x-tenant-id': useAuth?.getState()?.tenentName,
    'x-business-group-id': useAuth?.getState()?.bussinessGroupName,
    Business_grp_id: useAuth?.getState()?.bussinessGroupName,
    'x-client-id': 'webui'
  };
  return httpClient.get({ url, headers });
};

const putProduct = (request: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/products/v1`;
  const headers = {
    Tenant_id: useAuth?.getState()?.tenentName,
    'x-tenant-id': useAuth?.getState()?.tenentName,
    'x-business-group-id': useAuth?.getState()?.bussinessGroupName,
    Business_grp_id: useAuth?.getState()?.bussinessGroupName,
    'x-client-id': 'webui'
  };
  return httpClient.put({ url, headers, body: request });
};

const downloadSampleProductUploadFile = () => {
  const url = `${CUSTOMER_PRODUCT_URI}/products/v1/upload/template`;

  const headers = {
    'Content-Disposition': 'attachment; filename=template.xlsx',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  };

  return httpClient.get({ url, headers, responseType: 'arraybuffer' });
};

const uploadProduct = (formData: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}productss/v1/upload`;
  return httpClient.post({ url, body: formData });
};

const getReward = (customer_id: any) => {
  const url = `${REWARD_URL}/v1/coupons/available/${customer_id}`;
  return httpClient.get({ url });
};

const getChannelList = async (params?: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/channels/v1/list`;
  const { data, errors } = await httpClient.get({ url, params });
  return { data, errors };
};

const createChannel = async (body: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/channels/v1`;
  console.log('createChannel', url);
  const { data, errors } = await httpClient.post({ url, body });
  return { data, errors };
};

const updateChannel = async (body: any, id: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/channels/v1/${id}`;
  console.log('updateChannel', url);
  const { data, errors } = await httpClient.put({ url, body });
  return { data, errors };
};

const removeChannel = async (id: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/channels/v1/${id}`;
  console.log('removeChannel', url);
  const { data, errors } = await httpClient.delete({ url });
  return { data, errors };
};

const channelStatusUpdate = async (id: any, params: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/channels/v1/${id}/status`;
  console.log('channelStatusUpdate', url);
  const { data, errors } = await httpClient.patch({ url, params });
  return { data, errors };
};

const getProductExcelValidation = async (bodyData: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/products/v1/validate`;
  return httpClient.post({ url, body: bodyData });
};

const getCustomerExcelValidation = async (bodyData: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/customers/v1/validate`;
  return httpClient.post({ url, body: bodyData });
};

const confirmUploadCustomer = async (bodyData: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/customers/v2/upload`;
  return httpClient.post({ url, body: bodyData, timeout: 600000 }); //10MIN
};

const confirmUploadProduct = async (bodyData: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/products/v2/upload`;
  return httpClient.post({ url, body: bodyData });
};

const getCustomerReport = async (bodyData: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/customers/v1/validate/report`;
  return httpClient.post({ url, body: bodyData, responseType: 'arraybuffer' });
};

const getProductReport = async (bodyData: any) => {
  const url = `${CUSTOMER_PRODUCT_URI}/products/v1/validate/report`;
  return httpClient.post({ url, body: bodyData, responseType: 'arraybuffer' });
};

const getRewardList = async (params: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rewards/name`;
  const { data, errors } = await httpClient.get({ url, params });
  return { data, errors };
};

const getAllRewardList = async () => {
  const url = `${LOYALTY_URI}/loyalty/v1/rewards`;
  const { data, errors } = await httpClient.get({ url });
  return { data, errors };
};

const getAllChannelList = async () => {
  const url = `${CUSTOMER_PRODUCT_URI}/channels/v1/list`;
  const { data, errors } = await httpClient.get({ url });
  return { data, errors };
};

const getCommercialReport = (params: any, customer = '') => {
  const url = `${LOYALTY_URI}/loyalty/v1/customers/rewards/statement`;
  if (!!customer.length) {
    const headers = {
      'x-customer-id': customer
    };
    return httpClient.get({ url, params, headers });
  } else {
    return httpClient.get({ url, params });
  }
};

export const loyaltyService = {
  getRewardsList,
  getRewardDetail,
  deleteReward,
  createReward,
  getRewardsByType,
  createPointRule,
  getPointConversion,
  getPointConversionVersions,
  getPointConversionDetail,
  createPointRuleNewVersion,
  changePointConversionRuleStatus,
  getRuleEarnList,
  createEarnRuleNewVersion,
  createEarnRule,
  changeEarnRuleStatus,
  getRuleDescriptions,
  getEarnRuleVersions,
  getEarnRuleDetail,
  testEarnRule,
  getCustomerLevel,
  createCustomerLevel,
  putReward,
  changeRewardStatus,
  getRewardHistory,
  getRewardHistoryDetails,
  getRewardIdDetails,
  createCustomer,
  putCustomer,
  searchCustomer,
  getCustomerDetails,
  uploadCustomers,
  downloadSampleCustomerUploadFile,
  createProduct,
  searchProduct,
  updateProductStatus,
  getProductDetails,
  putProduct,
  downloadSampleProductUploadFile,
  uploadProduct,
  deleteCustomer,
  getReward,
  getRuleBurnList,
  createBurnRule,
  getBurnRuleDetail,
  changeBurnRuleStatus,
  createNewBurnRule,
  getBurnRuleVersions,
  getChannelList,
  createChannel,
  updateChannel,
  removeChannel,
  channelStatusUpdate,
  getProductExcelValidation,
  getCustomerExcelValidation,
  confirmUploadCustomer,
  confirmUploadProduct,
  getCustomerReport,
  getProductReport,
  getRewardList,
  getAllChannelList,
  getAllRewardList,
  getCommercialReport
};

import newCBTIcon from "../assets/images/newIcon.png";
import cbt_background from "../assets/images/CBT_Background.png";
import login_wall from "../assets/images/login.svg";
import PlanetButton from "../assets/images/PlanetButton.svg";
import Helpicon from "../assets/images/Helpicon.svg";
import home_1 from "../assets/images/home_1.svg";
import home_p2_bg from "../assets/images/home_p2_bg.svg";
import home_r1 from "../assets/images/home_r1.svg";
import home_r2 from "../assets/images/home_r2.svg";
import home_r3 from "../assets/images/home_r3.svg";
import home_r4 from "../assets/images/home_r4.svg";
import base_landing from "../assets/images/base_landing.png";
import landing_gradiant from "../assets/images/landing_gradiant.svg";
import free_base from "../assets/images/free_base.jpg";
import new_base from "../assets/images/new_base.svg";
import cartoons from "../assets/images/cartoons.svg";
import nature from "../assets/images/nature.png";
import dashboard1 from "../assets/images/dashboard1.png";
import accountBook from "../assets/images/account-book.png";
import union from "../assets/images/union.png";
import carryOut from "../assets/images/carry-out.png";
import newcbtlogo from "../assets/images/newcbtlogo.png";

export const images = {
	newCBTIcon,
	cbt_background,
	login_wall,
	PlanetButton,
	Helpicon,
	home_1,
	home_p2_bg,
	home_r1,
	home_r2,
	home_r3,
	home_r4,
	base_landing,
	landing_gradiant,
	free_base,
	new_base,
	cartoons,
	nature,
	dashboard1,
	accountBook,
	union,
	carryOut,
	newcbtlogo,
};

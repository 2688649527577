import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Radio, RadioChangeEvent, Row, Tooltip } from 'antd';
import * as React from 'react';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { useLoader } from '../../../stores/use-loader';
import { promotionService } from '../services/promotion.service';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import { useTranslation } from 'react-i18next';

interface IPromotionApplicationMethodProps {
  handleOnChange: (value: string) => void;
  cpcActiveTab: string;
  activeTab: string;
  handleOnTabDisable: (value: boolean) => void;
}

let defaultPriority = [
  {
    param_name: 'FIXED',
    param_value: '0',
    param_category: 'DISCOUNT_TYPE_PRIORITY'
  },
  {
    param_name: 'PERCENTAGE',
    param_value: '1',
    param_category: 'DISCOUNT_TYPE_PRIORITY'
  },
  {
    param_name: 'SPL_PRICE',
    param_value: '2',
    param_category: 'DISCOUNT_TYPE_PRIORITY'
  }
];

const PromotionApplicationMethod: React.FunctionComponent<IPromotionApplicationMethodProps> = ({
  handleOnChange,
  handleOnTabDisable,
  cpcActiveTab,
  activeTab
}) => {
  const [applicationMethodForm] = Form.useForm();
  const [changePromo, setChangePromo] = React.useState(true);
  const [activePromoApplicatiMethod, setActivePromoAppliMethod] = React.useState('');
  const [creationType, setCreationType] = React.useState('Create');
  const {t} = useTranslation();

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    getControlSettingsCall();
  }, [activeTab, cpcActiveTab]);

  const getControlSettingsCall = async () => {
    setLoading(true);
    const { data, errors } = await promotionService.getControlSettings();
    if (_.isEmpty(errors)) {
      let pickData = data?.find((item: any) => item?.param_name === 'combine_promo_strategy');
      let activePromotion = pickData?.param_value;
      if (!activePromotion) {
        handleOnTabDisable(true);
        setCreationType('Create');
      } else {
        setCreationType('Save');
      }
      setActivePromoAppliMethod(activePromotion);
    }
    setLoading(false);
  };

  const onChange = (e: RadioChangeEvent) => {
    setActivePromoAppliMethod(e.target.value);
    handleOnChange(e.target.value);
    setChangePromo(false);
    handleOnTabDisable(true);
  };

  const handleSave = async () => {
    setLoading(true);
    let defaultPreority = defaultPriority;
    const bodyData = [
      {
        param_name: 'combine_promo_strategy',
        param_value: activePromoApplicatiMethod,
        param_category: 'BASIC'
      }
    ];
    let combData = [...bodyData, ...defaultPreority];
    if (creationType === 'Save') {
      const { errors } = await promotionService.patchPromotionApplicationMethod(combData);
      if (_.isEmpty(errors)) {
        handleOnTabDisable(false);
        setChangePromo(true);
      } else {
        displayErrorNotifications(errors);
      }
    }

    if (creationType === 'Create') {
      const { errors } = await promotionService.postControlSettings(combData);
      if (_.isEmpty(errors)) {
        handleOnTabDisable(false);
        setChangePromo(true);
        setCreationType('Save');
      } else {
        displayErrorNotifications(errors);
      }
    }

    setLoading(false);
  };

  const handleReset = () => {
    getControlSettingsCall();
    setChangePromo(true);
    handleOnTabDisable(false);
  };

  return (
    <Form form={applicationMethodForm} layout="vertical" onFinish={handleSave}>
      <Row gutter={12} className="mt-4">
        <Col xs={12} md={6}>
          <Form.Item name="branded" label="">
            <Radio.Group onChange={onChange} value={activePromoApplicatiMethod} className="mt-4">
              <div className="p-2">
                <Radio value={'SEQUENTIAL'}>
                  {t('sequentialMethod')}&nbsp;&nbsp;
                  <Tooltip
                    placement="top"
                    title={t('sequentialDiscountsDescription')}
                  >
                    <InfoCircleOutlined style={{ color: '#1b6ef3' }} />
                  </Tooltip>
                </Radio>
              </div>
              <div className="p-2">
                <Radio value={'STACKING'}>
                  {t('stackingMethod')}&nbsp;&nbsp;
                  <Tooltip
                    placement="top"
                    title={t('stackingDiscountsDescription')}
                  >
                    <InfoCircleOutlined style={{ color: '#1b6ef3' }} />
                  </Tooltip>
                </Radio>
              </div>
            </Radio.Group>
            <Radio.Group onChange={onChange} value={'TEST'} className="mt-4">
              {/* <Radio value={'TEST'}>TEST</Radio>
              <Radio value={'DEMO'}>DEMO</Radio> */}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={3} md={3}>
          <Button size="large" block type="primary" htmlType="submit" disabled={changePromo}>
            <BoldButtonLabel labelText={creationType} />
          </Button>
        </Col>
        <Col xs={3} md={3}>
          <Button block size="large" onClick={handleReset}>
            <BoldButtonLabel labelText={t('reset')} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default PromotionApplicationMethod;

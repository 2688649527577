// The Dev Only Changes now will go to beta
import { CloudUploadOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  TableColumnsType,
  Tag,
  Typography
} from 'antd';
import { RcFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import dayjs from 'dayjs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import ActionButton from '../../../../components/ActionButton';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import TenantInfo from '../../../../components/TenantIdInfo';
import { convertQueryStringToObj, fileHelpers, objectHelpers, urlHelpers } from '../../../../helpers';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import '../../../../i18n';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useTenant } from '../../../../stores/use-auth';
import { useLoader } from '../../../../stores/use-loader';
import { loyaltyService } from '../../services/loyalty.service';
import { ICustomer, ICustomerListingResponse } from '../../types/customer';

interface ICustomerListingPageProps {}

const CustomerListingPage: React.FunctionComponent<ICustomerListingPageProps> = props => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
  const [rewardModal, setRewardModal] = React.useState(false);
  const [rewardForm] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [rewardData, setRewardData] = React.useState([] as any);
  const [loyalityMemberTypeOption, setLoyalityMemberType] = React.useState([] as any);
  const [customerUploadResp, setCustomerUploadResp] = React.useState({} as any);
  const [confirmUploadData, setConfirmUploadData] = React.useState<any>(null);

  const [fileList, setFileList] = React.useState<any[]>([]);
  const [customerListingResponse, setCustomerListingResponse] = React.useState({} as ICustomerListingResponse);

  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  // const { userRoles } = useTenant(({ userRoles }) => ({ userRoles }));
  const { userRoles, member_of } = useTenant(({ userRoles, member_of }) => ({ userRoles, member_of }));

  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const backUrlParamString = urlHelpers.getBackUrlParamString(searchParams);

  const [pageControl, setPageControl] = React.useState(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');
    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  React.useEffect(() => {
    loadInitialData();
  }, [pageControl]);

  React.useEffect(() => {
    if (!_.isEmpty(queryStringObj)) {
      let formParams: any = { ...queryStringObj };
      if (queryStringObj.sign_up_date_from) {
        const date_range = [dayjs(queryStringObj.sign_up_date_from), dayjs(queryStringObj.sign_up_date_to)];
        formParams = { ...formParams, date_range };
      }
      searchForm.setFieldsValue(formParams);
    }
    (async () => await getloyalityMemberType())();
  }, []);

  const loadInitialData = async () => {
    await handleSearch(pageControl?.currentPage - 1);
  };

  const handleOnClear = () => {
    searchForm.resetFields();
    handleSearch(0);
  };

  const columns: TableColumnsType<ICustomer> = [
    {
      title: t('customerId'),
      align: 'center',
      render(value, record, index) {
        return <>{record.customer_id}</>;
      }
    },
    {
      title: t('customerName'),
      align: 'center',
      render(value, record, index) {
        return <>{(record.first_name || '') + ' ' + (record.last_name || '')}</>;
      }
    },
    {
      title: t('customerType'),
      align: 'center',
      render(value, record, index) {
        let data = '';
        if (record?.customer_type === 'CUS') {
          data = t('customer');
        } else if (record?.customer_type === 'EMP') {
          data = t('employee');
        } else {
          data = record?.customer_type;
        }
        return <>{data}</>;
      }
    },
    {
      title: t('loyalityMemberType'),
      align: 'center',
      render(value, record, index) {
        return <>{t((record?.tier_type || '').toLowerCase())}</>;
      }
    },

    {
      title: t('action'),
      align: 'center',
      render(value, record, index) {
        let hideDeleteForReadOnlyUsers = userRoles?.includes('READ_ONLY') || userRoles?.includes('ADMIN');
        return (
          checkForAccess(member_of, [{ product: 'elysian', role: 'ROOT' }, { product: 'opus', role: 'ROOT' }, { product: 'elysian', role: 'Admin' }, { product: 'opus', role: 'Admin' }])
          && <section className="flex justify-center items-center gap-4">
            <ActionButton
              action="CREATE_NEW_VERSION"
              onClick={() => {
                navigate(`/data-management/customers/${record.customer_id}?${backUrlParamString}`);
              }}
              title={t('viewAndEdit')}
            />
            <Popconfirm
              title={t('areYouSure')}
              onConfirm={() => handleCustomerDelete(record.customer_id)}
              okText={t('ok')}
              cancelText={t('cancel')}
            >
              {(
                <ActionButton tooltipPlacement="bottom" action="DELETE" title={t('delete')} />
              )}
            </Popconfirm>
          </section>
        );
      }
    }
  ];

  const checkForAccess = (memberOf: string[] = [], accessList: { product: string, role: string }[]) => {
    return accessList.some(access =>
      memberOf?.some(item => {
        const parts = item?.split('/');
        return parts.length > 4 && parts[2] === access.product && parts[4] === access.role;
      })
    );
  }

  const handleCustomerDelete = async (customerId: string) => {
    setLoading(true);

    const { errors } = await loyaltyService.deleteCustomer(customerId);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('customerDeletedSuccessfully') });
      await loadInitialData();
    } else {
      displayErrorNotifications(errors);
    }

    setLoading(false);
  };

  const handleClickUploadCustomers = () => {
    setUploadModalVisible(true);
  };

  const handleDownloadTemplate = async () => {
    const { data, errors } = await loyaltyService.downloadSampleCustomerUploadFile();

    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'customer_upload_template', extension: '.xlsx' });
    }
  };

  const convertExcelDateToJSDate = (excelDate: any) => {
    const baseDate = new Date(1900, 0, 1); // Excel counts from Jan 1, 1900
    const date = new Date(baseDate.setDate(baseDate.getDate() + excelDate - 1)); // Adjust for serial number
    return date.toISOString().split('T')[0]; // Format to yyyy-mm-dd
  };

  const convertExcelDate = (dateString: any): string => {
    const formats = [
      'DD-MM-YYYY',
      'YYYY-MM-DD',
      'MM/DD/YYYY',
      'YYYY/MM/DD',
      'M/D/YYYY',
      'M-D-YYYY',
      'YYYY-MM-DDTHH:mm:ssZ',
      'YYYY-MM-DDTHH:mm:ss'
    ];
    const formattedDate = convertExcelDateToJSDate(dateString);
    let parsedDate = dayjs(formattedDate, formats, true);
    if (!parsedDate.isValid()) {
      return 'Invalid date';
    }

    return parsedDate.format('YYYY-MM-DD');
  };

  const getItemRequest = (rowData: any) => {
    let data = {
      customer_id: rowData?.customer_id ? rowData?.customer_id : null,
      customer_type: rowData?.customer_type ? rowData?.customer_type : null, //, EMP, ANONYMOUS, STAFF, REGISTERED_USER
      email: rowData?.email ? rowData?.email : null,
      first_name: rowData?.first_name ? rowData?.first_name : null,
      last_name: rowData?.last_name ? rowData?.last_name : null,
      customer_segments: [], //'string', 'string'
      // new_buyer: true,
      dial_code: rowData?.dial_code ? rowData?.dial_code : null,
      phone_number: rowData?.phone_number ? rowData?.phone_number : null,
      channel_identity_info: [
        {
          channel_name: rowData.alternate_id_channel_name1 ?? null,
          channel_type: rowData.alternate_id_channel_type1 ?? null,
          alternate_id: rowData.alternate_id_value1 ?? null
        },
        {
          channel_name: rowData.alternate_id_channel_name2 ?? null,
          channel_type: rowData.alternate_id_channel_type2 ?? null,
          alternate_id: rowData.alternate_id_value2 ?? null
        },
        {
          channel_name: rowData.alternate_id_channel_name3 ?? null,
          channel_type: rowData.alternate_id_channel_type3 ?? null,
          alternate_id: rowData.alternate_id_value3 ?? null
        }
      ].filter(obj => obj?.channel_name),
      date_of_birth: rowData?.date_of_birth ? convertExcelDate(rowData?.date_of_birth) : null,
      // tier_type: 'string',
      sign_up_date: rowData?.sign_up_date ? convertExcelDate(rowData?.sign_up_date) : null,
      refered_by: rowData?.refered_by ? rowData?.refered_by : null,
      supply_type_code: rowData?.supply_type_code ? rowData?.supply_type_code : null, //,B2B
      // loyalty_number: 'string',
      postal_code: rowData?.postal_code ? rowData?.postal_code : null,
      location: rowData?.location ? rowData?.location : null,
      state: rowData?.state ? rowData?.state : null,
      country: rowData?.country ? rowData?.country : null,
      state_code: rowData?.state_code ? rowData?.state_code : null,
      country_code: rowData?.country_code ? rowData?.country_code : null,
      member_type: rowData?.source_member_type ? rowData?.source_member_type : null,
      reward_name: rowData?.reward_name ? rowData?.reward_name : null,
      active_in_days: rowData?.active_in_days ? rowData?.active_in_days : null,
      expire_in_days: rowData?.expire_in_days ? rowData?.expire_in_days : null,
      value: rowData?.reward_points ? rowData?.reward_points : null
    };

    return data;
  };

  const loadExcelBodyData = (excelData: any) => {
    let reqData = excelData.map((currentData: any) => {
      let data = getItemRequest(currentData);
      return data;
    });
    return reqData;
  };

  const getValidData = async (excelData: any) => {
    setLoading(true);
    if (excelData.length >= 25000) {
      displayErrorNotifications([{ message: t('dataCannotBeMoreThan25000Record') }]);
    } else if (excelData.length === 0) {
      displayErrorNotifications([{ message: t('noDataFoundInExcel') }]);
    } else {
      let bodyData = loadExcelBodyData(excelData);
      let formatData = { customer_list: bodyData };
      const { data, errors } = await loyaltyService.getCustomerExcelValidation(formatData);
      if (_.isEmpty(errors)) {
        setConfirmUploadData(formatData);
        setCustomerUploadResp(data);
      } else {
        displayErrorNotifications(errors);
      }
    }
    setLoading(false);
  };
  const handleCustomerUpload = async () => {
    if (_.isEmpty(fileList)) return;
    setLoading(true);

    const formData = new FormData();
    formData.set('file', fileList[0] as RcFile);
    if (fileList) {
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const data = event?.target?.result as ArrayBuffer;
        const workbook = XLSX.read(data, {
          type: 'binary'
        });
        let excelDataParsed: any[] = [];
        workbook.SheetNames.forEach(sheet => {
          if (excelDataParsed?.length === 0) {
            const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            const jsonObject = JSON.stringify(rowObject);
            const getExceldata = JSON.parse(jsonObject);
            excelDataParsed.push(getExceldata);
            // getValidData(excelDataParsed[0]);
            handleOnConfirmUpload(excelDataParsed[0]);
          }
        });
      };
      let selectedFile = fileList[0];
      fileReader.readAsBinaryString(selectedFile);
    }
    setLoading(false);
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    let params = { ...formValues, offset, limit: pageControl?.pageSize };
    if (formValues.date_range) {
      let sign_up_date_from = formValues.date_range[0].format('YYYY-MM-DD');
      let sign_up_date_to = formValues.date_range[1].format('YYYY-MM-DD');
      delete params.date_range;
      params = { ...params, sign_up_date_from, sign_up_date_to };
    }
    if (formValues.customer_id) {
      let customer_id = params.customer_id.trim();
      params = { ...params, customer_id };
    }
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);

    setSearchParams(filteredParams);
    const { data, errors } = await loyaltyService.searchCustomer(filteredParams);
    if (_.isEmpty(errors)) {
      setCustomerListingResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const getloyalityMemberType = async () => {
    const { data, errors } = await loyaltyService.getCustomerLevel();

    if (_.isEmpty(errors)) {
      let mainData = [data.registration_level];
      let allOptions = data?.levels?.map((items: any) => items?.name);
      let loadData = [...mainData, ...allOptions];
      let setoptionData = loadData.map((item: any) => {
        return { label: item, value: item };
      });
      setLoyalityMemberType(setoptionData);
    }
  };

  const removeModalData = () => {
    setFileList([]);
    setCustomerUploadResp({});
  };

  const handleOnConfirmUpload = async (excelData: any) => {
    setLoading(true);
    try {
      let bodyData = loadExcelBodyData(excelData);
      let formatData = { customer_list: bodyData };
      const { errors } = await loyaltyService.confirmUploadCustomer(formatData);

      if (_.isEmpty(errors)) {
        removeModalData();
        setUploadModalVisible(false);
        displaySuccessNotification({
          message: t('itemsAddedSuccessfully', { count: customerUploadResp?.total_success })
        });

        loadInitialData();
      } else {
        displayErrorNotifications(errors);
      }
    } catch (error) {
      console.error('Error during upload:', error);
      displayErrorNotifications([{ message: t('uploadFailed') }]);
    } finally {
      setLoading(false);
    }
  };

  const getReportData = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getCustomerReport(customerUploadResp);
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'customer_report_excel', extension: '.xlsx' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const disableFutureDates = (current: any) => {
    return current && current > dayjs().endOf('day');
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('customers')}
              </Typography.Title>
            </Col>

            <Col>
              <div className="flex gap-4">
                {checkForAccess(member_of, [{
                  product: 'elysian',
                  role: "ROOT"
                }, {
                  product: "opus",
                  role: "ROOT"
                },
                {
                  product: "elysian",
                  role: "Admin"
                },
                {
                  product: "opus",
                  role: "Admin"
                }]) && <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    const backUrl = window.location?.pathname + `?${searchParams.toString()}`;
                    const params = new URLSearchParams({ goBackTo: backUrl });
                    navigate(`/data-management/customers/create?${params.toString()}`);
                  }}
                >
                    <BoldButtonLabel labelText={t('createCustomer')} />
                  </Button>
                }
                {(checkForAccess(member_of, [{
                  product: 'elysian',
                  role: "ROOT"
                }, {
                  product: "opus",
                  role: "ROOT"
                }])) && (
                    <Button size="large" type="primary" onClick={handleClickUploadCustomers}>
                      <BoldButtonLabel labelText={t('uploadCustomers')} />
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <Form
              layout="vertical"
              onFinish={() => {
                if (pageControl.currentPage === 1) {
                  handleSearch(0);
                } else {
                  setPageControl(prev => ({
                    ...prev,
                    currentPage: 1
                  }));
                }
              }}
              form={searchForm}
            >
              <Row gutter={12} align={'middle'}>
                <Col xs={24} md={6}>
                  <Form.Item name="customer_id" label={t('customerId')}>
                    <Input
                      size="large"
                      placeholder={t('customerId')}
                      allowClear
                      onBlur={(e: any) => {
                        if (e.target.value === '') {
                          handleSearch(0);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="tier_type" label={t('loyaltyMemberType')}>
                    <Select
                      allowClear
                      size="large"
                      placeholder={t('loyaltyMemberType')}
                      options={loyalityMemberTypeOption?.map((item: any) => ({
                        ...item,
                        label: t((item.label || '').toLowerCase())
                      }))}
                      onClear={() => {
                        searchForm.setFieldsValue({
                          tier_type: null
                        });
                        handleSearch(0);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="customer_type" label={t('customerType')}>
                    <Select
                      size="large"
                      placeholder={t('customerType')}
                      allowClear
                      options={[
                        { label: t('customer'), value: 'CUS' },
                        { label: t('employee'), value: 'EMP' }
                      ]}
                      onClear={() => {
                        searchForm.setFieldsValue({
                          customer_type: null
                        });
                        handleSearch(0);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="date_range" label={t('signUpDate')}>
                    <DatePicker.RangePicker size="large" allowClear disabledDate={disableFutureDates} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={3}>
                  <Button htmlType="submit" type="primary" block size="large">
                    <BoldButtonLabel labelText={t('search')} />
                  </Button>
                </Col>
                <Col xs={12} md={3}>
                  <Button size="large" onClick={handleOnClear} block>
                    <BoldButtonLabel labelText={t('clear')} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>{' '}
          <section className="mt-4">
            <Table
              loading={false}
              pagination={{
                current: pageControl?.currentPage,
                total: customerListingResponse?.page_info?.total_pages * pageControl?.pageSize || 0,
                pageSize: pageControl?.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],
                onChange: (currentPage, pageSize) => {
                  setPageControl({ currentPage, pageSize });
                },
                locale: {
                  items_per_page: `${t('page')}`
                }
              }}
              locale={{
                emptyText: t('noData')
              }}
              bordered
              dataSource={customerListingResponse.data}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>

        <Modal
          centered
          open={uploadModalVisible}
          onCancel={() => {
            setUploadModalVisible(false);
            removeModalData();
          }}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>{t('uploadCustomers')}</Typography.Title>
            </div>
          }
        >
          <section className="flex flex-col justify-center">
            <Button type="link" className="mb-2" onClick={handleDownloadTemplate}>
              {t('downloadSampleFile')}
            </Button>
            <div className="flex justify-center mb-2">
              <Tag color="warning" role="button" className="cursor-pointer">
                {t('uploadCsvFilesOnlyWithAMaximumOf25kCustomersPerFile')}
              </Tag>
            </div>
            <Dragger
              beforeUpload={file => {
                setFileList([file]);
                return false;
              }}
              onRemove={() => {
                removeModalData();
              }}
              fileList={fileList}
              name="file"
              accept={'.csv,.xlsx'}
            >
              <p>
                <CloudUploadOutlined className="text-9xl text-[#008080]" />
              </p>
              <p>{t('clickOrDragCustomersExcelFileToUpload')}</p>
            </Dragger>

            <Button
              disabled={_.isEmpty(fileList)}
              block
              size="large"
              type="primary"
              onClick={handleCustomerUpload}
              className="mt-4"
            >
              {/* <BoldButtonLabel labelText={t('validate')} /> */}
              <BoldButtonLabel labelText={t('upload')} />
            </Button>
            {/* {Object.keys(customerUploadResp).length !== 0 && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {' '}
                <Typography.Text className="text-blue-500">
                  {t('totalItems')}: {customerUploadResp?.total_items}
                </Typography.Text>
                <Typography.Text className="text-green-500">
                  {t('totalSuccess')}: {customerUploadResp?.total_success}
                </Typography.Text>
                <Typography.Text className="text-red-500">
                  {t('uploadedFileDuplicateEntries')} : {customerUploadResp?.total_duplicate}
                </Typography.Text>
                <Typography.Text className="text-red-500">
                  {t('totalFailed')}: {customerUploadResp?.total_failed}
                </Typography.Text>
                <div className="flex gap-5">
                  <Button
                    disabled={customerUploadResp?.total_success === 0}
                    block
                    type="primary"
                    onClick={handleOnConfirmUpload}
                    className="mt-4"
                  >
                    <BoldButtonLabel labelText={t('confirmUpload')} />
                  </Button>
                  <Button block type="primary" className="mt-4" onClick={getReportData}>
                    <BoldButtonLabel labelText={t('downloadReport')} />
                  </Button>
                </div>
              </div>
            )} */}
          </section>
        </Modal>

        <Modal
          centered
          open={rewardModal}
          onCancel={() => {
            setRewardModal(false);
          }}
          width={700}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>{t('rewards')}:</Typography.Title>
            </div>
          }
        >
          <Form form={rewardForm} layout="vertical">
            {rewardData.length > 0 ? (
              <div style={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
                {rewardData.map((reward: any) => (
                  <Card className="flex flex-col mb-4">
                    <div className="flex gap-2">
                      <Typography className="font-bold">{`${t('title')} - `}</Typography>{' '}
                      <Typography>{reward.title}</Typography>
                    </div>
                    <div className="flex gap-2">
                      <Typography className="font-bold">{`${t('couponCode')} - `}</Typography>{' '}
                      <Typography>{reward.code}</Typography>
                    </div>
                  </Card>
                ))}
              </div>
            ) : (
              <Typography style={{ fontSize: '18px' }} className="font-bold">
                {t('sorryNoCouponFound')}
              </Typography>
            )}
          </Form>
        </Modal>
      </div>
    </PrimaryLayout>
  );
};

export default CustomerListingPage;

import { Button, Modal, Typography } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import BoldButtonLabel from '../BoldButtonLabel';

interface IWarningModal {
  modalOpen: boolean;
  title: string;
  closeWarningModal: () => void;
  width: number;
  handleOnConfirm: (key: string) => void;
  message: string;
}

const WarningModal: React.FunctionComponent<IWarningModal> = ({
  modalOpen,
  title,
  closeWarningModal,
  width,
  handleOnConfirm,
  message
}) => {
  return (
    <Modal
      centered
      open={modalOpen}
      onCancel={() => {
        // setEditRewardModal(false);
        closeWarningModal();
      }}
      width={width}
      footer={false}
      title={
        <div className="flex">
          <WarningOutlined style={{ color: '#ebeb00', fontSize: '30px' }} /> &nbsp;&nbsp;
          <Typography.Title level={4} className={'mt-3'}>
            {title}
          </Typography.Title>
        </div>
      }
    >
      <div className="flex flex-col gap-2">
        <Typography.Text>{message}</Typography.Text>
        <div className="flex justify-end">
          <Button onClick={() => handleOnConfirm('confirm')} type="primary">
            <BoldButtonLabel labelText="Confirm" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default WarningModal;

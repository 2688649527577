import { LOGIN_URI, INTROSPECT_URI } from '../config/envConfig';
import httpClient from '../http/http.client';
import { useAuth } from '../stores/use-auth';

interface IResetData {
  realm: string;
  password: string;
  confirm_password: string;
  temporary: boolean;
  username: string;
}

export const getLogin = async (bodyData: any, tenant_id: any, businessGroupId: string) => {
  const url = `${LOGIN_URI}/identity/v1/token`;
  const config = {
    headers: {
      'x-tenant-id': tenant_id,
      'x-client-id': 'webui',
      'x-trace-id': '123',
      'x-business-group-id': businessGroupId
    }
  };
  const requestObj = { ...bodyData };

  const { data, errors } = await httpClient.post({ url: url, body: requestObj, headers: { ...config.headers } });
  return { data: data, errors: errors };
};

export const handleLogOut = async (user: any) => {
  const url = `${LOGIN_URI}/identity/v1/logout/${user}`;

  const { data, errors } = await httpClient.delete({ url: url });
  return { data: data, errors: errors };
};

export const getintrospect = async ({ tenantSecret }: { tenantSecret: string }) => {
  const getToken = useAuth?.getState()?.accessTokenData;
  const url = `${INTROSPECT_URI}/identity/v1/token/introspect`;
  const headers = {
    tenant_secret: tenantSecret,
    Authorization: getToken
  };
  const { data, errors } = await httpClient.post({ url, headers });
  return { data: data, errors: errors };
};

export const setResetPassword = async (bodyData: IResetData) => {
  const url = `${LOGIN_URI}/identity/v1/user/password`;
  const headers = {
    'x-tenant-id': useAuth?.getState()?.tenentName,
    'x-business-group-id': useAuth?.getState()?.bussinessGroupName
  };

  const { data, errors } = await httpClient.put({ url, headers, body: bodyData });
  return { data: data, errors: errors };
};

export const getOtpByEmail = async (body: any, bussinessGrpId: any, tenentId: any) => {
  const url = `${LOGIN_URI}/identity/v1/reset-password/send-otp`;
  const headers = {
    'x-tenant-id': tenentId,
    'x-business-group-id': bussinessGrpId
  };
  const { data, errors } = await httpClient.post({ url, headers, body });
  return { data: data, errors: errors };
};

export const verifyOtp = async (body: any, bussinessGrpId: any, tenentId: any) => {
  const url = `${LOGIN_URI}/identity/v1/reset-password/verify-otp`;
  const headers = {
    'x-tenant-id': tenentId,
    'x-business-group-id': bussinessGrpId
  };
  const { data, errors } = await httpClient.post({ url, headers, body });
  return { data: data, errors: errors };
};

export const resetForgetPassword = async (body: any, bussinessGrpId: any, tenentId: any) => {
  const url = `${LOGIN_URI}/identity/v1/reset-password`;
  const headers = {
    'x-tenant-id': tenentId,
    'x-business-group-id': bussinessGrpId
  };
  const { data, errors } = await httpClient.patch({ url, headers, body });
  return { data: data, errors: errors };
};

export const loggingIn = {
  getLogin,
  getintrospect,
  setResetPassword,
  handleLogOut,
  getOtpByEmail,
  verifyOtp,
  resetForgetPassword
};

import { Button, Card, Col, DatePicker, Form, Input, Row, Table, TableColumnsType, Typography } from 'antd';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useTranslation } from 'react-i18next';
import TenantInfo from '../../../../components/TenantIdInfo';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { convertQueryStringToObj, objectHelpers } from '../../../../helpers';
import { useLoader } from '../../../../stores/use-loader';
import { loyaltyService } from '../../services/loyalty.service';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import _ from '../../../../helpers/lodash';

interface ICommercialReport {}

interface RewardDetails {
  customer_id: string;
  reward_name: string;
  opening_points: number;
  points_accrued: number;
  points_redeemed: number;
  expired: number;
  balance: number;
}

const CommercialReport: React.FunctionComponent<ICommercialReport> = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
  const [commercialReportResponse, setCommercialReportResponse] = React.useState({} as any);
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');
    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const columns: TableColumnsType<RewardDetails> = [
    {
      title: t('customerId'),
      render(value, record, index) {
        return <>{record?.customer_id}</>;
      },
      align: 'center'
    },
    {
      title: t('openingPoint'),
      render(value, record, index) {
        return <>{record?.opening_points?.toFixed(2)}</>;
      },
      align: 'center'
    },
    {
      title: t('pointsAccrued'),
      render(value, record, index) {
        return <>{record?.points_accrued?.toFixed(2)}</>;
      },
      align: 'center'
    },
    {
      title: t('pointsRedeem'),
      render(value, record, index) {
        let data = record?.points_redeemed?.toFixed(2);
        return <>{data || 0.0}</>;
      },
      align: 'center'
    },
    {
      title: t('expiredPoints'),
      render(value, record, index) {
        let data = record?.expired?.toFixed(2);
        return <>{data || '0.00'}</>;
      },
      align: 'center'
    },
    {
      title: t('closingBlance'),
      render(value, record, index) {
        return <>{record?.balance?.toFixed(2) || 0}</>;
      },
      align: 'center'
    }
  ];

  React.useEffect(() => {
    loadInitialData();
  }, []);

  React.useEffect(() => {
    getReportData();
  }, [pageControl, setSearchParams]);

  const loadInitialData = async () => {
    setSearchParams({
      offset: String(pageControl.currentPage - 1),
      limit: String(pageControl.pageSize)
    });
    const endDate = dayjs();
    const startDate = endDate.subtract(60, 'day');

    form.setFieldsValue({
      date_range: [startDate, endDate]
    });

    await getReportData();
  };

  const getReportData = async () => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    let params: Record<string, any> = { offset: pageControl.currentPage - 1, limit: pageControl?.pageSize };
    if (formValues.date_range) {
      let from_Date = formValues.date_range[0].format('YYYY-MM-DD');
      let to_date = formValues.date_range[1].format('YYYY-MM-DD');
      params = { ...params, start_date: from_Date, end_date: to_date };
    }
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);

    const { data, errors } = await loyaltyService.getCommercialReport(filteredParams, formValues.customer);
    if (_.isEmpty(errors)) {
      setCommercialReportResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('commercialReport')}
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <Form
            form={form}
            layout="vertical"
            onFinish={() => {
              getReportData();
            }}
          >
            <section className="mt-4">
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item name="date_range" label={t('datePeriod')}>
                    <DatePicker.RangePicker
                      size="large"
                      placeholder={[t('startDate'), t('endDate')]}
                      style={{ borderRadius: '0' }}
                      allowClear
                      disabledDate={current => current && current > dayjs().endOf('day')}
                      onChange={(dates, dateStrings) => {
                        if (!dates) {
                          getReportData();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name="customer" label={t('customerId')}>
                    <Input placeholder={t('customerId')} size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={3}>
                  <Button type="primary" block size="large" htmlType="submit">
                    <BoldButtonLabel labelText={t('search')} />
                  </Button>
                </Col>
                <Col xs={12} md={3}>
                  <Button
                    size="large"
                    onClick={() => {
                      form.resetFields();
                      //   handleSearch(0);
                    }}
                    block
                  >
                    <BoldButtonLabel labelText={t('clear')} />
                  </Button>
                </Col>
              </Row>
              <section className="mt-4">
                <Table
                  pagination={{
                    current: pageControl?.currentPage,
                    total: commercialReportResponse?.page_info?.total_pages * pageControl?.pageSize || 0,
                    pageSize: pageControl?.pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['1', '10', '20', '50', '100', '200'],
                    onChange: (currentPage, pageSize) => setPageControl({ currentPage, pageSize }),
                    locale: {
                      items_per_page: `${t('page')}`
                    }
                  }}
                  locale={{
                    emptyText: (
                      <div className="flex flex-col items-center gap-2">
                        <InboxOutlined style={{ fontSize: '50px' }} />
                        {t('noData')}
                      </div>
                    )
                  }}
                  bordered
                  dataSource={commercialReportResponse.data || []}
                  columns={columns}
                  scroll={{ x: 1000 }}
                ></Table>
              </section>
            </section>
          </Form>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CommercialReport;

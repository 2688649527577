import { LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Select, Spin, Typography } from 'antd';
import { t } from 'i18next';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import { ENV } from '../../config/envConfig';
import { localeEjjeList, localeList } from '../../data/intl-data';
import { urlHelpers } from '../../helpers';
import _ from '../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import i18n from '../../i18n';
import { loggingIn } from '../../services/login.services';
import { useClientInfo } from '../../stores/use-auth';
import { useLoader } from '../../stores/use-loader';
import { useLocale } from '../../stores/use-locale';

interface IVerificationCode {}

const TransparentSelect = styled(Select)`
  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    color: white;
  }

  .ant-select-arrow {
    color: white;
  }

  .ant-select-item-option-content {
    color: white;
  }

  .ant-select-item-option {
    background-color: transparent !important;
    color: white;
  }

  .ant-select-item-option-selected {
    color: white !important;
    background-color: rgba(255, 255, 255, 0.2) !important; 
`;

const VerificationCode: React.FunctionComponent<IVerificationCode> = () => {
  const navigate = useNavigate();
  const [verificationCodeForm] = Form.useForm();
  const [counter, setCounter] = React.useState(30);

  const { loading, setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  const { clientEmail, setResetToken } = useClientInfo(({ clientEmail, setResetToken }) => ({
    clientEmail,
    setResetToken
  }));
  const { locale, setUserLocale } = useLocale(({ locale, setUserLocale }) => ({ locale, setUserLocale }));

  const { businessGroupId: businessGrpIdFromUrl, tenantId: tenandIdFromUrl } =
    urlHelpers.getTenantIdAndBusinessGroupIdFromUrl();

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCounter(prevCounter => {
        if (prevCounter <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevCounter - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [counter]);

  const handleOnCodeSubmit = async () => {
    setLoading(true);
    const { otp } = verificationCodeForm.getFieldsValue();
    const bodyData = {
      otp: otp,
      email: clientEmail
    };
    const { data, errors } = await loggingIn.verifyOtp(bodyData, businessGrpIdFromUrl, tenandIdFromUrl);
    if (_.isEmpty(errors)) {
      setResetToken(data.reset_token);
      navigate('/login/forgot-password/verify-code/reset-password');
      displaySuccessNotification({ message: 'OTP Verified Success!' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const tryAgain = async () => {
    setLoading(true);
    setCounter(30);
    let body = {
      email: clientEmail
    };
    const { data, errors } = await loggingIn.getOtpByEmail(body, businessGrpIdFromUrl, tenandIdFromUrl);
    if (_.isEmpty(errors)) {
      // navigate('/login/forgot-password/verify-code');
      displaySuccessNotification(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const selectLocale = (
    <div className="absolute right-[40px] top-[40px] login-language-dropdown">
      <TransparentSelect
        value={ENV === 'DEVELOPMENT' ? locale : 'IN'}
        onChange={(value: any) => {
          i18n.changeLanguage(value);
          setUserLocale(value);
        }}
        options={ENV === 'DEVELOPMENT' ? localeList : localeEjjeList}
        style={{ width: 'fit-content' }}
        disabled={ENV !== 'DEVELOPMENT'}
        className="text-white"
        dropdownStyle={{
          backgroundColor: 'transparent'
        }}
        defaultValue={'IN'}
      />
    </div>
  );

  return (
    <>
      <div className="main">
        <div className="base_container">
          <div className="cartoon_frame">
            <div>{selectLocale}</div>
            <div className="h-[100%] flex flex-row justify-center items-center">
              <Form
                form={verificationCodeForm}
                layout="vertical"
                onFinish={() => {
                  handleOnCodeSubmit();
                }}
              >
                <Card className="xl:w-[450px] lg:w-[450px] md:w-[450px] sm:w-[33%]">
                  <div className="mt-1">
                    <span className="font-extrabold text-[18px] text-[#008080]" translate="no">
                      {t('enterVerificationCode')}
                    </span>
                  </div>
                  <Typography.Text className="text-[#5e5f66]">
                    {t('didNotReciveOtpYet')} {counter} {t('clickHereAfter')}
                  </Typography.Text>
                  <div className="mt-4">
                    <Form.Item
                      label={<span className="text-[#5A607F]">{t('enterVerificationCode')}</span>}
                      name={'otp'}
                      required
                      className="mb-2"
                      rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                    >
                      <Input placeholder={t('enterVerificationCode')} size="large" />
                    </Form.Item>
                  </div>
                  <Row gutter={12} justify={'space-between'} className="mt-4">
                    <Col xs={24} md={8} lg={8}>
                      <Button
                        size="large"
                        block
                        type="primary"
                        disabled={counter !== 0}
                        onClick={() => {
                          tryAgain();
                        }}
                        // className="flex gap-2"
                      >
                        <BoldButtonLabel labelText={t('tryAgain')} />
                      </Button>
                    </Col>
                    <Col xs={24} md={8} lg={8}>
                      <Button size="large" block type="primary" htmlType="submit">
                        <BoldButtonLabel labelText={t('validateOTP')} />
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationCode;

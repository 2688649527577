import * as React from 'react';
import PrimaryLayout from '../../../layouts/primary-layout';
import { Card, Col, Form, Row, Typography } from 'antd';
import TenantInfo from '../../../components/TenantIdInfo';
import RewardForm from '../components/RewardForm';
import { useNavigate, useParams } from 'react-router-dom';
import { loyaltyService } from '../services/loyalty.service';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { useTranslation } from 'react-i18next';
import '../../../i18n';

interface IViewRewardPageProps {}

const ViewRewardPage: React.FunctionComponent<IViewRewardPageProps> = props => {
  const [viewForm] = Form.useForm();
  const navigate = useNavigate();
  const {t} = useTranslation()

  const { id } = useParams();

  const [rewardDetail, setRewardDetail] = React.useState({} as any);

  React.useEffect(() => {
    loadRewardDetails();
  }, []);

  const loadRewardDetails = async () => {
    const { data, errors } = await loyaltyService.getRewardDetail(id as string);
  

    if (_.isEmpty(errors)) {
      setRewardDetail(data);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const onFinish = async () => {
    const { description } = viewForm.getFieldsValue();
    const { data, errors } = await loyaltyService.putReward(id as string, { description });
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('rewardUpdatedSuccessfully') });
      navigate(-1);
      await loadRewardDetails();
    } else {
      displayErrorNotifications(errors);
    }
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t("reward")} - {rewardDetail.name}
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <RewardForm formInstance={viewForm} onFinish={onFinish} initialValues={rewardDetail} />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default ViewRewardPage;

import { CUSTOMER_PRODUCT_URI, LOYALTY_URI, PROMOTION_REPORT_URI } from '../../../config/envConfig';
import httpClient from '../../../http/http.client';

export const getRfmData = (params: any) => {
  const url = `${PROMOTION_REPORT_URI}/orders/v2/rfm`;
  return httpClient.get({ url, params });
};

export const getCategoryData = () => {
  const url = `${PROMOTION_REPORT_URI}/orders/v1/rfm/categories/list`;
  return httpClient.get({ url });
};

export const getRFMReport = (params?: any) => {
  const url = `${PROMOTION_REPORT_URI}/orders/v1/rfm/download`;
  return httpClient.get({ url, responseType: 'arraybuffer', params });
};

export const getCategoryProductData = () => {
  const url = `${CUSTOMER_PRODUCT_URI}/categories/v1`;
  return httpClient.get({ url });
};

export const getRewardHistoryReport = (params?: any) => {
  const url = `${LOYALTY_URI}/loyalty/v1/rewards/metrics/report`;
  return httpClient.get({ url, responseType: 'arraybuffer', params });
};

export const AnalyticsService = {
  getRfmData,
  getCategoryData,
  getRFMReport,
  getCategoryProductData,
  getRewardHistoryReport
};

import i18next from '../../../i18n';
i18next.init();

export let createRuleTriggerTypeData: Record<string, any> = {
  EVENT: {
    label: i18next.t('event'),
    value: 'EVENT',
    applicabilityTypes: [
      { label: i18next.t('order'), value: 'Order' },
      // { label: i18next.t('referral'), value: 'Referral' },
      // { label: i18next.t('survey'), value: 'Survey' },
      // { label: i18next.t('review'), value: 'Review' },
      { label: i18next.t('signUp'), value: 'Sign up' }
    ]
  }
  // MANUAL: {
  //   label: i18next.t('manual'),
  //   value: 'MANUAL',
  //   applicabilityTypes: [
  //     { label: i18next.t('sale'), value: 'Sale' },
  //     { label: i18next.t('abandonCart'), value: 'Abandon Cart' },
  //     { label: i18next.t('inactiveBuyer'), value: 'Inactive Buyer' }
  //   ]
  // },
  // SCHEDULED: {
  //   label: i18next.t('scheduled'),
  //   value: 'SCHEDULED',
  //   applicabilityTypes: [{ label: i18next.t('scheduled'), value: 'Scheduled' }]
  // }
};

// Function to update the object based on language changes
const updateTranslations = () => {
  createRuleTriggerTypeData = {
    EVENT: {
      label: i18next.t('event'),
      value: 'EVENT',
      applicabilityTypes: [
        { label: i18next.t('order'), value: 'Order' },
        // { label: i18next.t('referral'), value: 'Referral' },
        // { label: i18next.t('survey'), value: 'Survey' },
        // { label: i18next.t('review'), value: 'Review' },
        { label: i18next.t('signUp'), value: 'Sign up' }
      ]
    }
    // MANUAL: {
    //   label: i18next.t('manual'),
    //   value: 'MANUAL',
    //   applicabilityTypes: [
    //     { label: i18next.t('sale'), value: 'Sale' },
    //     { label: i18next.t('abandonCart'), value: 'Abandon Cart' },
    //     { label: i18next.t('inactiveBuyer'), value: 'Inactive Buyer' }
    //   ]
    // },
    // SCHEDULED: {
    //   label: i18next.t('scheduled'),
    //   value: 'SCHEDULED',
    //   applicabilityTypes: [{ label: i18next.t('scheduled'), value: 'Scheduled' }]
    // }
  };
};
i18next.on('languageChanged', () => {
  updateTranslations();
});

export let triggerTypeSelectOptions: any = [];

const updateTriggerTypeSelectOptions = () => {
  triggerTypeSelectOptions = Object.keys(createRuleTriggerTypeData).map(key => {
    const { label, value } = createRuleTriggerTypeData[key];
    return { label: i18next.t(label), value };
  });
};

updateTriggerTypeSelectOptions();
i18next.on('languageChanged', () => {
  updateTriggerTypeSelectOptions();
});

export let viewRewardHistoryData: Record<string, any> = {
  'Earn & Burn': {
    label: i18next.t('earnAndBurn'),
    value: 'Earn & Burn',
    applicabilityTypes: [
      { label: i18next.t('order'), value: 'Order' },
      // { label: i18next.t('referral'), value: 'Referral' },
      // { label: i18next.t('survey'), value: 'Survey' },
      // { label: i18next.t('review'), value: 'Review' },
      { label: i18next.t('signUp'), value: 'Sign up' }
      // { label: i18next.t('promo'), value: 'Promo' }
    ]
  }
  // 'Action Oriented': {
  //   label: i18next.t('actionOriented'),
  //   value: 'Action Oriented',
  //   applicabilityTypes: [
  //     { label: i18next.t('sale'), value: 'Sale' },
  //     { label: i18next.t('abandonCart'), value: 'Abandon Cart' },
  //     { label: i18next.t('inactiveBuyer'), value: 'Inactive Buyer' },
  //     { label: i18next.t('scheduled'), value: 'Scheduled' }
  //   ]
  // }
};

const updateTrans = () => {
  viewRewardHistoryData = {
    'Earn & Burn': {
      label: i18next.t('earnAndBurn'),
      value: 'Earn & Burn',
      applicabilityTypes: [
        { label: i18next.t('order'), value: 'Order' },
        // { label: i18next.t('referral'), value: 'Referral' },
        // { label: i18next.t('survey'), value: 'Survey' },
        // { label: i18next.t('review'), value: 'Review' },
        { label: i18next.t('signUp'), value: 'Sign up' }
        // { label: i18next.t('promo'), value: 'Promo' }
      ]
    }
    // 'Action Oriented': {
    //   label: i18next.t('actionOriented'),
    //   value: 'Action Oriented',
    //   applicabilityTypes: [
    //     { label: i18next.t('sale'), value: 'Sale' },
    //     { label: i18next.t('abandonCart'), value: 'Abandon Cart' },
    //     { label: i18next.t('inactiveBuyer'), value: 'Inactive Buyer' },
    //     { label: i18next.t('scheduled'), value: 'Scheduled' }
    //   ]
    // }
  };
};

i18next.on('languageChanged', updateTrans);

export let triggerTypeSelectOptionForRewardHistory: any = [];

const updateRewardHistoryOptions = () => {
  triggerTypeSelectOptionForRewardHistory = Object.keys(viewRewardHistoryData).map(key => {
    const { label, value } = viewRewardHistoryData[key];

    return { label: i18next.t(label), value };
  });
};

updateRewardHistoryOptions();

i18next.on('languageChanged', () => {
  updateRewardHistoryOptions();
});

export const createBurnRulesInitialXml =
  '<?xml version="1.0" encoding="UTF-8"?>\r\n' +
  '<definitions xmlns="https://www.omg.org/spec/DMN/20191111/MODEL/" id="definitions_1s6twx8" name="definitions" namespace="http://camunda.org/schema/1.0/dmn" exporter="Camunda Modeler" exporterVersion="5.27.0">\r\n' +
  '  <decision id="Decision_16jrlcr" name="Decision 1">\r\n' +
  '    <decisionTable id="DecisionTable_1dzc28c">\r\n' +
  '      <input id="Input_1" label="Customer Tier">\r\n' +
  '        <inputExpression id="InputExpression_1" typeRef="string">\r\n' +
  '          <text>customer.tier_type</text>\r\n' +
  '        </inputExpression>\r\n' +
  '      </input>\r\n' +
  '      <input id="InputClause_0mbogy3" label="Total Available Rewards">\r\n' +
  '        <inputExpression id="LiteralExpression_1yoj3xu" typeRef="number">\r\n' +
  '          <text>active_reward_value</text>\r\n' +
  '        </inputExpression>\r\n' +
  '      </input>\r\n' +
  '      <input id="InputClause_1lixl8i" label="Item Burn Point Available">\r\n' +
  '        <inputExpression id="LiteralExpression_0bx4679" typeRef="string">\r\n' +
  '          <text>some item in burn_payload.line_items satisfies item.lty_burn&gt;0</text>\r\n' +
  '        </inputExpression>\r\n' +
  '      </input>\r\n' +
  '      <output id="Output_1" label="Max Redemption" name="max_redemption" typeRef="number" />\r\n' +
  '      <output id="Output_2" label="Increments" name="increments" typeRef="number" />\r\n' +
  '      <output id="Output_3" label="Minimum Redeemable Value" name="min_redeemable_value" typeRef="number" />\r\n' +
  '    </decisionTable>\r\n' +
  '  </decision>\r\n' +
  '</definitions>';

export const createEarnRulesInitialXml =
  '<?xml version="1.0" encoding="UTF-8"?>\r\n<definitions xmlns="https://www.omg.org/spec/DMN/20191111/MODEL/" id="definitions_1s6twx8" name="definitions" namespace="http://camunda.org/schema/1.0/dmn" exporter="dmn-js (https://demo.bpmn.io/dmn)" exporterVersion="14.1.1">\r\n  <decision id="decision_11g4w17" name="Earn Rules">\r\n    <decisionTable id="decisionTable_0uc9rao">\r\n      <input id="input1" label="Customer Type">\r\n        <inputExpression id="inputExpression1" typeRef="string">\r\n          <text>customer.customer_type</text>\r\n        </inputExpression>\r\n      </input>\r\n    <output id="OutputClause_17wjx0u" label="Reward Name" name="reward_name" typeRef="string" />\r\n      <output id="OutputClause_1wdb6iy" label="Reward Value" name="value" typeRef="number" />\r\n      <output id="OutputClause_1ei9xkf" label="Expiry in days" name="expiry_in_days" typeRef="number" />\r\n    </decisionTable>\r\n  </decision>\r\n</definitions>';

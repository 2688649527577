import { Card, Col, Form, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { convertQueryStringToObj } from '../../../helpers';
import { getDayJsObjectWithFormattedString } from '../../../helpers/date.helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { priceListService } from '../services/pricelist.service';
import PriceEnteriesProductForm from './price-enteries-product-form';

interface IPriceEnteriesProductEditForm {}

const PriceEnteriesProductEditForm: React.FunctionComponent<IPriceEnteriesProductEditForm> = () => {
  //new declare
  const { parentId, itemId } = useParams();
  const navigate = useNavigate();
  const [priceEnteriesProductForm] = Form.useForm();
  //   const [suggestedLineForm] = Form.useForm();

  //new State
  const [searchParams, setSearchParams] = useSearchParams();
  const [productEnteriesResp, setProductEnteriesResp] = React.useState({} as any);

  //other declerations
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const { offset, backTabTo } = queryStringObj;

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  React.useEffect(() => {
    loadproductData();
  }, []);

  const loadproductData = async () => {
    setLoading(true);
    const { data, errors } = await priceListService.getProductItemDetails(parentId, itemId);
    if (_.isEmpty(errors)) {
      setProductEnteriesResp(data);
      loadPriceEnteries(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const loadPriceEnteries = (productItem: any) => {
    const {
      name,
      item_id,
      article_id,
      currency_code,
      is_product_variant,
      price_entries,
      schedule_info,
      status,
      addnl_promotion_info,
      id,
      price_list_id,
      timezone
    } = productItem;
    let getStartDate = schedule_info.schedule_start_date.split('T')[0];
    const sd = getDayJsObjectWithFormattedString(getStartDate, 'YYYY-MM-DD');
    let getEndDate = schedule_info.schedule_end_date.split('T')[0];
    const ed = getDayJsObjectWithFormattedString(getEndDate, 'YYYY-MM-DD');
    const st = getDayJsObjectWithFormattedString(schedule_info.schedule_start_time, 'HH:mm:ss');
    const et = getDayJsObjectWithFormattedString(schedule_info.schedule_end_time, 'HH:mm:ss');

    //useless BACKEND is not able to send Proper Data so modifing it
    let new_special_price_info = price_entries?.sale_price_info?.map((cv: any) => {
      let obj = {};
      if (cv?.discount_type === 'SPECIAL-PRICE') {
        obj = {
          ...cv,
          discount_value: cv?.list_price
        };
        return obj;
      }
      obj = {
        ...cv
      };
      return obj;
    });
    priceEnteriesProductForm.setFieldsValue({
      name: name,
      item_id: item_id,
      artical_Id: article_id,
      currency_code: currency_code,
      is_product_variant: String(is_product_variant),
      costPrice: price_entries?.cost_price,
      is_threshold_based: String(price_entries?.is_threshold_based),
      start_date: sd,
      end_date: ed,
      status: status,
      startTime: st,
      endTime: et,
      original_list_price: price_entries?.original_list_price || null,
      salesPriceInfo_Data: new_special_price_info || null,
      minimum_markup_percent: price_entries?.minimum_markup_percent || null,
      maximum_markup_percent: price_entries?.maximum_markup_percent || null,
      sales_lock_Price: String(price_entries?.lock_sale_price) || String(true),
      override_master_lock_price: String(price_entries?.override_master_lock_price) || String(true),
      time_Zone: timezone
    });
  };

  const handleGoBack = () => {
    const params = {
      offset: '0',
      backTabTo: 'PRICE-ENTERIES'
    };
    const paramString = new URLSearchParams(params).toString();
    navigate(`/promotion-engine/promotions/price-list/${parentId}/price-list-edit-form?${paramString}`);
  };

  const getDateString = (date: any, formatter: string) => {
    let reqDate = dayjs(date).format(formatter);
    return reqDate;
  };

  const getTimeString = (time: any, format: string) => {
    let reqTime = dayjs(time).format(format);
    return reqTime;
  };

  const loadEditedBodyData = () => {
    const {
      item_id,
      is_product_variant,
      currency_code,
      start_date,
      end_date,
      startTime,
      endTime,
      costPrice,
      salesPriceInfo_Data,
      status,
      is_threshold_based,
      minimum_markup_percent,
      maximum_markup_percent,
      sales_lock_Price,
      name,
      artical_Id,
      override_master_lock_price
    } = priceEnteriesProductForm.getFieldsValue();
    const sd = getDateString(start_date, 'YYYY-MM-DD');
    const ed = getDateString(end_date, 'YYYY-MM-DD');
    const st = getTimeString(startTime, 'HH:mm:ss');
    const et = getTimeString(endTime, 'HH:mm:ss');
    const bdData = {
      name: name,
      item_id: item_id,
      is_product_variant: is_product_variant,
      article_id: artical_Id,
      currency_code: currency_code,
      status: status,
      schedule_info: {
        schedule_start_date: sd,
        schedule_end_date: ed,
        schedule_start_time: st,
        schedule_end_time: et
      },
      price_entries: {
        cost_price: String(costPrice),
        is_threshold_based: is_threshold_based,
        lock_sale_price: sales_lock_Price,
        override_master_lock_price: override_master_lock_price,
        maximum_markup_percent: maximum_markup_percent,
        minimum_markup_percent: minimum_markup_percent,
        sale_price_info: salesPriceInfo_Data
      },
      addnl_promotion_info: productEnteriesResp.addnl_promotion_info
    };
    return bdData;
  };

  const handleOnSave = async () => {
    setLoading(true);
    const bodyData = loadEditedBodyData();
    const { errors } = await priceListService.updateItemOnPriceList(parentId as any, itemId, bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Data updated Sucessfully!' });
      handleGoBack();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Edit Price Entries
              </Typography.Title>
            </Col>
          </Row>
          <PriceEnteriesProductForm
            handleGoBack={handleGoBack}
            formInstance={priceEnteriesProductForm}
            handleOnSave={handleOnSave}
            isformVisible={false}
          />
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default PriceEnteriesProductEditForm;

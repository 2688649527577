import { Rule, RuleObject } from 'antd/es/form';
import dayjs from 'dayjs';

export const getRequiredValidator = ({ message }: { message: string }): Rule => {
  return {
    required: true,
    message
  };
};

export const getTitleValidation = ({
  maxLength,
  maxLengthErrorMessage,
  specialCharacterErrorMessage
}: {
  maxLength: number;
  maxLengthErrorMessage: string;
  specialCharacterErrorMessage: string;
}): Rule => {
  return {
    validator: (_, value) => {
      if (value && value.length > maxLength) {
        return Promise.reject(new Error(maxLengthErrorMessage));
      }
      if (value && /[^a-zA-Z0-9\s@]/.test(value)) {
        return Promise.reject(new Error(specialCharacterErrorMessage));
      }
      return Promise.resolve();
    }
  };
};

export const twoDecimalValidator = ({ message }: { message?: string }): Rule => {
  return {
    validator: (_, value) => {
      // If value is empty or matches the regex for up to 2 decimal places
      if (!value || /^\d+(\.\d{0,2})?$/.test(value)) {
        return Promise.resolve();
      }
      // Return error if the validation fails
      return Promise.reject(
        new Error(message || 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)')
      );
    }
  };
};

export const validateAvailabilityOnline = (getFieldValue: (name: string) => any): RuleObject => ({
  validator(_, value) {
    const isAvailableOnline = getFieldValue('isAvailableOnline');
    if (isAvailableOnline || value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Select at least one option (E Com or STORE).'));
  }
});

export const validateAvailabilityPos = (getFieldValue: (name: string) => any): RuleObject => ({
  validator(_, value) {
    const isAvailablePos = getFieldValue('isAvailablePos');
    if (isAvailablePos || value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Select at least one option (E Com or STORE).'));
  }
});

export const validateLimitPerUser = (getFieldValue: Function): Rule => ({
  validator: async (_, value) => {
    const maxUsage = Number(getFieldValue('maxUsage')); // Retrieve maxUsage value from the form
    const currentValue = Number(value); // Convert limitPerUser to number

    // Validate if limitPerUser exceeds maxUsage
    if (currentValue > maxUsage) {
      return Promise.reject(new Error('Limit per user cannot exceed Max Usage'));
    }

    // If the value is valid, resolve the promise
    return Promise.resolve();
  }
});

export const validateMaxUsage = (getFieldValue: Function): Rule => ({
  validator: async (_, value) => {
    const limitPerUser = Number(getFieldValue('limit_per_user')); // Get limitPerUser from the form
    const maxUsage = Number(value); // Convert maxUsage to a number

    // Validate if maxUsage is less than limitPerUser
    if (maxUsage < limitPerUser) {
      return Promise.reject(new Error('Max Usage should be greater than or equal to Limit per user'));
    }

    // If the value is valid, resolve the promise
    return Promise.resolve();
  }
});

export const validateTimeRange = (getFieldValue: Function) => ({
  validator(_: any, value: any) {
    const startTime = getFieldValue('timeRangeInSchedule_startTime');
    const dateRange = getFieldValue('dateRangeInSchedule');

    if (dateRange && startTime && value) {
      const startDate = dayjs(dateRange[0]).format('YYYY-MM-DD');
      const endDate = dayjs(dateRange[1]).format('YYYY-MM-DD');
      const isSameDay = startDate === endDate;

      if (isSameDay && dayjs(value).isBefore(dayjs(startTime))) {
        return Promise.reject(new Error('End Time must be greater than Start Time on the same day'));
      }
    }

    return Promise.resolve();
  }
});

export const validateScheduleTimeRange = (getFieldValue: Function) => ({
  validator(_: any, value: any) {
    const startTime = getFieldValue('timeRangeInSchedule_startTime');
    const endTime = getFieldValue('timeRangeInSchedule_endTime');

    if (!value || !startTime || !endTime) {
      return Promise.resolve(); // Skip validation if no values are present
    }

    const [rangeStart, rangeEnd] = value;
    if (dayjs(rangeStart).isBefore(dayjs(startTime)) || dayjs(rangeEnd).isAfter(dayjs(endTime))) {
      return Promise.reject(new Error('Time range must be within the start and end times of Scheduled'));
    }

    return Promise.resolve();
  }
});

export const getNumericValidatorForInput = ({ message }: { message?: string }): Rule => {
  return {
    validator: (_, value) => {
      if (isNumeric(value) || !value) {
        return Promise.resolve();
      }
      return Promise.reject(message || 'Please enter a valid numeric value');
    }
  };
};

export const getNumeric2DecimalPointValidatorForInput = ({ message }: { message?: string }): Rule => {
  return {
    validator: (_, value) => {
      if (isNumericWithTwoDecimalPlaces(value) || !value) {
        return Promise.resolve();
      }
      return Promise.reject(message || 'Please enter a valid numeric value with only 2 decimal places. Eg: (1.23) ');
    }
  };
};

export const isNumeric = (value: string) => {
  // Use a regular expression to check if the value is numeric
  return /^\d+$/.test(value);
};

const isNumericWithTwoDecimalPlaces = (value: string) => {
  // Use a regular expression to check if the value is numeric with two decimal places
  return /^\d+(\.\d{2})?$/.test(value);
};

export const getTitleValidator = ({
  requiredMessage,
  maxLength,
  maxLengthMessage
}: // allowedSymbolsMessage
{
  requiredMessage: string;
  maxLength: number;
  maxLengthMessage: string;
  // allowedSymbolsMessage: string;
}): Rule[] => {
  return [
    {
      required: true,
      message: requiredMessage
    },
    {
      validator: (_, value) => {
        if (value && value.length > maxLength) {
          return Promise.reject(new Error(maxLengthMessage));
        }
        // if (value && /[^a-zA-Z0-9\s@]/.test(value)) {
        //   return Promise.reject(new Error(allowedSymbolsMessage));
        // }
        return Promise.resolve();
      }
    }
  ];
};

export const getFormattedNumeric2DecimalPointValidatorForInput = ({ message }: { message?: string }): Rule => {
  return {
    validator: (_, value) => {
      if (!value || /^\d+(\.\d{0,2})?$/.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(message || 'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)');
    }
  };
};

export const formatToTwoDecimalPlaces = (event: React.FocusEvent<HTMLInputElement>) => {
  const { value } = event.target;

  if (value && /^\d+$/.test(value)) {
    event.target.value = parseFloat(value).toFixed(2); // Format to 2 decimal places
  } else if (value && /^\d+\.\d{1}$/.test(value)) {
    event.target.value = parseFloat(value).toFixed(2); // Format to 2 decimal places if only 1 decimal digit is provided
  }
};

export const getMaxTenDigitTwoDecimalPointValidatorForInput = ({ message }: { message?: string }): Rule => {
  return {
    validator: (_, value) => {
      if (!value) {
        return Promise.resolve(); // Allow empty values if it's not required
      }

      const valueStr = value.toString();
      const [integerPart, decimalPart] = valueStr.split('.');

      // Check if integer part exceeds 10 digits
      if (integerPart.length > 10) {
        return Promise.reject(new Error(message || 'Maximum 10 digits allowed before the decimal point.'));
      }

      // Check if decimal part exceeds 2 digits (if it exists)
      if (decimalPart && decimalPart.length > 2) {
        return Promise.reject(new Error(message || 'Maximum 2 decimal places allowed.'));
      }

      return Promise.resolve();
    }
  };
};

export const getSpecialCharValidator = ({ message }: { message: string }): Rule => {
  return {
    validator: (_: RuleObject, value: string) => {
      const alphanumericPattern = /[A-Za-z0-9]/; // checks for at least one alphanumeric character
      if (!alphanumericPattern.test(value)) {
        return Promise.reject(new Error(message));
      }
      return Promise.resolve();
    }
  };
};

export const validateNoSpecialCharacter = ({ message }: { message: string }): Rule => {
  return {
    validator: (_: RuleObject, value: string) => {
      const specialCharPattern = /^[A-Za-z0-9 ]*$/; // allows alphanumeric characters and spaces
      if (!specialCharPattern.test(value)) {
        return Promise.reject(new Error(message));
      }
      return Promise.resolve();
    }
  };
};

export const getNumberValidator = ({ message }: { message: string }): Rule => {
  return {
    validator: (_: RuleObject, value: string) => {
      const numberPattern = /^[0-9]+$/; // allows only numbers
      if (!numberPattern.test(value)) {
        return Promise.reject(new Error(message));
      }
      return Promise.resolve();
    }
  };
};

export const getEmptySpaceValidator = ({ message }: { message: string }): Rule => {
  return {
    validator: (_: RuleObject, value: string) => {
      if (!value || value.trim() === '') {
        return Promise.reject(new Error(message)); // Message for empty or space-only input
      }
      return Promise.resolve();
    }
  };
};

export const trimFields = (data: any, fieldsToTrim: string[]) => {
  const trimmedData = { ...data }; // Copy the original data just to Debug

  fieldsToTrim?.forEach(field => {
    if (trimmedData[field]) {
      trimmedData[field] = trimmedData[field]?.trim();
    }
  });

  return trimmedData;
};

import { createBrowserRouter } from 'react-router-dom';
import GenericErrorComponent from '../components/error-component';
import Error404Page from '../pages/404';
import InventoryPage from '../pages/inventory/inventory';
import InventoryDetailsPage from '../pages/inventory/inventory-details';
import InventoryHistoryPage from '../pages/inventory/inventory-history';
import OrderListingPage from '../pages/orders';
import OrderCreation from '../pages/order-creation';
import OrderDashboard from '../pages/order-dashboard';
import OrderDetails from '../pages/order-details';
import Login from '../pages/login';
import NodeList from '../pages/nodes-list';
import OperatorList from '../pages/operator-list';
import NodeDetails from '../pages/node-detail';
import OperatorDetails from '../pages/operator-detail';
import CreateNode from '../pages/create-node';
import OperatorCreationPage from '../pages/create-operator';
import ProductsPage from '../pages/admin/products';
import SubscriptionsPage from '../pages/admin/subscriptions';
import AddSubscriptionPage from '../pages/admin/subscriptions/add-subscription';
import PromotionListingPage from '../modules/promotion-engine/pages/promotion-list';
import CreatePromotionPage from '../modules/promotion-engine/pages/create-promotion';
import RewardListPage from '../modules/loyalty-engine/pages/rewards-list';
import CreateRewardPage from '../modules/loyalty-engine/pages/create-reward';
import ViewRewardPage from '../modules/loyalty-engine/pages/view-reward';
import PointConversionRulesListing from '../modules/loyalty-engine/pages/point-conversions';
import ViewPointConversionRule from '../modules/loyalty-engine/pages/view-point-conversion';
import CreatePointConversionRule from '../modules/loyalty-engine/pages/create-point-conversion';
import CreatePointConversionNewVersion from '../modules/loyalty-engine/pages/create-point-conversion-new-version';
import EarnRulesListing from '../modules/loyalty-engine/pages/earn-rules/earn-rules-listing';
import EarnRulesView from '../modules/loyalty-engine/pages/earn-rules/earn-rules-view';
import EarnRulesTest from '../modules/loyalty-engine/pages/earn-rules/earn-rules-test';
import EarnRulesCreateNewVersion from '../modules/loyalty-engine/pages/earn-rules/earn-rules-new-version';
import CreateEarnRules from '../modules/loyalty-engine/pages/earn-rules/earn-rules-create';
import CustomerTier from '../modules/loyalty-engine/pages/customer-tier';
import RewardHistory from '../modules/loyalty-engine/pages/reports/reward-history';
import Dashboard from '../modules/loyalty-engine/pages/dashboard';
import RewardHistoryDetail from '../modules/loyalty-engine/pages/reports/reward-history-detail';
import SetPriority from '../modules/promotion-engine/pages/set-priority';
import CouponListing from '../modules/promotion-engine/pages/coupons-listing';
import GenerateCoupon from '../modules/promotion-engine/pages/generate-coupon';
import UnderConstructionPage from '../pages/under-construction';
import ViewPromotion from '../modules/promotion-engine/pages/view-promotion';
import CustomerListingPage from '../modules/loyalty-engine/pages/customers/customer-listing';
import CreateCustomer from '../modules/loyalty-engine/pages/customers/create-customer';
import EditCustomer from '../modules/loyalty-engine/pages/customers/edit-customer';
import ProductListing from '../modules/loyalty-engine/pages/products/ProductListing';
import CreateProduct from '../modules/loyalty-engine/pages/products/CreateProduct';
import EditProduct from '../modules/loyalty-engine/pages/products/EditProduct';
import UserProfile from '../pages/user-profile';
import EditPromotionPage from '../modules/promotion-engine/pages/edit-promotion';
import LoyalityDashboard from '../modules/loyalty-engine/pages/dashboard/LoyalityDashboard';
import TenentProfile from '../pages/admin/tenent-profile';
import ManageUser from '../pages/admin/manage-user';
import DNNListing from '../modules/nebula/pages/logistics/dnn/dnn-listing';
import DNNView from '../modules/nebula/pages/logistics/dnn/dnn-view';
import DnnCreate from '../modules/nebula/pages/logistics/dnn/dnn-create';
import SnnListing from '../modules/nebula/pages/snn/snn-listing';
import SnnView from '../modules/nebula/pages/snn/snn-view';
import SnnCreate from '../modules/nebula/pages/snn/snn-create';
import DNNEdit from '../modules/nebula/pages/logistics/dnn/dnn-edit';
import SnnEdit from '../modules/nebula/pages/snn/snn-edit';
import ShippingProfileListing from '../modules/nebula/pages/logistics/shipping-profile/listing';
import ShippingProfileView from '../modules/nebula/pages/logistics/shipping-profile/view';
import ShippingProfileCreate from '../modules/nebula/pages/logistics/shipping-profile/shipping-profile-create';
import ShippingProfileEdit from '../modules/nebula/pages/logistics/shipping-profile/edit';
import Catalog from '../modules/promotion-engine/pages/catalog';
import RedemptionListing from '../modules/promotion-engine/pages/redemption-listing';
import AuditListing from '../modules/promotion-engine/pages/audit-listing';
import ReportListing from '../modules/promotion-engine/pages/report-listing';
import CatalogEdit from '../modules/promotion-engine/pages/catalog-edit';
import CatalogView from '../modules/promotion-engine/pages/catalog-view';
import RFM from '../modules/loyalty-engine/pages/analytics/rfm';
import PromotionReport from '../modules/promotion-engine/pages/promotion-report';
import PromotionReportDetails from '../modules/promotion-engine/pages/promotion-report-details';
import PriceListDiscountListing from '../modules/promotion-engine/pages/price-list-discount-listing';
import PriceListCreate from '../modules/promotion-engine/pages/price-list-create';
import PriceListEdit from '../modules/promotion-engine/pages/price-list-edit';
import PriceListView from '../modules/promotion-engine/pages/price-list-view';
import PriceEnteriesSuggestedLines from '../modules/promotion-engine/pages/price-enteries-suggested-line';
import PriceEnteriesProductEditForm from '../modules/promotion-engine/pages/price-enteries-product-edit-form';
import PriceEnteriesProductViewForm from '../modules/promotion-engine/pages/price-enteries-product-view-form';
import PriceEnteriesCopyLines from '../modules/promotion-engine/pages/price-enteries-copy-lines';
import NebulaOmsDashboard from '../modules/nebula/pages/dashboard';
import RoDetails from '../modules/nebula/pages/release-order/ro-details';
import ReleaseOrderList from '../modules/nebula/pages/release-order-list';
import BundleDiscountPromo from '../modules/promotion-engine/pages/bundle-discount-promo';
import BXGYPromo from '../modules/promotion-engine/pages/bxgy-promo';
import SubTotalDiscountPromo from '../modules/promotion-engine/pages/sub-total-discount-promo';
import VolumeDiscountPromo from '../modules/promotion-engine/pages/volume-discount-promo';
import ShippingDiscountPromo from '../modules/promotion-engine/pages/shipping-discount-promo';
import ServiceNetworkEdit from '../modules/nebula/pages/logistics/service-network/edit';
import ServiceNetworkCreate from '../modules/nebula/pages/logistics/service-network/create';
import ServiceNetworkView from '../modules/nebula/pages/logistics/service-network/view';
import ServiceNetworkListing from '../modules/nebula/pages/logistics/service-network/listing';
import DnnManagePostCode from '../modules/nebula/pages/logistics/dnn/manage-postcodes';
import DnnManageNodes from '../modules/nebula/pages/logistics/dnn/manage-nodes';
import PromotionDashboard from '../modules/promotion-engine/pages/promotion-dashboard';
import PromiseDashboard from '../modules/nebula/pages/promise-dashboard';
import OMSPromise from '../modules/nebula/pages/OMSPromise';
import LoyaltyReward from '../modules/promotion-engine/pages/loyalty-reward-promo';
import BurnRuleListing from '../modules/loyalty-engine/pages/burn-rules/burn-rule-listing';
import CreateBurnRules from '../modules/loyalty-engine/pages/burn-rules/burn-rules-create';
import BurnRuleView from '../modules/loyalty-engine/pages/burn-rules/burn-rule-view';
import BurnRuleTest from '../modules/loyalty-engine/pages/burn-rules/burn-rule-test';
import BurnRuleNewVersion from '../modules/loyalty-engine/pages/burn-rules/burn-rule-new-version';
import OrderProducts from '../pages/order-products';
import ViewOrderProduct from '../pages/order-products/view-order-product';
import BillingPayments from '../pages/admin/billing-payments';
import ChannelListing from '../modules/loyalty-engine/pages/channel-management/channel-listing';
import AddUser from '../pages/admin/manage-user/add-user';
import EditUser from '../pages/admin/manage-user/edit-user';
import TransferOrder from '../modules/nebula/pages/transfer-order';
import TestEncryption from '../modules/promotion-engine/pages/test-encryption';
import SubTotalPromo from '../modules/promotion-engine/pages/Promotion-pages/sub-total-promo';
import EditPromo from '../modules/promotion-engine/pages/Promotion-pages/edit-promo';
import PromotionCreate from '../modules/promotion-engine/pages/Promotion-pages/promotion-create';
import CommercialReport from '../modules/loyalty-engine/pages/commercial-report/commercial-report';
import ForgotPassword from '../pages/update-password/forgot-password';
import VerificationCode from '../pages/update-password/verifacation-code';
import UpdatePwd from '../pages/update-password/update-pwd';
import ViewPromo from '../modules/promotion-engine/pages/Promotion-pages/view-promo';
import BundlePromo from '../modules/promotion-engine/pages/Promotion-pages/bundle-promo';

const ADMIN_ROUTES = [
  {
    path: '/admin/account-management',
    element: <ProductsPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/admin/subscription-management',
    element: <SubscriptionsPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/admin/billing-payments',
    element: <BillingPayments />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/admin/usage-metering',
    element: <UnderConstructionPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/admin/subscriptions',
    element: <SubscriptionsPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/admin/subscriptions/new',
    element: <AddSubscriptionPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/admin/tenant_Profile',
    element: <TenentProfile />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/admin/manage_User',
    element: <ManageUser />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/admin/manage_User/add-user',
    element: <AddUser />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/admin/manage_User/edit-user',
    element: <EditUser />,
    errorElement: <GenericErrorComponent />
  }
];

const NEBULA_ROUTES = [
  {
    path: '/',
    element: <OrderDashboard />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/nebula/order/dashboard',
    element: <NebulaOmsDashboard />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/dashboard',
    element: <OrderDashboard />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '*',
    element: <Error404Page />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/inventory',
    element: <InventoryPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/inventory/history',
    element: <InventoryHistoryPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/inventory/details/:productId',
    element: <InventoryDetailsPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/orders/release-order/:releaseOrderNumber',
    element: <RoDetails />,
    errorElement: <GenericErrorComponent />
  },
  { path: '/order', element: <OrderListingPage />, errorElement: <GenericErrorComponent /> },
  { path: '/transfer-order', element: <TransferOrder />, errorElement: <GenericErrorComponent /> },
  { path: '/order/:orderNumber', element: <OrderDetails />, errorElement: <GenericErrorComponent /> },
  { path: '/order-create', element: <OrderCreation />, errorElement: <GenericErrorComponent /> },
  { path: '/order-products', element: <OrderProducts />, errorElement: <GenericErrorComponent /> },
  { path: '/order-products/view/:id', element: <ViewOrderProduct />, errorElement: <GenericErrorComponent /> },
  { path: '/release-order', element: <ReleaseOrderList />, errorElement: <GenericErrorComponent /> },
  { path: '/dashboard', element: <OrderDashboard />, errorElement: <GenericErrorComponent /> },
  { path: '/nodes', element: <NodeList />, errorElement: <GenericErrorComponent /> },
  { path: '/operators', element: <OperatorList />, errorElement: <GenericErrorComponent /> },
  { path: '/promise', element: <OMSPromise />, errorElement: <GenericErrorComponent /> },
  // { path: '/report', element: <OMSReport />, errorElement: <GenericErrorComponent /> },
  { path: '/nodes/:nodeId', element: <NodeDetails />, errorElement: <GenericErrorComponent /> },
  {
    path: '/operators/:operatorId',
    element: <OperatorDetails />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/operators/create',
    element: <OperatorCreationPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/promise/dashboard',
    element: <PromiseDashboard />,
    errorElement: <GenericErrorComponent />
  },
  { path: '/nodes/create', element: <CreateNode /> },
  { path: '/login', element: <Login />, errorElement: <GenericErrorComponent /> },
  { path: '/login/forgot-password', element: <ForgotPassword /> },
  { path: '/login/forgot-password/verify-code', element: <VerificationCode /> },
  { path: '/login/forgot-password/verify-code/reset-password', element: <UpdatePwd /> },
  {
    path: '/My-Account',
    element: <UserProfile />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/dnn',
    element: <DNNListing />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/dnn/:id',
    element: <DNNView />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/dnn/:id/edit',
    element: <DNNEdit />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/dnn/:id/postcodes',
    element: <DnnManagePostCode />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/dnn/:id/nodes',
    element: <DnnManageNodes />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/dnn/create',
    element: <DnnCreate />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/snn',
    element: <SnnListing />,
    errorElement: <GenericErrorComponent />
  },

  {
    path: '/logistics/snn/:id',
    element: <SnnView />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/snn/create',
    element: <SnnCreate />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/snn/:id/edit',
    element: <SnnEdit />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/shipping-profile/:name',
    element: <ShippingProfileListing />,
    errorElement: <GenericErrorComponent />
  },

  {
    path: '/logistics/shipping-profile/:id',
    element: <ShippingProfileView />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/shipping-profile/create/:id',
    element: <ShippingProfileCreate />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/shipping-profile/:id/edit',
    element: <ShippingProfileEdit />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/service-network/:ssnName/:ssnId/:shippingName/:shippingId',
    element: <ServiceNetworkListing />,
    errorElement: <GenericErrorComponent />
  },

  {
    path: '/logistics/service-network/:id',
    element: <ServiceNetworkView />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/service-network/create/:shippingId',
    element: <ServiceNetworkCreate />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/logistics/service-network/:id/edit',
    element: <ServiceNetworkEdit />,
    errorElement: <GenericErrorComponent />
  }
];

const PROMOTION_ENGINE_ROUTES = [
  {
    path: '/promotion-engine/promotions/dashboard',
    element: <PromotionDashboard />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions',
    element: <PromotionListingPage promo={'BUNDLE_DISC'} promoName="Bundle Discount" />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/sub_total_discount',
    element: <SubTotalDiscountPromo />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotions/new-sub-total-discount',
    element: <SubTotalPromo />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/volume_discount',
    element: <VolumeDiscountPromo />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/shipping_discount',
    element: <ShippingDiscountPromo />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/bundle_discount',
    element: <BundleDiscountPromo />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotions/new-bundle-discount',
    element: <BundlePromo />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/bxgy',
    element: <BXGYPromo />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/loyalty_reward',
    element: <LoyaltyReward />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/:promotionName/create',
    element: <CreatePromotionPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/promotions/create/:promotionName',
    element: <PromotionCreate />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/promotion-engine/promotions/:promotionName/:id/view',
    element: <ViewPromotion />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/promotion-engine/promotions/:promotionName/:id/edit',
    element: <EditPromotionPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'promotions/:promotionName/:id/edit',
    element: <EditPromo />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'promotions/:promotionName/:id/view',
    element: <ViewPromo />,
    errorElement: <GenericErrorComponent />
  },

  {
    path: '/promotion-engine/promotions/priority',
    element: <SetPriority />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/promotion-engine/coupons',
    element: <CouponListing />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/promotion-engine/coupons/report',
    element: <ReportListing />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/coupons/redemption',
    element: <RedemptionListing />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/coupons/audit',
    element: <AuditListing />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/:id/generate-coupons',
    element: <GenerateCoupon />,
    errorElement: <GenericErrorComponent />
  },
  // {
  //   path: '/promotion-engine/promotions/price-list-discount',
  //   element: <PriceListDiscount />,
  //   errorElement: <GenericErrorComponent />
  // },
  {
    path: '/promotion-engine/promotions/price-list',
    element: <PriceListDiscountListing />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/price-list/price-list-create-form',
    element: <PriceListCreate />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/price-list/:parentId/price-list-edit-form',
    element: <PriceListEdit />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/price-list/:parentId/price-list-view-form',
    element: <PriceListView />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/price-list/:parentId/price-list-edit-form/suggested-lines',
    element: <PriceEnteriesSuggestedLines />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/price-list/:parentId/price-list-edit-form/copy-lines',
    element: <PriceEnteriesCopyLines />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/price-list/:parentId/price-list-edit-form/:itemId/product-edit',
    element: <PriceEnteriesProductEditForm />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/price-list/:parentId/price-list-view-form/:itemId/product-edit',
    element: <PriceEnteriesProductViewForm />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/catalog',
    element: <Catalog />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/catalog/:id/edit',
    element: <CatalogEdit />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/catalog/:id/view',
    element: <CatalogView />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/report',
    element: <PromotionReport />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: 'promotion-engine/promotions/report/redemptionDetails/:id',
    element: <PromotionReportDetails />,
    errorElement: <GenericErrorComponent error={'test'} />
  },
  {
    path: '/promotion-engine/promotions/test-encryption',
    element: <TestEncryption />,
    errorElement: <GenericErrorComponent error={'test'} />
  }
];

const LOYALTY_ROUTES = [
  {
    path: '/loyalty/config/rewards',
    element: <RewardListPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/loyalty/config/rewards/create',
    element: <CreateRewardPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/loyalty/config/rewards/:id',
    element: <ViewRewardPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/loyalty/config/point-conversion',
    element: <PointConversionRulesListing />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/loyalty/config/point-conversion/create',
    element: <CreatePointConversionRule />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/loyalty/config/point-conversion/:id',
    element: <ViewPointConversionRule />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/loyalty/config/point-conversion/:id/new',
    element: <CreatePointConversionNewVersion />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'loyalty/config/burn-rule',
    element: <BurnRuleListing />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'loyalty/config/earn-rule',
    element: <EarnRulesListing />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'loyalty/config/earn-rule/:id',
    element: <EarnRulesView />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'loyalty/config/burn-rule/view/:id',
    element: <BurnRuleView />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'loyalty/config/burn-rule/:id/test',
    element: <BurnRuleTest />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'loyalty/config/earn-rule/:id/test',
    element: <EarnRulesTest />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'loyalty/config/burn-rule/create',
    element: <CreateBurnRules />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'loyalty/config/earn-rule/create',
    element: <CreateEarnRules />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'loyalty/config/burn-rule/:id/new',
    element: <BurnRuleNewVersion />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'loyalty/config/earn-rule/:id/new',
    element: <EarnRulesCreateNewVersion />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'loyalty/config/tiers',
    element: <CustomerTier />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'loyalty/config/channel-list',
    element: <ChannelListing />,
    errorElement: <GenericErrorComponent />
  },
  { path: 'loyalty/reports', element: <RewardHistory />, errorElement: <GenericErrorComponent /> },
  { path: 'loyalty/commercial-report', element: <CommercialReport />, errorElement: <GenericErrorComponent /> },
  {
    path: 'loyalty/reports/customer/detail',
    element: <RewardHistoryDetail />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/loyalty/dashboard',
    element: <LoyalityDashboard />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'loyalty/dashboard',
    element: <UnderConstructionPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: '/loyalty/analytics/rmf',
    element: <RFM />,
    errorElement: <GenericErrorComponent />
  }
];

const DATA_MANAGEMENT = [
  {
    path: 'data-management/customers',
    element: <CustomerListingPage />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'data-management/products',
    element: <ProductListing />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'data-management/customers/create',
    element: <CreateCustomer />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'data-management/customers/:id',
    element: <EditCustomer />,
    errorElement: <GenericErrorComponent />
  },
  {
    path: 'data-management/products/create',
    element: <CreateProduct />,
    errorElement: <GenericErrorComponent />
  },

  {
    path: 'data-management/products/:id',
    element: <EditProduct />,
    errorElement: <GenericErrorComponent />
  }
];

const router = createBrowserRouter([
  ...ADMIN_ROUTES,
  ...NEBULA_ROUTES,
  ...PROMOTION_ENGINE_ROUTES,
  ...LOYALTY_ROUTES,
  ...DATA_MANAGEMENT
]);

export { router };

import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  TableColumnsType,
  Typography
} from 'antd';
import { validationHelpers } from '../../../../helpers';
import React from 'react';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import ActionButton from '../../../../components/ActionButton';
import { CloudUploadOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import _ from '../../../../helpers/lodash';
import { RcFile } from 'antd/es/upload';
import * as XLSX from 'xlsx';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { useLoader } from '../../../../stores/use-loader';
import { promotionServiceCreate } from '../../services/promotionCreate.service';
import { useNavigate } from 'react-router-dom';
import WarningModal from '../../../../components/WarningModal';
import { getFormattedNumeric2DecimalPointValidatorForInput } from '../../../../helpers/validation.helpers';
import { TableRowSelection } from 'antd/es/table/interface';

interface IRewardProps {
  promotionForm: any;
  bdyData: any;
  handleOnGoBack: (Key: any, bdyData: any) => void;
  promotionResponse?: any;
  handleOnGoBackReward: (bdyData: any) => void;
}

const RewardForm: React.FunctionComponent<IRewardProps> = ({
  handleOnGoBack,
  promotionResponse,
  promotionForm,
  bdyData,
  handleOnGoBackReward
}) => {
  const navigate = useNavigate();
  const [rewardForm] = Form.useForm();
  const [updateRewardForm] = Form.useForm();
  const [volumeRewardForm] = Form.useForm();
  //SubTotal discount
  const [rewardData, setRewardData] = React.useState<any>([]);
  const [categoryData, setCategoryData] = React.useState<any>([]);
  const [importModalVisible, setImportModalVisible] = React.useState(false);
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [editRewardModal, setEditRewardModal] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  // const [warningBackModal, setWarningBackModal] = React.useState<boolean>(false);
  const [rewardSearchData, setRewardSearchData] = React.useState<any>([]);
  const [categorySearchData, setCategorySearchData] = React.useState<any>([]);
  //SubTotal discount
  //Volume Discount
  const [volRewardData, setVolRewardData] = React.useState<any>([]);
  const [volRewardSearchData, setVolRewardSearchData] = React.useState<any>([]);
  const [volumeEditModal, setVolumeEditModal] = React.useState(false);
  //Volume Discount
  const [pagination, setPagination] = React.useState({
    pageSize: 5,
    showSizeChanger: false,
    showQuickJumper: false
  });

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const product_columns: TableColumnsType<any> = [
    {
      title: 'Item ID',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.maximum_quantity}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => handleOnEditReward(record)}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  deleteReward(record);
                }}
              >
                <ActionButton action="DELETE" title={''} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const category_columns: TableColumnsType<any> = [
    {
      title: 'Category',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.category}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.maximum_quantity}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => handleOnEditReward(record)}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  deleteReward(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const volume_Product_Columns: TableColumnsType<any> = [
    {
      title: 'Item ID',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Special Price',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.discount_value}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => handleOnEditVolume_Product_Columns(record)}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  deleteVolumeReward(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  const volume_Percent_Absolute_Columns: TableColumnsType<any> = [
    {
      title: 'Item ID',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.item_id}</>;
      }
    },
    {
      title: 'Minimum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.minimum_quantity}</>;
      }
    },
    {
      title: 'Maximum Quantity',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.maximum_quantity}</>;
      }
    },
    {
      title: 'Action',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title="Edit"
                onClick={() => handleOnEditVolume_Product_Columns(record)}
              ></ActionButton>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => {
                  deleteVolumeReward(record);
                }}
              >
                <ActionButton action="DELETE" title={'Remove'} />
              </Popconfirm>
            </section>
          </>
        );
      }
    }
  ];

  React.useEffect(() => {
    loadIntialForm();
  }, []);

  const loadIntialForm = () => {
    const hasRewardDetails = 'reward_details' in bdyData;
    if (hasRewardDetails) {
      loadDataFromExistingData();
    } else {
      if (!_.isEmpty(promotionResponse)) {
        loadDataFromApi();
      }
    }
  };

  const loadDataFromApi = () => {
    const { discount, application_type, promotion_type } = promotionResponse;
    const { product_reward, discount_value, max_discount, threshold_selection, thresholds } = discount[0];
    if (application_type !== 'ORDER_TOTAL') {
      if (promotion_type === 'SUB_TTL_DIS') {
        if (promotionForm.apply_discount_on === 'PRODUCTS') {
          const flattenedConditions = product_reward?.product_info?.flatMap((info: any) => info?.conditions);
          setRewardData(flattenedConditions);
        } else {
          const flattenedConditions = product_reward?.product_info?.flatMap((info: any) => {
            let categoryAddingIndex = info?.conditions?.map((item: any, index: any) => {
              let obj = {
                categoryId: index,
                ...item
              };
              return obj;
            });
            return categoryAddingIndex;
            // return info?.conditions;
          });
          setCategoryData(flattenedConditions);
        }
      }
      if (promotion_type === 'BUNDLE_DISC') {
        const flattenedConditions = product_reward?.product_info?.flatMap((info: any) => info?.conditions);
        setVolRewardData(flattenedConditions);
      }
    }
    const setFormData = {
      discount_value,
      max_discount,
      threshold_selection,
      subtotal_treshold: thresholds?.threshold_list
    };
    rewardForm.setFieldsValue(setFormData);
  };

  const loadDataFromExistingData = () => {
    const { reward_details, application_type } = bdyData;
    const { promotionType } = promotionForm;
    if (reward_details.length > 0) {
      const { product_reward, discount_value, max_discount, threshold_selection } = reward_details[0];
      //populate data to Table
      if (application_type !== 'ORDER_TOTAL') {
        if (promotionType === 'SUB_TTL_DIS') {
          if (promotionForm.apply_discount_on === 'PRODUCTS') {
            const flattenedConditions = product_reward?.product_info?.flatMap((info: any) => info?.conditions);
            setRewardData(flattenedConditions);
          } else {
            const flattenedConditions = product_reward?.product_info?.flatMap((info: any) => {
              let categoryAddingIndex = info?.conditions?.map((item: any, index: any) => {
                let obj = {
                  categoryId: index,
                  ...item
                };
                return obj;
              });
              return categoryAddingIndex;
            });
            setCategoryData(flattenedConditions);
          }
        }
        if (promotionType === 'BUNDLE_DISC') {
          const flattenedConditions = product_reward?.product_info?.flatMap((info: any) => info?.conditions);
          setVolRewardData(flattenedConditions);
        }
      }
      if (!!threshold_selection === false) {
        rewardForm.setFieldsValue({
          threshold_selection: 'NO_THRESHOLD'
        });
      }
      const setFormData = {
        discount_value,
        max_discount
      };
      rewardForm.setFieldsValue(setFormData);
    }
  };

  const handleOnAddSubTotalItems = async () => {
    const fieldsToValidate: (string | (string | number)[])[] = ['item_id', 'minimum_quantity', 'maximum_quantity'];
    await rewardForm.validateFields(fieldsToValidate);
    addSubTotalItems();
  };

  const handleOnAddVolumeItems = async () => {
    let fieldsToValidate: (string | (string | number)[])[] = [];
    const { discount_type } = promotionForm;
    if (discount_type === 'SPL_PRICE') {
      fieldsToValidate = ['item_id', 'minimum_quantity', 'discount_value'];
    }
    if (discount_type === 'PERCENTAGE' || discount_type === 'FIXED') {
      fieldsToValidate = ['item_id', 'minimum_quantity', 'maximum_quantity'];
    }
    await rewardForm.validateFields(fieldsToValidate);
    addVolumeItem();
  };

  const handleOnAddCategory = async () => {
    const fieldsToValidate: (string | (string | number)[])[] = ['category', 'minimum_quantity', 'maximum_quantity'];
    await rewardForm.validateFields(fieldsToValidate);
    addCategories();
  };

  const handleOnEditReward = (record: any) => {
    updateRewardForm.setFieldsValue(record);
    setEditRewardModal(true);
  };

  const handleOnEditVolume_Product_Columns = (record: any) => {
    volumeRewardForm.setFieldsValue(record);
    setVolumeEditModal(true);
  };

  const addSubTotalItems = () => {
    const { item_id } = rewardForm.getFieldsValue();
    let isItemAvailable = rewardData?.findIndex((item: any) => item.item_id === item_id);
    if (isItemAvailable !== -1) {
      displayErrorNotifications([{ message: 'Item already Exits' }]);
    } else {
      const data = {
        ...rewardForm.getFieldsValue(),
        identifier_type: 'SKU_ID'
      };
      setRewardData((prev: any) => [...prev, data]);
      rewardForm.resetFields(['item_id', 'minimum_quantity', 'maximum_quantity']);
    }
  };

  const addVolumeItem = () => {
    const { item_id } = rewardForm.getFieldsValue();
    const { discount_type } = promotionForm;
    let fieldsToClear: (string | (string | number)[])[] = [];
    let isItemAvailable = volRewardData?.findIndex((item: any) => item.item_id === item_id);
    if (isItemAvailable !== -1) {
      displayErrorNotifications([{ message: 'Item already Exits' }]);
    } else {
      const data = {
        ...rewardForm.getFieldsValue(),
        identifier_type: 'SKU_ID'
      };
      setVolRewardData((prev: any) => [...prev, data]);
      if (discount_type === 'SPL_PRICE') {
        fieldsToClear = ['item_id', 'minimum_quantity', 'discount_value'];
      }
      if (discount_type === 'PERCENTAGE' || discount_type === 'FIXED') {
        fieldsToClear = ['item_id', 'minimum_quantity', 'maximum_quantity'];
      }
      rewardForm.resetFields(fieldsToClear);
    }
  };

  const addCategories = () => {
    const data = {
      ...rewardForm.getFieldsValue(),
      identifier_type: 'SKU_ID',
      item_id: '0'
    };
    setCategoryData((prev: any) => [...prev, data]);
  };

  const deleteReward = (record: any) => {
    const { apply_discount_on } = promotionForm;
    if (apply_discount_on === 'PRODUCTS') {
      const updatedRewardData = rewardData.filter((item: any) => item.item_id !== record.item_id);
      setRewardData(updatedRewardData);
    } else {
      let updatedRewardData = categoryData.filter((item: any) => item.categoryId !== record.categoryId);
      updatedRewardData = updatedRewardData?.map((item: any, index: any) => {
        return {
          ...item,
          categoryId: index
        };
      });
      setCategoryData(updatedRewardData);
    }
  };

  const deleteVolumeReward = (record: any) => {
    const updatedRewardData = volRewardData.filter((item: any) => item.item_id !== record.item_id);
    setVolRewardData(updatedRewardData);
  };

  const closePostCodeModal = () => {
    setFileList([]);
    setImportModalVisible(false);
  };

  const handleOnUpload = () => {
    if (_.isEmpty(fileList)) return;

    const formData = new FormData();
    formData.set('file', fileList[0] as RcFile);
    if (fileList) {
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const data = event?.target?.result as ArrayBuffer;
        const workbook = XLSX.read(data, {
          type: 'binary'
        });
        let excelDataParsed: any[] = [];
        workbook.SheetNames.forEach(sheet => {
          if (excelDataParsed.length === 0) {
            const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            const jsonObject = JSON.stringify(rowObject);
            const getExceldata = JSON.parse(jsonObject);
            excelDataParsed.push(getExceldata);
            getValidData(excelDataParsed[0]);
          }
        });
      };
      let selectedFile = fileList[0];
      fileReader.readAsBinaryString(selectedFile);
    }
  };

  const getValidData = async (excelData: any) => {
    // setLoading(true);
    if (excelData.length > 10000) {
      displayErrorNotifications([{ message: 'Data cannot exced more than ten thousand' }]);
      return;
    } else {
      let bodyData = loadExcelBodyData(excelData);
      if (promotionForm.promotionType === 'SUB_TTL_DIS') {
        if (promotionForm.apply_discount_on === 'PRODUCTS') {
          setRewardData([...rewardData, ...bodyData]);
        } else {
          // Find the last categoryId in categoryData
          const lastCategoryId = categoryData.length
            ? Math.max(...categoryData.map((item: any) => item.categoryId))
            : -1;
          const updatedCategoryData = [
            ...categoryData,
            ...bodyData.map((item: any, index: any) => ({
              categoryId: lastCategoryId + index + 1,
              ...item
            }))
          ];
          setCategoryData(updatedCategoryData);
        }
      }
      if (promotionForm.promotionType === 'BUNDLE_DISC') {
        setVolRewardData([...volRewardData, ...bodyData]);
      }
      closePostCodeModal();
    }
  };

  const loadExcelBodyData = (excelData: any) => {
    let allData = excelData.map((item: any) => {
      return item;
    });
    return allData;
  };

  const handleOnUpdateItem = () => {
    const { apply_discount_on } = promotionForm;
    if (apply_discount_on === 'PRODUCTS') {
      const { item_id } = updateRewardForm.getFieldsValue();
      const itemIdToUpdate = item_id;
      const updatedData = updateRewardForm.getFieldsValue();
      const CrrupdateData = rewardData.map((item: any) => {
        if (item.item_id === itemIdToUpdate) {
          return updatedData;
        } else {
          return item;
        }
      });
      setRewardData(CrrupdateData);
    } else {
      const { categoryId } = updateRewardForm.getFieldsValue();
      const itemIdToUpdate = categoryId;
      const updatedData = updateRewardForm.getFieldsValue();
      const CrrupdateData = categoryData.map((item: any) => {
        if (item.categoryId === itemIdToUpdate) {
          return updatedData;
        } else {
          return item;
        }
      });
      setCategoryData(CrrupdateData);
    }
    updateRewardForm.resetFields();
    setEditRewardModal(false);
    displaySuccessNotification({ message: 'Reward updated successfully!' });
  };

  const handleOnEditVolumePromo = () => {
    const { item_id } = volumeRewardForm.getFieldsValue();
    const itemIdToUpdate = item_id;
    const updatedData = volumeRewardForm.getFieldsValue();
    const CrrupdateData = volRewardData.map((item: any) => {
      if (item.item_id === itemIdToUpdate) {
        return updatedData;
      } else {
        return item;
      }
    });
    setVolRewardData(CrrupdateData);
    setVolumeEditModal(false);
    displaySuccessNotification({ message: 'Reward updated successfully!' });
  };

  const handleOnUpdatePromo = async () => {
    // setLoading(true);
    try {
      const allFields = Object.keys(rewardForm.getFieldsValue());
      const fieldToIgnore = ['item_id', 'category', 'minimum_quantity', 'maximum_quantity'];
      // Filter out the field to ignore from the list of all fields
      const fieldsToValidate = allFields.filter(field => !fieldToIgnore?.includes(field));

      // Validate all fields except the one to ignore
      await rewardForm.validateFields(fieldsToValidate);
      AddItem();
    } catch (error) {
      console.log('valatiateSaveHandler', error);
      // displayErrorNotifications([{ message: 'Something Went Wrong !' }]);
    }
    // setLoading(false);
  };

  const handleOnUpdateVolumePromo = async () => {
    // setLoading(true);
    try {
      const allFields = Object.keys(rewardForm.getFieldsValue());
      const fieldToIgnore = ['item_id', 'minimum_quantity', 'discount_value', 'maximum_quantity'];
      // Filter out the field to ignore from the list of all fields
      const fieldsToValidate = allFields.filter(field => !fieldToIgnore?.includes(field));

      // Validate all fields except the one to ignore
      await rewardForm.validateFields(fieldsToValidate);
      AddItem();
    } catch (error) {
      console.log('valatiateSaveHandler', error);
      // displayErrorNotifications([{ message: 'Something Went Wrong !' }]);
    }
    // setLoading(false);
  };

  const handleOnUpdateCategoryPromo = async () => {
    // setLoading(true);
    try {
      const allFields = Object.keys(rewardForm.getFieldsValue());
      const fieldToIgnore = ['item_id', 'category', 'minimum_quantity', 'maximum_quantity'];
      const fieldsToValidate = allFields.filter(field => !fieldToIgnore?.includes(field));

      await rewardForm.validateFields(fieldsToValidate);
      AddItem();
    } catch (error) {
      console.log('valatiateSaveHandler', error);
    }
    // setLoading(false);
  };

  const handleOnUpdateTreshold = async () => {
    try {
      const allFields = Object.keys(rewardForm.getFieldsValue());
      // const fieldToIgnore = ['item_id', 'category', 'minimum_quantity', 'maximum_quantity'];
      // const fieldsToValidate = allFields.filter(field => !fieldToIgnore?.includes(field));

      await rewardForm.validateFields(allFields);
      AddThreshold();
    } catch (error) {
      console.log('valatiateSaveHandler', error);
    }
  };

  const getNewReward = () => {
    const { promotionType, appliesTo, threshold_selection, subtotal_treshold, discount_type, apply_discount_on } =
      promotionForm;

    const { discount_value, max_discount } = rewardForm.getFieldsValue();
    if (promotionType === 'SUB_TTL_DIS' && discount_type === 'PERCENTAGE') {
      let isOrderTotal = appliesTo === 'ORDER_TOTAL';

      const getTresholdData = () => {
        const thresholdData = subtotal_treshold?.map((item: any) => {
          let data;
          if (item?.max_limit_set) {
            data = {
              ...item,
              discount_value: Number(item.discount_value),
              maximum_value: 999999999 // Remove maximum_value if max_limit_set is true
            };
          } else {
            data = {
              ...item,
              discount_value: Number(item.discount_value)
            };
          }
          return data;
        });
        return thresholdData;
      };

      const getConditionList = () => {
        if (apply_discount_on === 'PRODUCTS') {
          return rewardData;
        }
        if (apply_discount_on === 'CATEGORY') {
          return categoryData;
        }
        return [];
      };
      let combData = [
        {
          discount_type: discount_type,
          threshold_selection: isOrderTotal ? threshold_selection : 'NO_THRESHOLD',
          thresholds: isOrderTotal ? (subtotal_treshold ? { threshold_list: getTresholdData() } : null) : null,
          discount_value: discount_value || 0,
          applies_to_price: 'NONE',
          max_discount: max_discount || 0,
          apply_discount_on: isOrderTotal ? 'ORDER' : apply_discount_on,
          product_reward: isOrderTotal
            ? null
            : {
                product_info: [
                  {
                    selection_type: 'BASIC',
                    operator: 'OR',
                    min_line_items: 0,
                    conditions: getConditionList()
                  }
                ]
              }
        }
      ];
      return combData;
    }
    if (promotionType === 'BUNDLE_DISC') {
      const getConditionList = () => {
        return volRewardData;
      };
      let combData = [
        {
          discount_type: discount_type,
          threshold_selection: 'NO_THRESHOLD',
          discount_value: discount_value || null,
          applies_to_price: 'NONE',
          max_discount: max_discount || null,
          apply_discount_on: 'PRODUCTS',
          product_reward: {
            product_info: [
              {
                selection_type: 'BUNDLE',
                operator: 'AND',
                conditions: getConditionList()
              }
            ]
          }
        }
      ];
      return combData;
    }
  };

  const AddItem = () => {
    const { apply_discount_on } = promotionForm;
    if (apply_discount_on === 'PRODUCTS') {
      AddReward();
    } else {
      AddCategory();
    }
  };

  const getThresholdReward = () => {
    const { appliesTo, discount_type, apply_discount_on } = promotionForm;

    const { discount_value, max_discount, threshold_selection, subtotal_treshold } = rewardForm.getFieldsValue();
    let isOrderTotal = appliesTo === 'ORDER_TOTAL';

    const getTresholdData = () => {
      const thresholdData = subtotal_treshold?.map((item: any) => {
        let data;
        if (item?.max_limit_set) {
          data = {
            ...item,
            discount_value: Number(item.discount_value),
            maximum_value: 999999999 // Remove maximum_value if max_limit_set is true
          };
        } else {
          data = {
            ...item,
            discount_value: Number(item.discount_value)
          };
        }
        return data;
      });
      return thresholdData;
    };

    let combData = [
      {
        discount_type: discount_type,
        threshold_selection: isOrderTotal ? threshold_selection : 'NO_THRESHOLD',
        thresholds: isOrderTotal ? (subtotal_treshold ? { threshold_list: getTresholdData() } : null) : null,
        discount_value: discount_value || 0,
        applies_to_price: 'NONE',
        max_discount: max_discount || 0,
        apply_discount_on: isOrderTotal ? 'ORDER' : apply_discount_on,
        product_reward: isOrderTotal
          ? null
          : {
              product_info: [
                {
                  selection_type: 'BASIC',
                  operator: 'OR',
                  min_line_items: 0,
                  conditions: null
                }
              ]
            }
      }
    ];
    return combData;
  };

  const AddReward = () => {
    // setLoading(true);
    let creationBodyData = bdyData;
    let rewardData = getNewReward();
    let combinedBodyData = {
      ...creationBodyData,
      reward_details: rewardData
        ? Object.values(rewardData).map(reward => ({
            applies_to_price: reward.applies_to_price,
            apply_discount_on: reward.apply_discount_on,
            discount_type: reward.discount_type,
            product_reward: reward.product_reward,
            threshold_selection: reward.threshold_selection,
            thresholds: reward.thresholds,
            discount_value: reward.discount_value,
            max_discount: reward.max_discount
          }))
        : []
    };

    if (!_.isEmpty(promotionResponse)) {
      combinedBodyData?.reward_details?.forEach((reward: any) => {
        reward.discount_id = promotionResponse?.discount[0]?.discount_id;
      });
      handleUpdate(combinedBodyData);
    } else {
      handleOnCreate(combinedBodyData);
    }
  };

  const AddCategory = () => {
    let creationBodyData = bdyData;
    let rewardData = getNewReward();
    let combinedBodyData = {
      ...creationBodyData,
      reward_details: rewardData
        ? Object.values(rewardData).map(reward => ({
            applies_to_price: reward.applies_to_price,
            apply_discount_on: reward.apply_discount_on,
            discount_type: reward.discount_type,
            product_reward: reward.product_reward,
            threshold_selection: reward.threshold_selection,
            thresholds: reward.thresholds,
            discount_value: reward.discount_value,
            max_discount: reward.max_discount
          }))
        : []
    };

    if (!_.isEmpty(promotionResponse)) {
      combinedBodyData?.reward_details?.forEach((reward: any) => {
        reward.discount_id = promotionResponse?.discount[0]?.discount_id;
      });
      handleUpdate(combinedBodyData);
    } else {
      handleOnCreate(combinedBodyData);
    }
  };

  const AddThreshold = () => {
    let creationBodyData = bdyData;
    let rewardData = getThresholdReward();
    let combinedBodyData = {
      ...creationBodyData,
      reward_details: rewardData
        ? Object.values(rewardData).map(reward => ({
            applies_to_price: reward.applies_to_price,
            apply_discount_on: reward.apply_discount_on,
            discount_type: reward.discount_type,
            product_reward: reward.product_reward,
            threshold_selection: reward.threshold_selection,
            thresholds: reward.thresholds,
            discount_value: reward.discount_value,
            max_discount: reward.max_discount
          }))
        : []
    };
    if (!_.isEmpty(promotionResponse)) {
      combinedBodyData?.reward_details?.forEach((reward: any) => {
        reward.discount_id = promotionResponse?.discount[0]?.discount_id;
      });
      handleUpdate(combinedBodyData);
    } else {
      handleOnCreate(combinedBodyData);
    }
  };

  const handleUpdate = async (bodyData: any) => {
    setLoading(true);
    const { errors } = await promotionServiceCreate.updatePromotion(bodyData, promotionResponse?.id);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Promotion updated successfully!' });
      navigate(-1);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnCreate = async (bodyData: any) => {
    const { errors } = await promotionServiceCreate.getCreatePromotion(bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Promotion created successfully!' });
      navigate(-1);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const onDeleteSelected = () => {
    const { apply_discount_on, promotionType } = promotionForm;
    if (promotionType === 'SUB_TTL_DIS') {
      if (apply_discount_on === 'PRODUCTS') {
        let filter_Data = rewardData?.filter((item: any) => !selectedRowKeys?.includes(item?.item_id));
        setRewardData(filter_Data);
        displaySuccessNotification({ message: 'Selected Items Removed Successfully!!' });
      } else {
        let filterData = categoryData?.filter((item: any) => !selectedRowKeys.includes(item.categoryId));
        let reAssignCatId = filterData?.map((item: any, index: any) => {
          return {
            ...item,
            categoryId: index
          };
        });
        setCategoryData(reAssignCatId);
        displaySuccessNotification({ message: 'Selected Items Removed Successfully!!' });
      }
    }
    if (promotionType === 'BUNDLE_DISC') {
      let filter_Data = volRewardData?.filter((item: any) => !selectedRowKeys?.includes(item?.item_id));
      setVolRewardData(filter_Data);
      displaySuccessNotification({ message: 'Selected Items Removed Successfully!!' });
    }
    setSelectedRowKeys([]);
  };

  const goBackSaver = (creationBodyData: any) => {
    let combinedBodyData = {};
    const { appliesTo, promotion_type } = promotionForm;
    if (appliesTo === 'ORDER_TOTAL') {
    } else {
      let rewardData = getNewReward();
      combinedBodyData = {
        ...creationBodyData,
        reward_details: rewardData
          ? Object.values(rewardData).map(reward => ({
              applies_to_price: reward.applies_to_price,
              apply_discount_on: reward.apply_discount_on,
              discount_type: reward.discount_type,
              product_reward: reward.product_reward,
              threshold_selection: reward.threshold_selection,
              thresholds: reward.thresholds,
              discount_value: reward.discount_value,
              max_discount: reward.max_discount
            }))
          : []
      };
    }
    return combinedBodyData;
  };

  const handleOnSearch = () => {
    const { apply_discount_on, promotionType } = promotionForm;
    const { search } = rewardForm.getFieldsValue();
    if (search === '') {
      setRewardSearchData([]);
      setCategorySearchData([]);
      setVolRewardSearchData([]);
      return;
    }
    if (promotionType === 'SUB_TTL_DIS') {
      if (apply_discount_on === 'PRODUCTS') {
        let searchData = rewardData?.find((item: any) => item?.item_id === search);
        let casualData = [searchData];
        setRewardSearchData(casualData);
      }
      if (apply_discount_on === 'CATEGORY') {
        let searchData = categoryData?.filter((item: any) => item?.category === search);
        setCategorySearchData(searchData);
      }
    }
    if (promotionType === 'BUNDLE_DISC') {
      let searchData = volRewardData?.find((item: any) => item?.item_id === search);
      let casualData = [searchData];
      setVolRewardSearchData(casualData);
    }
  };

  const SubTotalScreenRenderer = () => {
    const { appliesTo, apply_discount_on } = promotionForm;

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<any> = {
      selectedRowKeys,
      onChange: onSelectChange,
      selections: [Table.SELECTION_ALL, Table.SELECTION_NONE]
    };

    return (
      <>
        {appliesTo === 'LINE_ITEMS' && (
          <>
            <Row gutter={12}>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Discount Value"
                  name={'discount_value'}
                  rules={[
                    { required: true, message: "This field can't be empty" },
                    validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                      message: 'Enter up to 10 digits and 2 decimals'
                    })
                  ]}
                >
                  <InputNumber
                    min={1}
                    size="large"
                    placeholder="Discount Value"
                    className="w-full"
                    style={{ borderRadius: '0' }}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Maximum Discount"
                  name={'max_discount'}
                  rules={[
                    { required: false, message: "This field can't be empty" },
                    validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                      message: 'Enter up to 10 digits and 2 decimals'
                    })
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder="Maximum Discount"
                    className="w-full"
                    style={{ borderRadius: '0' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div>
              <Card>
                <Row gutter={12}>
                  {apply_discount_on === 'PRODUCTS' && (
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Item ID"
                        name={'item_id'}
                        rules={[{ required: true, message: "This field can't be empty" }]}
                      >
                        <Input size="large" placeholder="Item Id" />
                      </Form.Item>
                    </Col>
                  )}
                  {apply_discount_on === 'CATEGORY' && (
                    <Col xs={12} md={6}>
                      <Form.Item
                        label="Category"
                        name={'category'}
                        rules={[{ required: true, message: "This field can't be empty" }]}
                      >
                        <Input size="large" placeholder="Category" />
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={12} md={6}>
                    <Form.Item
                      label={'Minimum Quantity'}
                      name={'minimum_quantity'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                          message: 'Enter up to 10 digits and 2 decimals'
                        })
                      ]}
                    >
                      <InputNumber
                        placeholder={'Minimum Quantity'}
                        className="w-full"
                        min={0}
                        size="large"
                        style={{ borderRadius: '0' }}
                      ></InputNumber>
                    </Form.Item>
                  </Col>{' '}
                  <Col xs={12} md={6}>
                    <Form.Item
                      label={'Maximum Quantity'}
                      name={'maximum_quantity'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                          message: 'Enter up to 10 digits and 2 decimals'
                        })
                      ]}
                    >
                      <InputNumber
                        placeholder={'Maximum Quantity'}
                        className="w-full"
                        min={0}
                        size="large"
                        style={{ borderRadius: '0' }}
                      ></InputNumber>
                    </Form.Item>
                  </Col>{' '}
                </Row>
                {promotionForm.apply_discount_on === 'PRODUCTS' && (
                  <div className="mt-4">
                    <Button type="primary" size="large" onClick={handleOnAddSubTotalItems}>
                      <BoldButtonLabel labelText={'Add Product'} />
                    </Button>
                  </div>
                )}
                {promotionForm.apply_discount_on === 'CATEGORY' && (
                  <div className="mt-4">
                    <Button type="primary" size="large" onClick={handleOnAddCategory}>
                      <BoldButtonLabel labelText={'Add Category'} />
                    </Button>
                  </div>
                )}
              </Card>
              <div className="flex items-center justify-between mt-4">
                <Row gutter={24}>
                  <Col xs={12} md={24}>
                    <Form.Item label="" name={'search'}>
                      <Input.Search
                        placeholder="Search..."
                        onSearch={handleOnSearch}
                        enterButton
                        size="large"
                      ></Input.Search>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col>
                    <Button
                      size="large"
                      block
                      type="primary"
                      onClick={() => {
                        setImportModalVisible(true);
                      }}
                    >
                      <BoldButtonLabel labelText="Bulk Upload" />
                      <UploadOutlined />
                    </Button>
                  </Col>
                </Row>
              </div>
              <section className="mt-4">
                {selectedRowKeys.length > 0 && (
                  <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
                    <Button type="primary" danger icon={<DeleteOutlined />} onClick={onDeleteSelected}>
                      Delete Selected
                    </Button>
                  </div>
                )}
                <Table
                  rowSelection={rowSelection}
                  loading={false}
                  pagination={{ ...pagination, position: ['bottomRight'] }}
                  bordered
                  dataSource={
                    promotionForm.apply_discount_on === 'PRODUCTS'
                      ? rewardSearchData?.length > 0
                        ? rewardSearchData
                        : rewardData
                      : categorySearchData?.length > 0
                      ? categorySearchData
                      : categoryData
                  }
                  columns={promotionForm.apply_discount_on === 'PRODUCTS' ? product_columns : category_columns}
                  scroll={{ x: 1000 }}
                  rowKey={(record: any) => {
                    if (promotionForm.apply_discount_on === 'PRODUCTS') {
                      return record?.item_id;
                    } else {
                      return record?.categoryId;
                    }
                  }}
                ></Table>
              </section>
            </div>
          </>
        )}
        {appliesTo === 'ORDER_TOTAL' && (
          <>
            <Row gutter={12}>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Threshold Selection"
                  name={'threshold_selection'}
                  rules={[{ required: true, message: "This field can't be empty" }]}
                >
                  <Select
                    allowClear
                    placeholder="Select"
                    size="large"
                    options={[
                      { label: 'None', value: 'NO_THRESHOLD' },
                      { label: 'MOV', value: 'MOV' },
                      { label: 'MOQ', value: 'MOQ' }
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.threshold_selection !== curValues.threshold_selection;
                }}
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const current_threshold_selection = getFieldValue('threshold_selection');
                  return (
                    current_threshold_selection === 'NO_THRESHOLD' && (
                      <Col xs={12} md={6}>
                        <Form.Item
                          label="Discount Value"
                          name="discount_value"
                          rules={[
                            { required: true, message: "This field can't be empty" },
                            validationHelpers.getNumericValidatorForInput({})
                          ]}
                        >
                          <Input size="large" placeholder="Discount Value" />
                        </Form.Item>
                      </Col>
                    )
                  );
                }}
              </Form.Item>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Maximum Discount"
                  name={'max_discount'}
                  rules={[
                    { required: false, message: "This field can't be empty" },
                    validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                      message: 'Enter up to 10 digits and 2 decimals'
                    })
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder="Maximum Discount"
                    className="w-full"
                    style={{ borderRadius: '0' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return (
                  prevValues.threshold_selection !== curValues.threshold_selection ||
                  prevValues.subtotal_treshold !== curValues.subtotal_treshold
                );
              }}
            >
              {({ getFieldValue, setFieldsValue }) => {
                const thresholdSelection = getFieldValue('threshold_selection');
                const subtotalThreshold = getFieldValue('subtotal_treshold') || [];

                if (thresholdSelection && thresholdSelection !== 'NO_THRESHOLD' && subtotalThreshold.length === 0) {
                  setFieldsValue({
                    subtotal_treshold: [{ minimum_value: null, max_limit_set: false }]
                  });
                }

                let previousMaxValue = 0;
                if (subtotalThreshold?.length > 0) {
                  const lastItem = subtotalThreshold[subtotalThreshold.length - 1];
                  previousMaxValue = lastItem.maximum_value || 0;
                }

                return (
                  thresholdSelection &&
                  thresholdSelection !== 'NO_THRESHOLD' && (
                    <>
                      <Form.List name="subtotal_treshold">
                        {(fields, { add, remove }) => (
                          <>
                            {fields?.map(({ key, name, fieldKey, ...restField }, index) => {
                              const maxLimitSet = getFieldValue(['subtotal_treshold', name, 'max_limit_set']);
                              const isLastItem = index === fields.length - 1;
                              const currentPreviousMaxValue =
                                index > 0
                                  ? getFieldValue(['subtotal_treshold', fields[index - 1].name, 'maximum_value'])
                                  : 0;
                              return (
                                <div key={key} {...restField}>
                                  <Row gutter={12}>
                                    <Col xs={12} md={6}>
                                      <Form.Item
                                        label="Minimum Value"
                                        name={[name, 'minimum_value']}
                                        rules={[
                                          { required: true, message: "This field can't be empty" },
                                          {
                                            validator: (_, value) => {
                                              if (value >= currentPreviousMaxValue) {
                                                return Promise.resolve();
                                              }
                                              return Promise.reject(
                                                new Error(
                                                  `Minimum Value must be greater than ${currentPreviousMaxValue}`
                                                )
                                              );
                                            }
                                          },
                                          getFormattedNumeric2DecimalPointValidatorForInput({
                                            message:
                                              'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                          })
                                        ]}
                                      >
                                        <InputNumber
                                          min={currentPreviousMaxValue}
                                          size="large"
                                          placeholder="Minimum Value"
                                          className="w-full"
                                          style={{ borderRadius: '0' }}
                                        />
                                      </Form.Item>
                                    </Col>
                                    {!maxLimitSet && (
                                      <Col xs={12} md={6}>
                                        <Form.Item
                                          label="Maximum Value"
                                          name={[name, 'maximum_value']}
                                          rules={[
                                            { required: true, message: "This field can't be empty" },
                                            {
                                              validator: (_, value) => {
                                                const minValue = getFieldValue([
                                                  'subtotal_treshold',
                                                  name,
                                                  'minimum_value'
                                                ]);
                                                if (value >= minValue) {
                                                  return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                  new Error('Maximum Value must be greater than Minimum Value')
                                                );
                                              }
                                            },
                                            getFormattedNumeric2DecimalPointValidatorForInput({
                                              message:
                                                'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                            })
                                          ]}
                                        >
                                          <InputNumber
                                            min={1}
                                            size="large"
                                            placeholder="Maximum Value"
                                            className="w-full"
                                            style={{ borderRadius: '0' }}
                                          />
                                        </Form.Item>
                                      </Col>
                                    )}
                                    <Col xs={12} md={6}>
                                      <Form.Item
                                        label="Discount Value"
                                        name={[name, 'discount_value']}
                                        rules={[
                                          { required: true, message: "This field can't be empty" },
                                          getFormattedNumeric2DecimalPointValidatorForInput({
                                            message:
                                              'Please enter a valid numeric value with up to 2 decimal places. Eg: (1.23)'
                                          }),
                                          {
                                            validator: async (_: any, value: any) => {
                                              const discountType = getFieldValue('discount_type');
                                              if (discountType === 'PERCENTAGE' && value > 100) {
                                                return Promise.reject(new Error('Percentage cannot exceed 100.'));
                                              }
                                              return Promise.resolve();
                                            }
                                          }
                                        ]}
                                      >
                                        <Input
                                          size="large"
                                          placeholder="Discount Value"
                                          // onBlur={formatToTwoDecimalPlaces}
                                        />
                                      </Form.Item>
                                    </Col>
                                    {isLastItem && (
                                      <Col xs={12} md={3} className="mt-8">
                                        <Form.Item valuePropName="checked" name={[name, 'max_limit_set']}>
                                          <Checkbox>Max Limit Set</Checkbox>
                                        </Form.Item>
                                      </Col>
                                    )}
                                    {fields.length > 1 && (
                                      <Col xs={12} md={2}>
                                        <div className="mx-4 h-full flex justify-center items-center">
                                          <DeleteOutlined
                                            className="text-red-500 text-xl cursor-pointer"
                                            role="button"
                                            title="Remove"
                                            onClick={() => remove(name)}
                                          />
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              );
                            })}
                            {/* <Row>
                                <Col> */}
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => add({ minimum_value: previousMaxValue, max_limit_set: false })}
                                className="mt-2"
                                disabled={fields.some(field =>
                                  getFieldValue(['subtotal_treshold', field.name, 'max_limit_set'])
                                )}
                              >
                                Add
                              </Button>
                            </Form.Item>
                            {/* </Col>
                              </Row> */}
                          </>
                        )}
                      </Form.List>
                    </>
                  )
                );
              }}
            </Form.Item>
          </>
        )}
      </>
    );
  };

  const VolumeScreenRenderer = () => {
    const { discount_type } = promotionForm;

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const volRowSelection: TableRowSelection<any> = {
      selectedRowKeys,
      onChange: onSelectChange,
      selections: [Table.SELECTION_ALL, Table.SELECTION_NONE]
    };

    const getVolumeColumn = () => {
      if (discount_type === 'SPL_PRICE') {
        return volume_Product_Columns;
      }
      if (discount_type === 'PERCENTAGE' || discount_type === 'FIXED') {
        return volume_Percent_Absolute_Columns;
      }
      return [];
    };

    return (
      <>
        {discount_type !== 'SPL_PRICE' && (
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                label="Discount Value"
                name={'discount_value'}
                rules={[
                  { required: true, message: "This field can't be empty" },
                  validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                    message: 'Enter up to 10 digits and 2 decimals'
                  })
                ]}
              >
                <InputNumber
                  min={1}
                  size="large"
                  placeholder="Discount Value"
                  className="w-full"
                  style={{ borderRadius: '0' }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label="Maximum Discount"
                name={'max_discount'}
                rules={[
                  { required: false, message: "This field can't be empty" },
                  validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                    message: 'Enter up to 10 digits and 2 decimals'
                  })
                ]}
              >
                <InputNumber
                  size="large"
                  placeholder="Maximum Discount"
                  className="w-full"
                  style={{ borderRadius: '0' }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <div>
          <Card>
            <Row gutter={12}>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Item ID"
                  name={'item_id'}
                  rules={[{ required: true, message: "This field can't be empty" }]}
                >
                  <Input size="large" placeholder="Item Id" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Minimum Quantity"
                  name={'minimum_quantity'}
                  rules={[{ required: true, message: "This field can't be empty" }]}
                >
                  <Input size="large" placeholder="Minimum Quantity" />
                </Form.Item>
              </Col>
              {(discount_type === 'PERCENTAGE' || discount_type === 'FIXED') && (
                <Col xs={12} md={6}>
                  <Form.Item
                    label={'Maximum Quantity'}
                    name={'maximum_quantity'}
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                        message: 'Enter up to 10 digits and 2 decimals'
                      })
                    ]}
                  >
                    <InputNumber
                      placeholder={'Maximum Quantity'}
                      className="w-full"
                      min={0}
                      size="large"
                      style={{ borderRadius: '0' }}
                    ></InputNumber>
                  </Form.Item>
                </Col>
              )}
              {discount_type === 'SPL_PRICE' && (
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Special Price"
                    name={'discount_value'}
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Special Price" />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Card>
        </div>
        <div className="mt-2">
          <Button type="primary" size="large" onClick={handleOnAddVolumeItems}>
            <BoldButtonLabel labelText={'Add Product'} />
          </Button>
        </div>
        <div className="flex items-center justify-between mt-4">
          <Row gutter={24}>
            <Col xs={12} md={24}>
              <Form.Item label="" name={'search'}>
                <Input.Search placeholder="Search..." onSearch={handleOnSearch} enterButton size="large"></Input.Search>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col>
              <Button
                size="large"
                block
                type="primary"
                onClick={() => {
                  setImportModalVisible(true);
                }}
              >
                <BoldButtonLabel labelText="Bulk Upload" />
                <UploadOutlined />
              </Button>
            </Col>
          </Row>
        </div>
        <section className="mt-4">
          {selectedRowKeys.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
              <Button type="primary" danger icon={<DeleteOutlined />} onClick={onDeleteSelected}>
                Delete Selected
              </Button>
            </div>
          )}
          <Table
            rowSelection={volRowSelection}
            loading={false}
            pagination={{ ...pagination, position: ['bottomRight'] }}
            bordered
            dataSource={volRewardSearchData.length > 0 ? volRewardSearchData : volRewardData}
            columns={getVolumeColumn()}
            scroll={{ x: 1000 }}
            rowKey={(record: any) => record?.item_id}
          ></Table>
        </section>
      </>
    );
  };

  return (
    <div>
      <Card>
        <Form form={rewardForm} scrollToFirstError layout="vertical">
          {promotionForm.promotionType === 'SUB_TTL_DIS' && SubTotalScreenRenderer()}
          {promotionForm.promotionType === 'BUNDLE_DISC' && VolumeScreenRenderer()}
          <Row gutter={[12, 12]} className="mt-4">
            <Col xs={12} md={6}>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  let creationBodyData = bdyData;
                  let combinedBodyData = goBackSaver(creationBodyData);
                  handleOnGoBack(0, combinedBodyData);
                }}
                block
              >
                <BoldButtonLabel labelText="Go Back"></BoldButtonLabel>
              </Button>
            </Col>
            {promotionForm.promotionType === 'BUNDLE_DISC' && (
              <Col xs={12} md={6}>
                <Button
                  size="large"
                  type="primary"
                  block
                  disabled={volRewardData.length < 1}
                  onClick={() => {
                    handleOnUpdateVolumePromo();
                  }}
                >
                  <BoldButtonLabel labelText="Update Product"></BoldButtonLabel>
                </Button>
              </Col>
            )}
            {promotionForm.apply_discount_on === 'PRODUCTS' && (
              <Col xs={12} md={6}>
                <Button
                  size="large"
                  type="primary"
                  block
                  disabled={rewardData.length < 1}
                  onClick={() => {
                    handleOnUpdatePromo();
                  }}
                >
                  <BoldButtonLabel labelText="Update Product"></BoldButtonLabel>
                </Button>
              </Col>
            )}
            {promotionForm.apply_discount_on === 'CATEGORY' && (
              <Col xs={12} md={6}>
                <Button
                  size="large"
                  type="primary"
                  block
                  disabled={categoryData.length < 1}
                  onClick={() => {
                    handleOnUpdateCategoryPromo();
                  }}
                >
                  <BoldButtonLabel labelText="Update Category"></BoldButtonLabel>
                </Button>
              </Col>
            )}
            {promotionForm.appliesTo === 'ORDER_TOTAL' && (
              <Col xs={12} md={6}>
                <Button
                  size="large"
                  type="primary"
                  block
                  onClick={() => {
                    handleOnUpdateTreshold();
                  }}
                >
                  <BoldButtonLabel labelText="Update"></BoldButtonLabel>
                </Button>
              </Col>
            )}
          </Row>
        </Form>
        <Modal
          centered
          open={importModalVisible}
          onCancel={() => {
            closePostCodeModal();
          }}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>Import Data</Typography.Title>
            </div>
          }
        >
          <section className="flex flex-col justify-center">
            <Button type="link" className="mb-4">
              Download Sample Template
            </Button>
            <Dragger
              beforeUpload={file => {
                setFileList([file]);
                return false;
              }}
              onRemove={() => {
                setFileList([]);
              }}
              fileList={fileList}
              name="file"
              accept={'.csv,.xlsx'}
            >
              <p>
                <CloudUploadOutlined className="text-9xl text-[#5885AF]" />
              </p>
              <p>Click or Drag Customers Excel File to upload</p>
            </Dragger>
            <Button
              disabled={_.isEmpty(fileList)}
              block
              size="large"
              type="primary"
              onClick={handleOnUpload}
              className="mt-4"
            >
              <BoldButtonLabel labelText="Upload" />
            </Button>
          </section>
        </Modal>
        <Modal
          centered
          open={editRewardModal}
          onCancel={() => {
            setEditRewardModal(false);
          }}
          width={1000}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>Edit Reward</Typography.Title>
            </div>
          }
        >
          <Form
            form={updateRewardForm}
            scrollToFirstError
            layout="vertical"
            onFinish={() => {
              handleOnUpdateItem();
            }}
          >
            <Row gutter={12}>
              {promotionForm.apply_discount_on === 'PRODUCTS' && (
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Item ID"
                    name={'item_id'}
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Item Id" />
                  </Form.Item>
                </Col>
              )}
              {promotionForm.apply_discount_on === 'CATEGORY' && (
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Category ID"
                    name={'categoryId'}
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Category ID" disabled={true} />
                  </Form.Item>
                </Col>
              )}
              {promotionForm.apply_discount_on === 'CATEGORY' && (
                <Col xs={12} md={6}>
                  <Form.Item
                    label="Category"
                    name={'category'}
                    rules={[{ required: true, message: "This field can't be empty" }]}
                  >
                    <Input size="large" placeholder="Category" />
                  </Form.Item>
                </Col>
              )}
              <Col xs={12} md={6}>
                <Form.Item
                  label={'Minimum Quantity'}
                  name={'minimum_quantity'}
                  rules={[
                    { required: true, message: "This field can't be empty" },
                    validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                      message: 'Enter up to 10 digits and 2 decimals'
                    })
                  ]}
                >
                  <InputNumber
                    placeholder={'Minimum Quantity'}
                    className="w-full"
                    min={0}
                    size="large"
                    style={{ borderRadius: '0' }}
                  ></InputNumber>
                </Form.Item>
              </Col>{' '}
              <Col xs={12} md={6}>
                <Form.Item
                  label={'Maximum Quantity'}
                  name={'maximum_quantity'}
                  rules={[
                    { required: true, message: "This field can't be empty" },
                    validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                      message: 'Enter up to 10 digits and 2 decimals'
                    })
                  ]}
                >
                  <InputNumber
                    placeholder={'Maximum Quantity'}
                    className="w-full"
                    min={0}
                    size="large"
                    style={{ borderRadius: '0' }}
                  ></InputNumber>
                </Form.Item>
              </Col>{' '}
            </Row>
            <div className="mt-4">
              <Button htmlType="submit" type="primary" size="large">
                <BoldButtonLabel labelText={'Update'} />
              </Button>
            </div>
          </Form>
        </Modal>
        <Modal
          centered
          open={volumeEditModal}
          onCancel={() => {
            setVolumeEditModal(false);
          }}
          width={1000}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>Edit Reward</Typography.Title>
            </div>
          }
        >
          <Form
            form={volumeRewardForm}
            scrollToFirstError
            layout="vertical"
            onFinish={() => {
              handleOnEditVolumePromo();
            }}
          >
            <Row gutter={12}>
              <Col xs={12} md={6}>
                <Form.Item
                  label="Item ID"
                  name={'item_id'}
                  rules={[{ required: true, message: "This field can't be empty" }]}
                >
                  <Input size="large" placeholder="Item Id" disabled={true} />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label={'Minimum Quantity'}
                  name={'minimum_quantity'}
                  rules={[
                    { required: true, message: "This field can't be empty" },
                    validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                      message: 'Enter up to 10 digits and 2 decimals'
                    })
                  ]}
                >
                  <InputNumber
                    placeholder={'Minimum Quantity'}
                    className="w-full"
                    min={0}
                    size="large"
                    style={{ borderRadius: '0' }}
                  ></InputNumber>
                </Form.Item>
              </Col>{' '}
              {promotionForm.discount_type !== 'SPL_PRICE' && (
                <Col xs={12} md={6}>
                  <Form.Item
                    label={'Maximum Quantity'}
                    name={'maximum_quantity'}
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                        message: 'Enter up to 10 digits and 2 decimals'
                      })
                    ]}
                  >
                    <InputNumber
                      placeholder={'Maximum Quantity'}
                      className="w-full"
                      min={0}
                      size="large"
                      style={{ borderRadius: '0' }}
                    ></InputNumber>
                  </Form.Item>
                </Col>
              )}
              {promotionForm.discount_type === 'SPL_PRICE' && (
                <Col xs={12} md={6}>
                  <Form.Item
                    label={'Special Price'}
                    name={'discount_value'}
                    rules={[
                      { required: true, message: "This field can't be empty" },
                      validationHelpers.getMaxTenDigitTwoDecimalPointValidatorForInput({
                        message: 'Enter up to 10 digits and 2 decimals'
                      })
                    ]}
                  >
                    <InputNumber
                      placeholder={'Special Price'}
                      className="w-full"
                      min={0}
                      size="large"
                      style={{ borderRadius: '0' }}
                    ></InputNumber>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <div className="mt-4">
              <Button htmlType="submit" type="primary" size="large">
                <BoldButtonLabel labelText={'Update'} />
              </Button>
            </div>
          </Form>
        </Modal>
      </Card>
    </div>
  );
};

export default RewardForm;

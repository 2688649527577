import * as React from 'react';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useAuth } from '../../../../stores/use-auth';
import { DASHBOARD_URI } from '../../../../config/envConfig';
import { useTranslation } from 'react-i18next';

interface ILoyalityDashboard { }

const LoyalityDashboard: React.FunctionComponent<ILoyalityDashboard> = props => {
  const { bussinessGroupName, tenentName } = useAuth(({ bussinessGroupName, tenentName }) => ({
    bussinessGroupName,
    tenentName
  }));

  const languageCode: any = {
    'IN': "en",
    "CL": "es"
  }

  const { i18n } = useTranslation();
  const [language, setLanguage] = React.useState(languageCode[i18n.language]);

  React.useEffect(() => {
    const handleLanguageChange = (lng: any) => {
      setLanguage(languageCode[lng])
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const labelUrl = `${DASHBOARD_URI}elysian-analytics-dashboard/${bussinessGroupName}/${tenentName}/language=${language}`;

  return (
    <PrimaryLayout>
      <iframe title="Igad" style={{ height: '100%', border: 0 }} src={labelUrl} key={language}></iframe>
    </PrimaryLayout>
  );
};

export default LoyalityDashboard;

import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd';
import PrimaryLayout from '../../../../layouts/primary-layout';
import TenantInfo from '../../../../components/TenantIdInfo';
import { useLoader } from '../../../../stores/use-loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useTimezone } from '../../../../hooks/useTimezone';
import React from 'react';
import { loyaltyService } from '../../services/loyalty.service';
import dayjs from 'dayjs';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import _ from '../../../../helpers/lodash';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import DmnModeler from 'dmn-js/lib/Modeler';
import { useTranslation } from 'react-i18next';

interface IBurnRulesNewVersionProps {}

const BurnRuleNewVersion: React.FunctionComponent<IBurnRulesNewVersionProps> = props => {
  const { t } = useTranslation();
  const [createForm] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const { timezoneSelectOptions } = useTimezone();
  const modellerRef = React.useRef<any>(null);

  const [burnRuleDetails, setBurnRuleDetails] = React.useState({} as any);

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    if (_.isEmpty(burnRuleDetails)) {
      fetchRuleDetails();
    }

    if (modellerRef.current === null && burnRuleDetails.xml) {
      initializeModeller();
    }

    return () => {
      if (modellerRef.current !== null) {
        modellerRef.current.detach();
        modellerRef.current = null;
      }
    };
  }, [burnRuleDetails]);

  const fetchRuleDetails = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getBurnRuleDetail(id as string);
    if (_.isEmpty(errors)) {
      setBurnRuleDetails(data);
      let datePresent = data.start_date && data.end_date;
      let formValues = {
        burn_template_type: data.burn_template_type,
        name: data.name,
        reward_name: data.reward_name,
        timezone: data.time_zone,
        date_range: datePresent ? [dayjs(data.start_date), dayjs(data.end_date)] : null
      };
      createForm.setFieldsValue(formValues);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const initializeModeller = async () => {
    const modeller = new DmnModeler({
      container: '#createBurnRulesNewVersionCanvas'
    });
    modellerRef.current = modeller;
    try {
      const { warnings } = await modeller.importXML(burnRuleDetails.xml);

      if (warnings.length) {
        console.log('import with warnings', warnings);
      } else {
        console.log('import successful');
      }
    } catch (err) {
      console.log('something went wrong:', err);
    }
  };

  const handleCreate = async () => {
    setLoading(true);

    const { name, burn_template_type, reward_name, date_range, timezone } = createForm.getFieldsValue();
    const { xml } = await modellerRef.current.saveXML();
    const request: any = {};
    if (date_range?.length) {
      const [start_date, end_date] = date_range;
      request.start_date = start_date.format();
      request.end_date = end_date.format();
    }

    const body = {
      burn_template_type: burn_template_type,
      name: name,
      xml: xml,
      reward_name: reward_name,
      start_date: request.start_date,
      end_date: request.end_date,
      time_zone: timezone
    };
    const { errors } = await loyaltyService.createNewBurnRule(body);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('ruleCreatedSuccessfully') });
      navigate('/loyalty/config/burn-rule');
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('createNewVersion')}
              </Typography.Title>
            </Col>
          </Row>
          <div className="flex gap-4">
            <TenantInfo />
          </div>
          <section className="mt-4">
            <Form layout="vertical" onFinish={handleCreate} form={createForm}>
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item name="burn_template_type" label={t('templateType')}>
                    <Select
                      disabled
                      placeholder={t('selectTemplateType')}
                      size="large"
                      options={[
                        { label: t('customerOrder'), value: 'CUSTOMER_ORDER' },
                        { label: t('giftCard'), value: 'GIFT_CARD' },
                        { label: t('transferReward'), value: 'TRANSFER_REWARD' }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    rules={[
                      { message: t('thisFieldIsRequired'), required: true },
                      {
                        validator: async (_, ruleName) => {
                          if (/\s/.test(ruleName)) {
                            return Promise.reject(new Error(t('noSpacesAllowed')));
                          }
                        }
                      }
                    ]}
                    name="name"
                    label={t('ruleName')}
                  >
                    <Input placeholder={t('enterRuleName')} size="large" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    rules={[{ message: t('thisFieldIsRequired'), required: true }]}
                    name="reward_name"
                    label={t('rewardName')}
                  >
                    <Input placeholder={t('enterRewardName')} size="large" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item
                    rules={[{ message: t('thisFieldIsRequired'), required: true }]}
                    name="timezone"
                    label={t('timezone')}
                  >
                    <Select options={timezoneSelectOptions} placeholder={t('selectTimezone')} size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="date_range" label={t('date')}>
                    <DatePicker.RangePicker size="large" placeholder={[t('startDate'), t('endDate')]} />
                  </Form.Item>
                </Col>
              </Row>
              <div id="createBurnRulesNewVersionCanvas" className="h-[400px]"></div>
              <Row gutter={12} className="mt-2">
                <Col xs={24} md={8} lg={6}>
                  <Button block htmlType="submit" size="large" type="primary">
                    <BoldButtonLabel labelText={t('createNewVersion')} />
                  </Button>
                </Col>
                <Col xs={24} md={8} lg={6}>
                  <Button block size="large" onClick={() => navigate(`/loyalty/config/burn-rule`)}>
                    <BoldButtonLabel labelText={t('goBack')} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default BurnRuleNewVersion;

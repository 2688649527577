import { Button, Card, Col, Form, Input, Row, Typography } from "antd"
import PrimaryLayout from "../../../../layouts/primary-layout"
import TenantInfo from "../../../../components/TenantIdInfo"
import { useLoader } from "../../../../stores/use-loader";
import BoldButtonLabel from "../../../../components/BoldButtonLabel";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../../i18n";

interface IBurnRuleTestProps { }

const BurnRuleTest: React.FunctionComponent<IBurnRuleTestProps> = props => {
  const [jsonForm] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const testRule = async () => {
    setLoading(true);
    // const ruleJson = deserialize(jsonForm.getFieldsValue().input_json);
    // const { data, errors } = await loyaltyService.testEarnRule(id as string, ruleJson);
    // if (_.isEmpty(errors)) {
    //   setRewardObject(data);
    // } else {
    //   displayErrorNotifications(errors);
    // }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t("burnTestRule")}
              </Typography.Title>
            </Col>
          </Row>
          <section className="flex gap-4 flex-wrap">
            <TenantInfo />
          </section>
          <section className="my-4">
            <Form form={jsonForm} onFinish={testRule} layout="vertical">
              <Row>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={t("inputJson")}
                    name="input_json"
                    validateTrigger="onBlur"
                    rules={[
                      { required: true, message: t("fieldIsEmpty") },
                      // () => ({
                      //     validator(_, value) {
                      //         if (!value || validateJson(value, deserialize)) {
                      //             return Promise.resolve();
                      //         }
                      //         return Promise.reject(new Error('Invalid JSON'));
                      //     }
                      // })
                    ]}
                  >
                    <Input.TextArea rows={25} placeholder={t("enterJson")} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Button htmlType="submit" type="primary" block>
                    <BoldButtonLabel labelText={t("testRule")}></BoldButtonLabel>
                  </Button>
                </Col>
                <Col xs={24} md={8} lg={6}>
                  <Button block onClick={() => navigate(`/loyalty/config/burn-rule`)}>
                    <BoldButtonLabel labelText={t('back')} />{' '}
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  )
}

export default BurnRuleTest;
